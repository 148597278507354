import { Injectable } from '@angular/core';
import { BaseApi } from '@core/classes/base-api.class';
import { NjoftimiForCreationDTO } from '@core/interfaces/dto/creation/njoftimi-for-creation-dto.interface';
import { NjoftimiForUpdateDTO } from '@core/interfaces/dto/update/njoftimi-for-update-dto.interface';
import { Njoftimi } from '@core/interfaces/models/njoftimi.interface';
import { NjoftimiParams } from '@core/interfaces/params/njoftimi-params.interface';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, combineLatest, forkJoin } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { LeksikografiaHubService } from './signalr/leksikografia-hub.service';

@Injectable({
  providedIn: 'root',
})
export class NjoftimiService extends BaseApi<Njoftimi, NjoftimiParams, NjoftimiForCreationDTO, NjoftimiForUpdateDTO> {
  private _store$: BehaviorSubject<Njoftimi[]> = new BehaviorSubject<Njoftimi[]>([]);
  private _pageNumber$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  private _pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(20);
  private _canLoad$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _unread$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(apiService: ApiService, private leksikografiaHubService: LeksikografiaHubService) {
    super(apiService, 'njoftimi');

    leksikografiaHubService.onNotificationReceived().subscribe((njoftimi) => {
      const store = cloneDeep(this._store$.getValue());
      store.splice(0, 0, njoftimi);
      this._store$.next(store);
      this.addUnread();

      let audio = new Audio();
      audio.src = '../../../../assets/notification.mp3';
      audio.load();
      audio.play();
    });
  }

  deleteNjoftimi(njoftimi: Njoftimi) {
    return super.delete(njoftimi.id).pipe(
      tap(() => {
        const store = cloneDeep(this._store$.getValue());
        const index = store.findIndex(x => x.id == njoftimi.id);
        if(index != -1) {
          store.splice(index, 1);
        }
        this._store$.next(store);
        
        if(!njoftimi.hapur)
          this.subtractUnread();
      })
    )
  }
  
  getFromStore() {
    return this._store$.asObservable();
  }

  getCanLoad() {
    return this._canLoad$.asObservable();
  }

  getUnreadCount() {
    return this._unread$.asObservable();
  }

  subtractUnread() {
    const count = this._unread$.getValue() - 1;
    if (count < 0) {
      throw 'Unread count cannot be less than 0.';
    }
    this._unread$.next(count);
  }

  addUnread() {
    const count = this._unread$.getValue() + 1;
    this._unread$.next(count);
  }

  markAsOpen(id: number) {
    return this.apiService.put(`${this._endpointUrl}${id}/open`, null).pipe(
      tap(() => {
        const store = cloneDeep(this._store$.getValue());
        const index = store.findIndex((x) => x.id == id);
        if (index != -1) store[index].hapur = true;
        this._store$.next(store);
        this.subtractUnread();
      })
    );
  }

  loadMore() {
    return combineLatest([this._pageNumber$, this._pageSize$, this._canLoad$]).pipe(
      take(1),
      filter(([nr, size, canLoad]) => canLoad),
      switchMap(([nr, size]) => super.get({ pageNumber: nr, pageSize: size })),
      map((njoftimet) => {
        const store = cloneDeep(this._store$.getValue());
        store.push(...njoftimet);
        this._store$.next(store);

        if (njoftimet.length < this._pageSize$.getValue()) {
          this._canLoad$.next(false);
        } else {
          this._pageNumber$.next(this._pageNumber$.getValue() + 1);
        }

        return true;
      })
    );
  }

  load() {
    return forkJoin(this.loadMore(), this.apiService.get<number>(`${this._endpointUrl}unread`)).pipe(
      map(([, count]) => {
        this._unread$.next(count);
        return true;
      })
    );
  }
}
