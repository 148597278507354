import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { FjalaHuajFjalaPerzgjedhurForCreationDTO } from '@core/interfaces/dto/creation/fjala-huaj-fjala-perzgjedhur-for-creation-dto.interface';
import { FjalaHuajFjalaPerzgjedhurForUpdateDTO } from '@core/interfaces/dto/update/fjala-huaj-fjala-perzgjedhur-for-update-dto.interface';
import { FjalaHuajFjalaPerzgjedhur } from '@core/interfaces/models/fjala-huaj-fjala-perzgjedhur.interface';
import { BaseParams } from '@core/interfaces/params/base-params.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class FjalaHuajFjalaPerzgjedhurService extends Store<
  FjalaHuajFjalaPerzgjedhur,
  BaseParams,
  FjalaHuajFjalaPerzgjedhurForCreationDTO,
  FjalaHuajFjalaPerzgjedhurForUpdateDTO
> {
  constructor(apiService: ApiService) {
    super(apiService, 'fjalaHuajFjalaPerzgjedhur/');
  }
}
