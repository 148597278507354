import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { ProverbaEtiketaForCreationDTO } from '@core/interfaces/dto/creation/proverba-etiketa-for-creation-dto.interface';
import { ProverbaEtiketaForUpdateDTO } from '@core/interfaces/dto/update/proverba-etiketa-for-update-dto.interface';
import { ProverbaEtiketa } from '@core/interfaces/models/proverba-etiketa.interface';
import { BaseParams } from '@core/interfaces/params/base-params.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ProverbaEtiketaService extends Store<ProverbaEtiketa, BaseParams, ProverbaEtiketaForCreationDTO, ProverbaEtiketaForUpdateDTO> {
  constructor(apiService: ApiService) {
    super(apiService, 'proverbaEtiketa');
  }
}
