import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { FjalaPerzgjedhurForCreationDTO } from '@core/interfaces/dto/creation/fjala-perzgjedhur-for-creation-dto.interface';
import { FjalaPerzgjedhurForUpdateDTO } from '@core/interfaces/dto/update/fjala-perzgjedhur-for-update-dto.interface';
import { FjalaPerzgjedhur } from '@core/interfaces/models/fjala-perzgjedhur.interface';
import { BaseParams } from '@core/interfaces/params/base-params.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FjalaPerzgjedhurService extends Store<FjalaPerzgjedhur, BaseParams, FjalaPerzgjedhurForCreationDTO, FjalaPerzgjedhurForUpdateDTO> {

  constructor(apiService: ApiService) {
    super(apiService, 'fjalaPerzgjedhur/')
  }
}
