import { PjesaLigjerates } from '@core/enums/pjesa-ligjerates.enum';
import { Constants } from '@core/globals/constants';
import { QueryBuilderConfig } from 'ngx-angular-query-builder';

export class QueryBuilderConfigs {
  private static get Nyja() {
    const nyja = Constants.Nyjet.map<{ name: string; value: any }>((x) => {
      return { name: x.long, value: x.id };
    });
    nyja.unshift({ name: 'empty', value: null });
    return nyja;
  }

  public static Word: QueryBuilderConfig = {
    fields: {
      id: {
        name: 'id',
        type: 'number',
        operators: ['=', '!=', '>', '>=', '<', '<='],
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.value.value == '' ? false : null;
        },
      },
      headWord: {
        name: 'headword',
        type: 'string',
        operators: ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'],
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.value.value == '' ? false : null;
        },
      },
      node: {
        name: 'node',
        type: 'category',
        operators: ['=', '!='],
        options: this.Nyja,
        defaultValue: () => {
          return this.Nyja[0];
        },
      },
      homonym: {
        name: 'homonym',
        type: 'category',
        operators: ['=', '!=', '>', '>=', '<', '<='],
        options: Constants.Homonimet.map((x) => {
          return { name: x.long, value: x.id };
        }),
        defaultValue: () => {
          return {
            value: '',
          };
        },
      },
      description: {
        name: 'description',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
            rule?.operator!
          ) && rule.value.value == ''
            ? false
            : null;
        },
      },
      material: {
        name: 'material',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
            rule?.operator!
          ) && rule.value.value == ''
            ? false
            : null;
        },
      },
      example: {
        name: 'example',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
          'contains_lessthan_words',
          'contains_exact_words',
          'contains_morethan_words',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
            rule?.operator!
          ) && rule.value.value == ''
            ? false
            : null;
        },
      },
      synonym: {
        name: 'synonym',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
            rule?.operator!
          ) && rule.value.value == ''
            ? false
            : null;
        },
      },
      synonym_material: {
        name: 'synonym_material',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
            rule?.operator!
          ) && rule.value.value == ''
            ? false
            : null;
        },
      },
      antonym: {
        name: 'antonym',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
            rule?.operator!
          ) && rule.value.value == ''
            ? false
            : null;
        },
      },
      antonym_material: {
        name: 'antonym_material',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
            rule?.operator!
          ) && rule.value.value == ''
            ? false
            : null;
        },
      },
      partOfSpeech: {
        name: 'partofspeech',
        type: 'partOfSpeech',
        operators: ['=', '!='],
        options: [
          { name: 'Zbrazët', value: 0 },
          { name: 'Emër (em.)', value: PjesaLigjerates.Emer },
          { name: 'Mbiemër (mb.)', value: PjesaLigjerates.Mbiemer },
          { name: 'Numëror (num.)', value: PjesaLigjerates.Numeror },
          { name: 'Përemër (për.)', value: PjesaLigjerates.Peremer },
          { name: 'Folje (fol.)', value: PjesaLigjerates.Folje },
          { name: 'Ndajfolje (ndjf.)', value: PjesaLigjerates.Ndajfolje },
          { name: 'Parafjalë (prf.)', value: PjesaLigjerates.Parafjale },
          { name: 'Lidhëz (ldhz.)', value: PjesaLigjerates.Lidhez },
          { name: 'Pjesëz (pj.)', value: PjesaLigjerates.Pjesez },
          { name: 'Pasthirrmë (psth.)', value: PjesaLigjerates.Pasthirrme },
          { name: 'E papërcaktuar (papërcak.)', value: PjesaLigjerates.Epapercaktuar },
        ],
        defaultValue: () => {
          return {
            pjesaLigjerates: null,
            filteri: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.pjesaLigjerates == null ? false : null;
        },
      },
      proverb: {
        name: 'proverb',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
            rule?.operator!
          ) && rule.value.value == ''
            ? false
            : null;
        },
      },
      phrase: {
        name: 'phrase',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
            rule?.operator!
          ) && rule.value.value == ''
            ? false
            : null;
        },
      },
      mistake: {
        name: 'mistake',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
            rule?.operator!
          ) && rule.value.value == ''
            ? false
            : null;
        },
      },
      mixUp: {
        name: 'mixup',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
            rule?.operator!
          ) && rule.value.value == ''
            ? false
            : null;
        },
      },
      foreignWord: {
        name: 'foreignword',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
            rule?.operator!
          ) && rule.value.value == ''
            ? false
            : null;
        },
      },
      etymology: {
        name: 'etymology',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
            rule?.operator!
          ) && rule.value.value == ''
            ? false
            : null;
        },
      },
      etiquette: {
        name: 'etiquette',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], //loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      approval: {
        name: 'approval',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], //loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      wordSource: {
        name: 'wordsource',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], // loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      fieldOfUsage: {
        name: 'fieldofusage',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], // loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      reference: {
        name: 'reference',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        type: 'reference',
        defaultValue: () => {
          return {
            referenceTitle: '',
            referenceURL: '',
          };
        },
        validator: (rule, parent) => {
          return !['empty', 'not_empty'].includes(rule?.operator!) && rule.value.referenceTitle == '' && rule.value.referenceURL == '' ? false : null;
        },
      },
      insertDate: {
        name: 'insertdate',
        type: 'date',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          if (rule.value.value == '') return 'querybuilder_validator_insertdate_empty';
          else if (isNaN(Date.parse(rule.value.value))) return 'querybuilder_validator_insertdate_invalid';
          else return null;
        },
      },
      updateDate: {
        name: 'updatedate',
        type: 'date',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          if (rule.value.value == '') return 'querybuilder_validator_updatedate_empty';
          else if (isNaN(Date.parse(rule.value.value))) return 'querybuilder_validator_updatedate_invalid';
          else return null;
        },
      },
      comments: {
        name: 'comments',
        operators: ['empty', 'not_empty', 'contains', 'not_contains', 'has_commented'],
        type: 'comments',
        options: [], // loaded async
        defaultOperator: 'has_commented',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return ['contains', 'not_contains', 'has_commented'].includes(rule?.operator!) && rule.value.value == '' ? false : null;
        },
      },
    },
    allowEmptyRulesets: false,
  };

  public static Example: QueryBuilderConfig = {
    fields: {
      example: {
        name: 'example',
        type: 'string',
        operators: [
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
          'contains_lessthan_words',
          'contains_exact_words',
          'contains_morethan_words',
        ],
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.value.value == '' ? false : null;
        },
      },
      explanation: {
        name: 'explanation',
        type: 'string',
        operators: [
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
          'contains_lessthan_words',
          'contains_exact_words',
          'contains_morethan_words',
        ],
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.value.value == '' ? false : null;
        },
      },
      word: {
        name: 'word',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.operator &&
            ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
              rule.operator
            ) &&
            rule.value.value == ''
            ? false
            : null;
        },
      },
      exampleEtiquette: {
        name: 'etiquette',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], //loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      exampleApproval: {
        name: 'approval',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], //loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      source: {
        name: 'source',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], // loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      insertDate: {
        name: 'insertdate',
        type: 'date',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          if (rule.value.value == '') return 'querybuilder_validator_insertdate_empty';
          else if (isNaN(Date.parse(rule.value.value))) return 'querybuilder_validator_insertdate_invalid';
          return null;
        },
      },
      updateDate: {
        name: 'updatedate',
        type: 'date',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          if (rule.value.value == '') return 'querybuilder_validator_updatedate_empty';
          else if (isNaN(Date.parse(rule.value.value))) return 'querybuilder_validator_updatedate_invalid';
          return null;
        },
      },
      comments: {
        name: 'comments',
        operators: ['empty', 'not_empty', 'contains', 'not_contains', 'has_commented'],
        type: 'comments',
        options: [], // loaded async
        defaultOperator: 'has_commented',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.operator && ['contains', 'not_contains', 'has_commented'].includes(rule.operator) && rule.value.value == '' ? false : null;
        },
      },
    },
  };
  
  public static Proverb: QueryBuilderConfig = {
    fields: {
      proverb: {
        name: 'proverb',
        type: 'string',
        operators: [
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
          'contains_lessthan_words',
          'contains_exact_words',
          'contains_morethan_words',
        ],
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.value.value == '' ? false : null;
        },
      },
      explanation: {
        name: 'explanation',
        type: 'string',
        operators: [
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
          'contains_lessthan_words',
          'contains_exact_words',
          'contains_morethan_words',
        ],
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.value.value == '' ? false : null;
        },
      },
      word: {
        name: 'word',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.operator &&
            ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
              rule.operator
            ) &&
            rule.value.value == ''
            ? false
            : null;
        },
      },
      proverbEtiquette: {
        name: 'etiquette',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], //loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      proverbApproval: {
        name: 'approval',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], //loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      source: {
        name: 'source',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], // loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      insertDate: {
        name: 'insertdate',
        type: 'date',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          if (rule.value.value == '') return 'querybuilder_validator_insertdate_empty';
          else if (isNaN(Date.parse(rule.value.value))) return 'querybuilder_validator_insertdate_invalid';
          return null;
        },
      },
      updateDate: {
        name: 'updatedate',
        type: 'date',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          if (rule.value.value == '') return 'querybuilder_validator_updatedate_empty';
          else if (isNaN(Date.parse(rule.value.value))) return 'querybuilder_validator_updatedate_invalid';
          return null;
        },
      },
      comments: {
        name: 'comments',
        operators: ['empty', 'not_empty', 'contains', 'not_contains', 'has_commented'],
        type: 'comments',
        options: [], // loaded async
        defaultOperator: 'has_commented',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.operator && ['contains', 'not_contains', 'has_commented'].includes(rule.operator) && rule.value.value == '' ? false : null;
        },
      },
    },
  };
  
  public static Frazeologjia: QueryBuilderConfig = {
    fields: {
      frazeologjia: {
        name: 'frazeologjia',
        type: 'string',
        operators: [
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
          'contains_lessthan_words',
          'contains_exact_words',
          'contains_morethan_words',
        ],
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.value.value == '' ? false : null;
        },
      },
      explanation: {
        name: 'explanation',
        type: 'string',
        operators: [
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
          'contains_lessthan_words',
          'contains_exact_words',
          'contains_morethan_words',
        ],
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.value.value == '' ? false : null;
        },
      },
      word: {
        name: 'word',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.operator &&
            ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
              rule.operator
            ) &&
            rule.value.value == ''
            ? false
            : null;
        },
      },
      frazeologjiaEtiquette: {
        name: 'etiquette',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], //loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      frazeologjiaApproval: {
        name: 'approval',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], //loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      source: {
        name: 'source',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], // loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      insertDate: {
        name: 'insertdate',
        type: 'date',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          if (rule.value.value == '') return 'querybuilder_validator_insertdate_empty';
          else if (isNaN(Date.parse(rule.value.value))) return 'querybuilder_validator_insertdate_invalid';
          return null;
        },
      },
      updateDate: {
        name: 'updatedate',
        type: 'date',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          if (rule.value.value == '') return 'querybuilder_validator_updatedate_empty';
          else if (isNaN(Date.parse(rule.value.value))) return 'querybuilder_validator_updatedate_invalid';
          return null;
        },
      },
      comments: {
        name: 'comments',
        operators: ['empty', 'not_empty', 'contains', 'not_contains', 'has_commented'],
        type: 'comments',
        options: [], // loaded async
        defaultOperator: 'has_commented',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.operator && ['contains', 'not_contains', 'has_commented'].includes(rule.operator) && rule.value.value == '' ? false : null;
        },
      },
    },
  };
  
  public static FjalaHuaj: QueryBuilderConfig = {
    fields: {
      fjalaHuaj: {
        name: 'fjalaHuaj',
        type: 'string',
        operators: [
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
          'contains_lessthan_words',
          'contains_exact_words',
          'contains_morethan_words',
        ],
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.value.value == '' ? false : null;
        },
      },
      explanation: {
        name: 'explanation',
        type: 'string',
        operators: [
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
          'contains_lessthan_words',
          'contains_exact_words',
          'contains_morethan_words',
        ],
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.value.value == '' ? false : null;
        },
      },
      word: {
        name: 'word',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.operator &&
            ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
              rule.operator
            ) &&
            rule.value.value == ''
            ? false
            : null;
        },
      },
      fjalaHuajEtiquette: {
        name: 'etiquette',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], //loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      fjalaHuajApproval: {
        name: 'approval',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], //loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      source: {
        name: 'source',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], // loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      insertDate: {
        name: 'insertdate',
        type: 'date',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          if (rule.value.value == '') return 'querybuilder_validator_insertdate_empty';
          else if (isNaN(Date.parse(rule.value.value))) return 'querybuilder_validator_insertdate_invalid';
          return null;
        },
      },
      updateDate: {
        name: 'updatedate',
        type: 'date',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          if (rule.value.value == '') return 'querybuilder_validator_updatedate_empty';
          else if (isNaN(Date.parse(rule.value.value))) return 'querybuilder_validator_updatedate_invalid';
          return null;
        },
      },
      comments: {
        name: 'comments',
        operators: ['empty', 'not_empty', 'contains', 'not_contains', 'has_commented'],
        type: 'comments',
        options: [], // loaded async
        defaultOperator: 'has_commented',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.operator && ['contains', 'not_contains', 'has_commented'].includes(rule.operator) && rule.value.value == '' ? false : null;
        },
      },
    },
  };
  
  public static FjalaGabim: QueryBuilderConfig = {
    fields: {
      fjalaGabim: {
        name: 'fjalaGabim',
        type: 'string',
        operators: [
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
          'contains_lessthan_words',
          'contains_exact_words',
          'contains_morethan_words',
        ],
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.value.value == '' ? false : null;
        },
      },
      explanation: {
        name: 'explanation',
        type: 'string',
        operators: [
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
          'contains_lessthan_words',
          'contains_exact_words',
          'contains_morethan_words',
        ],
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.value.value == '' ? false : null;
        },
      },
      word: {
        name: 'word',
        type: 'string',
        operators: [
          'empty',
          'not_empty',
          'startswith',
          'not_startswith',
          'contains_word',
          'not_contains_word',
          'contains',
          'not_contains',
          'endswith',
          'not_endswith',
        ],
        defaultOperator: 'startswith',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.operator &&
            ['startswith', 'not_startswith', 'contains_word', 'not_contains_word', 'contains', 'not_contains', 'endswith', 'not_endswith'].includes(
              rule.operator
            ) &&
            rule.value.value == ''
            ? false
            : null;
        },
      },
      fjalaGabimEtiquette: {
        name: 'etiquette',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], //loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      fjalaGabimApproval: {
        name: 'approval',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], //loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      source: {
        name: 'source',
        operators: ['in', 'not_in'],
        type: 'multiselectAutosuggest',
        options: [], // loaded async
        defaultValue: () => {
          return {
            values: [],
          };
        },
        validator: (rule, parent) => {
          return rule.value.values.length == 0 ? false : null;
        },
      },
      insertDate: {
        name: 'insertdate',
        type: 'date',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          if (rule.value.value == '') return 'querybuilder_validator_insertdate_empty';
          else if (isNaN(Date.parse(rule.value.value))) return 'querybuilder_validator_insertdate_invalid';
          return null;
        },
      },
      updateDate: {
        name: 'updatedate',
        type: 'date',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          if (rule.value.value == '') return 'querybuilder_validator_updatedate_empty';
          else if (isNaN(Date.parse(rule.value.value))) return 'querybuilder_validator_updatedate_invalid';
          return null;
        },
      },
      comments: {
        name: 'comments',
        operators: ['empty', 'not_empty', 'contains', 'not_contains', 'has_commented'],
        type: 'comments',
        options: [], // loaded async
        defaultOperator: 'has_commented',
        defaultValue: () => {
          return {
            value: '',
          };
        },
        validator: (rule, parent) => {
          return rule.operator && ['contains', 'not_contains', 'has_commented'].includes(rule.operator) && rule.value.value == '' ? false : null;
        },
      },
    },
  };
}
