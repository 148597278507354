import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userAgent',
})
export class UserAgentPipe implements PipeTransform {
  transform(agent: string, ...args: unknown[]): unknown {
    let value = agent.toLowerCase();
    switch (true) {
      case value.indexOf('edge') > -1:
        return 'MS Edge';
      case value.indexOf('edg/') > -1:
        return 'Edge (chromium based)';
      case value.indexOf('opr') > -1:
        return 'Opera';
      case value.indexOf('chrome') > -1:
        return 'Chrome';
      case value.indexOf('trident') > -1:
        return 'MS IE';
      case value.indexOf('firefox') > -1:
        return 'Mozilla Firefox';
      case value.indexOf('safari') > -1:
        return 'Safari';
      default:
        return 'Could not identify';
    }
  }
}
