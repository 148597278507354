import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PhrasePipe } from '@shared/modules/phrase/phrase.pipe';
import { BehaviorSubject, Subject, combineLatest, startWith, debounceTime, tap, filter, switchMap, takeUntil } from 'rxjs';
import { AsSentenceProcessorService } from '../as-sentence-processor.service';
import { FileContentResponse } from '../interfaces/file-content-response.interface';
import { FileContent } from '../interfaces/file-content.interface';

@Component({
  selector: 'as-sentence-processor-search',
  templateUrl: './as-sentence-processor-search.component.html',
  styleUrls: ['./as-sentence-processor-search.component.scss'],
})
export class AsSentenceProcessorSearchComponent implements OnInit, OnDestroy {
  searchItemCtrl: FormControl = new FormControl('');
  searchWholeWord: FormControl = new FormControl(true);

  searchItems$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  highlightText: string[] = [];

  loading: boolean = false;

  list: FileContent[] = [];
  count?: number;

  pageNumber$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(50);

  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('paginator') paginator?: MatPaginator;

  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(private snackBar: MatSnackBar, private phrasePipe: PhrasePipe, private sentenceService: AsSentenceProcessorService) {}

  ngOnInit(): void {
    combineLatest([this.searchItems$, this.pageNumber$, this.pageSize$, this.searchWholeWord.valueChanges.pipe(startWith(true))])
      .pipe(
        debounceTime(0), // eliminate same call-stack changes firing multiple times
        tap(() => {
          this.list = [];
        }),
        filter(([items]) => {
          return items.length > 0;
        }),
        tap(([items]) => {
          this.loading = true;
          this.highlightText = items;
        }),
        debounceTime(1000),
        switchMap(([items, pageNumber, pageSize, wholeWord]) => {
          items.forEach((element) => {
            element = encodeURIComponent(element);
          });

          return this.sentenceService.searchNew(items, pageNumber, pageSize, wholeWord);
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((response: FileContentResponse) => {
        this.loading = false;
        this.count = response.count;
        this.list = response.sentencesList;
      });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && value.length > 1) {
      if (this.paginator) this.paginator.pageIndex = 0;

      this.pageNumber$.next(1);
      this.searchItems$.next([...this.searchItems$.getValue(), value]);
      this.count = undefined;

      this.searchItemCtrl.setValue(null);
    }
  }

  remove(item: string): void {
    const index = this.searchItems$.getValue().indexOf(item);

    if (index >= 0) {
      let items = this.searchItems$.getValue();
      items.splice(index, 1);
      if (this.paginator) this.paginator.pageIndex = 0;

      this.pageNumber$.next(1);
      this.searchItems$.next(items);
      this.count = undefined;
    }
  }

  pageChanged(event: PageEvent) {
    this.pageNumber$.next(event.pageIndex + 1);
    this.pageSize$.next(event.pageSize);
  }

  onSentenceClick(sentence: string) {
    this.sentenceService.sentenceSelected(sentence);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
