import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BasePagingParams } from '@core/interfaces/paging/base-paging-params.interface';
import { PagingResponse } from '@core/interfaces/paging/paging-response.interface';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  get<T>(url: string = '', params?: any) {
    return this.http.get<T>(`${this.baseUrl}${url}${this.getQueryParams(params)}`);
  }

  getPaging<T>(url: string = '', params: BasePagingParams) {
    return this.http.get<T[]>(`${this.baseUrl}${url}${this.getQueryParams(params)}`, { observe: 'response' }).pipe(
      map((response) => {
        return {
          body: response.body ?? [],
          totalCount: response.headers.has('TotalCount') ? parseInt(response.headers.get('TotalCount')!) : 0,
        } as PagingResponse<T>;
      })
    );
  }

  getResponse<T>(url: string = '', params?: any) {
    return this.http.get<T>(`${this.baseUrl}${url}${this.getQueryParams(params)}`, { observe: 'response' });
  }

  post<T>(url: string = '', body: any, params?: any) {
    return this.http.post<T>(`${this.baseUrl}${url}${this.getQueryParams(params)}`, body);
  }

  put<T>(url: string = '', body: any, params?: any) {
    return this.http.put<T>(`${this.baseUrl}${url}${this.getQueryParams(params)}`, body);
  }

  delete<T>(url: string = '', params?: any) {
    return this.http.delete<T>(`${this.baseUrl}${url}${this.getQueryParams(params)}`);
  }

  private getQueryParams(params?: any) {
    let queryParams = '';
    if (params) {
      Object.keys(params).forEach((key, index) => {
        if (params[key] !== null && params[key] !== undefined) {
          queryParams += queryParams.length == 0 ? '?' : '&';
          queryParams += `${key}=${encodeURIComponent(params[key])}`;
        }
      });
    }
    return queryParams;
  }
}
