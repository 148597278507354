import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ShembulliEditoriService } from '@core/services/shembulli/shembulli-editori.service';
import { ConfirmPageChangeComponent } from '@shared/dialogs/confirm-page-change/confirm-page-change.component';
import { ShembujtComponent } from '@shembujt/shembujt.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShembulliGuard implements CanDeactivate<ShembujtComponent> {
  constructor(private shembulliEditoriService: ShembulliEditoriService, private matDialog: MatDialog) {}

  canDeactivate(
    component: ShembujtComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.shembulliEditoriService.editoriForm.dirty) {
      const dialog = this.matDialog.open(ConfirmPageChangeComponent, {
        width: '400px',
        disableClose: false,
      });

      return dialog.afterClosed();
    }
    return true;
  }
}
