import { Injectable } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { QasjaForUpdateDTO } from '@core/interfaces/dto/update/qasja-for-update-dto.interface';
import { Qasja } from '@core/interfaces/models/qasja.interface';
import { QasjaParams } from '@core/interfaces/params/qasja-params.interface';
import { BehaviorSubject, map, of, switchMap, tap } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class QasjaService {
  private _baseUrl: string = 'qasja';

  private _myPermissions$: BehaviorSubject<Qasja[]> = new BehaviorSubject<Qasja[]>([]);
  private _dataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService, private authService: AuthService) {}

  load() {
    return this.getMy().pipe(
      tap((qasjet) => {
        this._myPermissions$.next(qasjet);
        this._dataLoaded$.next(true);
      })
    );
  }

  get(params?: QasjaParams) {
    return this.apiService.get<Qasja[]>(this._baseUrl, params);
  }

  getMy() {
    return this.apiService.get<Qasja[]>(`${this._baseUrl}/my`);
  }

  update(item: QasjaForUpdateDTO) {
    return this.apiService.put<Qasja>(`${this._baseUrl}`, item);
  }

  getMyQasjaFromStore() {
    return this._myPermissions$.asObservable();
  }

  hasQasjet(qasjet: { emri: string, tipi: string, vlera: any }[]) {
    return this.authService.isLoggedInAsAdmin$.pipe(
      switchMap((isAdmin) => {
        if (isAdmin) return of(true);
        return this._myPermissions$.pipe(
          map((permissions) => {
            let hasQasje = true;
            for(let i = 0; i<qasjet.length; i++){
              hasQasje = permissions.some((p) => p.emri == qasjet[i].emri && p.tipi == qasjet[i].tipi && p.vlera == qasjet[i].vlera?.toString());
              if(hasQasje)
                break;
            }
            return hasQasje;
          })
        );
      })
    )
  }

  hasQasja(emri: string, tipi: string, vlera: any) {
    return this.authService.isLoggedInAsAdmin$.pipe(
      switchMap((isAdmin) => {
        if (isAdmin) return of(true);
        return this._myPermissions$.pipe(
          map((permissions) => {
            return permissions.some((p) => p.emri == emri && p.tipi == tipi && p.vlera == vlera.toString());
          })
        );
      })
    );
  }
}
