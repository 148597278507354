import { Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { EditoriService } from '@core/services/editori.service';
import { OperatoriFaqjaService } from '@core/services/operatori-faqja.service';
import { ProverbaEditoriService } from '@core/services/proverba/proverba-editori.service';
import { ShembulliEditoriService } from '@core/services/shembulli/shembulli-editori.service';
import { Subject, filter, takeUntil, switchMap, map, combineLatest, debounceTime, distinctUntilChanged, startWith } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean = false;
  canAdministrate: boolean = false;

  showFjalaNavButtons: boolean;
  showShembujtNavButtons: boolean;
  showProverbaNavButtons: boolean;
  showFrazeologjiaNavButtons: boolean;
  showFjalaHuajNavButtons: boolean;
  showFjalaGabimNavButtons: boolean;

  darkMode: boolean = false;

  loading: boolean = false;
  saving: boolean = true;

  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;

  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private editoriService: EditoriService,
    private shembulliEditoriService: ShembulliEditoriService,
    private proverbaEditoriService: ProverbaEditoriService,
    private authService: AuthService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private operatoriFaqjaService: OperatoriFaqjaService
  ) {
    this.showFjalaNavButtons = this.router.url == '/home';
    this.showShembujtNavButtons = this.router.url == '/shembujt';
    this.showProverbaNavButtons = this.router.url == '/proverba';
    this.showFrazeologjiaNavButtons = this.router.url == '/frazeologjia';
    this.showFjalaHuajNavButtons = this.router.url == '/fjala-huaj';
    this.showFjalaGabimNavButtons = this.router.url == '/fjala-gabim';

    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._onDestroy$)
      )
      .subscribe((event: any) => {
        this.showFjalaNavButtons = false;
        this.showShembujtNavButtons = false;
        this.showProverbaNavButtons = false;
        this.showFrazeologjiaNavButtons = false;
        this.showFjalaHuajNavButtons = false;
        this.showFjalaGabimNavButtons = false;
        if (event.url === '/home' || event.urlAfterRedirects === '/home') {
          this.showFjalaNavButtons = true;
        } else if (event.url === '/shembujt' || event.urlAfterRedirects === '/shembujt') {
          this.showShembujtNavButtons = true;
        } else if (event.url === '/proverba' || event.urlAfterRedirects === '/proverba') {
          this.showProverbaNavButtons = true;
        } else if (event.url === '/frazeologjia' || event.urlAfterRedirects === '/frazeologjia') {
          this.showFrazeologjiaNavButtons = true;
        } else if (event.url === '/fjala-huaj' || event.urlAfterRedirects === '/fjala-huaj') {
          this.showFjalaHuajNavButtons = true;
        } else if (event.url === '/fjala-gabim' || event.urlAfterRedirects === '/fjala-gabim') {
          this.showFjalaGabimNavButtons = true;
        }
      });
  }

  ngOnInit(): void {
    this.authService.isDoneLoading$
      .pipe(
        filter((isDone) => isDone),
        switchMap((_) => this.authService.isAuthenticated$),
        distinctUntilChanged(),
        map((isAuthenticated) => {
          if (isAuthenticated && this.authService.hasLinguistPermission()) {
            return true;
          }
          return false;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });

    this.operatoriFaqjaService
      .listenOnFieldChanges('darkMode')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((darkMode) => {
        if (darkMode) {
          this.renderer.addClass(document.body, 'dark-theme');
          this.renderer.removeClass(document.body, 'light-theme');
        } else {
          this.renderer.addClass(document.body, 'light-theme');
          this.renderer.removeClass(document.body, 'dark-theme');
        }
        this.darkMode = darkMode;
        localStorage.setItem('darkMode', darkMode);
      });

    combineLatest([this.editoriService.getLoading(), this.shembulliEditoriService.getLoading(), this.proverbaEditoriService.getLoading()])
      .pipe(
        debounceTime(0),
        map(([fjala, shembulli, proverba]) => {
          return fjala || shembulli || proverba;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((loading) => {
        this.loading = loading;
      });

    combineLatest([this.editoriService.getSaving(), this.shembulliEditoriService.getSaving(), this.proverbaEditoriService.getSaving()])
      .pipe(
        debounceTime(0),
        map(([fjala, shembulli, proverba]) => {
          return fjala || shembulli || proverba;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((saving) => {
        this.saving = saving;
      });
  }

  onRightClick(event: MouseEvent) {
    this.trigger?.openMenu();
    event.preventDefault();
  }

  toggleTheme() {
    this.operatoriFaqjaService.updateItems({ darkMode: !this.darkMode });
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
