import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { AsSnackBarComponent } from './as-snack-bar.component';

@Injectable()
export class AsSnackBar {
  messageText: string[] = [];

  constructor(public snackBar: MatSnackBar) {}

  public openSnackBar(
    message: string,
    type: string,
    duration?: number,
    verticalPosition?: MatSnackBarVerticalPosition,
    horizontalPosition?: MatSnackBarHorizontalPosition
  ) {
    const _snackType = type !== undefined ? type : 'success';
    this.snackBar.openFromComponent(AsSnackBarComponent, {
      duration: duration || 4000,
      horizontalPosition: horizontalPosition || 'center',
      verticalPosition: verticalPosition || 'bottom',
      data: { message: message, snackType: _snackType, snackBar: this.snackBar },
    });
  }
}
