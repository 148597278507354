import { Injectable } from '@angular/core';
import { StrukturaStore } from '@core/classes/struktura-store.class';
import { ProverbaFjalaPerzgjedhurStruktura } from '@core/interfaces/models/proverba-fjala-perzgjedhur-struktura.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ProverbaFjalaPerzgjedhurStrukturaService extends StrukturaStore<ProverbaFjalaPerzgjedhurStruktura> {

  constructor(apiService: ApiService) {
    super(apiService, 'proverbaFjalaPerzgjedhurStruktura');
  }

  updateMy(items: ProverbaFjalaPerzgjedhurStruktura[]) {
    return this.apiService.put<ProverbaFjalaPerzgjedhurStruktura[]>(`${this._endpointUrl}my`, { proverbaFjalaPerzgjedhurStrukturaForCreationDTO: items });
  }

}