import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { AsSnackBarComponent } from './as-snack-bar.component';
import { AsSnackBar } from './as-snack-bar.service';

@NgModule({
  declarations: [
    AsSnackBarComponent
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatIconModule
  ],
  providers: [
    AsSnackBar
  ]
})
export class AsSnackBarModule { }
