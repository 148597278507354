<h2 mat-dialog-title>Info rreth përdoruesit</h2>
<button class="close-dialog-button" mat-icon-button mat-dialog-title [mat-dialog-close]="null">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-content *ngIf="perdoruesi">
  <div class="image" *ngIf="perdoruesi.operatoriPicture">
    <img [src]="perdoruesi.operatoriPicture" />
  </div>
  <mat-list>
    <mat-list-item>
      <div mat-line>{{ perdoruesi.operatoriName }}</div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <div mat-line>{{ perdoruesi.operatoriId }}</div>
    </mat-list-item>
  </mat-list>

  <mat-accordion class="connections">
    <ng-container *ngFor="let connection of perdoruesi.connections; let i = index">
      <mat-expansion-panel
        [ngClass]="connection.perdoruesiStatusi == PerdoruesiStatusi.Online ? 'online' : 'away'"
        [expanded]="openedConnectionId == connection.connectionId"
        (opened)="openedConnectionId = connection.connectionId"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>Connection Id: {{ connection.connectionId }}</mat-panel-title>
        </mat-expansion-panel-header>

        <mat-list>
          <mat-list-item *ngIf="connection.bazaIds.length > 0">
            <div mat-line>
              Fjalët e hapura:
              <ng-container *ngFor="let bazaId of connection.bazaIds">{{ bazaId }} </ng-container>
            </div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>IP Address: {{ connection.ipAddress }}</div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>Statusi: {{ connection.perdoruesiStatusi == PerdoruesiStatusi.Online ? 'Online' : 'Away' }}</div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>
              Browser: <span [matTooltip]="connection.userAgent">{{ connection.userAgent | userAgent }}</span>
            </div>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
