export class Constants {
  public static PjesetLigjerates = [
    { id: 10, long: 'Emër', short: 'em.' },
    { id: 11, long: 'Folje', short: 'fol.' },
    { id: 12, long: 'Mbiemër', short: 'mb.' },
    { id: 13, long: 'Përemër', short: 'për.' },
    { id: 14, long: 'Ndajfolje', short: 'ndjf.' },
    { id: 15, long: 'Numëror', short: 'num.' },
    { id: 16, long: 'Parafjalë', short: 'prf.' },
    { id: 17, long: 'Lidhëz', short: 'lidhz.' },
    { id: 18, long: 'Pjesëz', short: 'pj.' },
    { id: 19, long: 'Pasthirrmë', short: 'psth.' },
    { id: 20, long: 'E papërcaktuar', short: 'papërcak.' },
  ];

  public static get Nyjet() {
    return [
      { id: 10, short: 'i.', long: 'I' },
      { id: 11, short: 'e.', long: 'E' },
      { id: 12, short: 't.', long: 'Të' },
      { id: 13, short: 's.', long: 'Së' },
    ];
  }

  public static get Homonimet() {
    return [
      { id: 0, short: '', long: '' },
      { id: 1, short: 'i.', long: 'I' },
      { id: 2, short: 'ii.', long: 'II' },
      { id: 3, short: 'iii.', long: 'III' },
      { id: 4, short: 'iv.', long: 'IV' },
      { id: 5, short: 'v.', long: 'V' },
      { id: 6, short: 'vi.', long: 'VI' },
      { id: 7, short: 'vii.', long: 'VII' },
      { id: 8, short: 'viii.', long: 'VIII' },
      { id: 9, short: 'ix.', long: 'IX' },
      { id: 10, short: 'x.', long: 'X' },
    ];
  }

  //#region EMRI

  public static EmriGjinia = [
    { id: 10, short: 'm.', long: 'Mashkullore' },
    { id: 11, short: 'f.', long: 'Femërore' },
    { id: 12, short: 'as.', long: 'Asnjanëse' },
  ];

  public static EmriGjiniaNenvecoria1 = [{ id: 10, short: 'amb.', long: 'Ambigjenë' }];

  public static EmriLakimi = [
    { id: 10, short: 'I', long: 'I parë' },
    { id: 11, short: 'II', long: 'I dytë' },
    { id: 12, short: 'III', long: 'I tretë' },
    { id: 13, short: 'IV', long: 'I katërt' },
  ];

  public static EmriGjinia10Lakimi = [
    { id: 10, short: 'lak.I', long: 'I parë' },
    { id: 11, short: 'lak.II', long: 'I dytë' },
  ];

  public static EmriGjinia11Lakimi = [{ id: 12, short: 'lak.III', long: 'I tretë' }];

  public static EmriGjinia12Lakimi = [{ id: 13, short: 'lak.IV', long: 'I katërt' }];

  public static EmriNumri = [
    { id: 10, short: 'nj.', long: 'Njëjës' },
    { id: 11, short: 'sh.', long: 'Shumës' },
    { id: 12, short: 'vt.nj.', long: 'Vetëm në njëjës' },
    { id: 13, short: 'vt.sh.', long: 'Vetëm në shumës' },
    { id: 14, short: 'kryes.nj.', long: 'Kryesisht në njëjës' },
    { id: 15, short: 'kryes.sh.', long: 'Kryesisht në shumës' },
  ];

  public static EmriLloji = [
    { id: 10, short: 'përgj.', long: 'I përgjithshëm' },
    { id: 11, short: 'përv.', long: 'I përveçëm' },
    { id: 12, short: 'përm.', long: 'Përmbledhës' },
    { id: 13, short: 'lënd.', long: 'Lënde' },
  ];

  public static EmriRasa = [
    { id: 10, short: 'e.', long: 'Emërore' },
    { id: 11, short: 'gj.', long: 'Gjinore' },
    { id: 12, short: 'dh.', long: 'Dhanore' },
    { id: 13, short: 'k.', long: 'Kallëzore' },
    { id: 14, short: 'rr.', long: 'Rrjedhore' },
    { id: 15, short: 'vd.', long: 'Vendore*' },
    { id: 16, short: 'th.', long: 'Thirrore*' },
  ];

  public static EmriTrajta = [
    { id: 10, short: 'shq.', long: 'E shquar' },
    { id: 11, short: 'pashq.', long: 'E pashquar' },
  ];

  public static EmriShumesi = [
    { id: 10, short: 'sn.', long: 'Si njëjësi' },
    { id: 11, short: 'p.', long: 'Me prapashtesa' },
    { id: 12, short: 'nt.', long: 'Me ndërrime tingujsh' },
    { id: 13, short: 'ntp.', long: 'Me ndërrime tingujsh dhe prapashtesë' },
    { id: 14, short: 'ntt.', long: 'Ndërrime të tjera tingujsh' },
    { id: 15, short: 'nnm.', long: 'Me një ndryshim të madh' },
    { id: 16, short: 'fs.', long: 'Me formë supletive' },
  ];

  public static EmriLakimi10Shumesi = [
    { id: 10, short: 'sn.', long: 'Si njëjësi' },
    { id: 11, short: 'p.', long: 'Me prapashtesa' },
    { id: 12, short: 'nt.', long: 'Me ndërrime tingujsh' },
    { id: 13, short: 'ntp.', long: 'Me ndërrime tingujsh dhe prapashtesë' },
    { id: 14, short: 'ntt.', long: 'Ndërrime të tjera tingujsh' },
    { id: 15, short: 'nnm.', long: 'Me një ndryshim të madh' },
  ];

  public static EmriLakimi11Shumesi = [
    { id: 11, short: 'p.', long: 'Me prapashtesa' },
    { id: 12, short: 'nt.', long: 'Me ndërrime tingujsh' },
    { id: 13, short: 'ntp.', long: 'Me ndërrime tingujsh dhe prapashtesë' },
    { id: 16, short: 'fs.', long: 'Me formë supletive' },
  ];

  public static EmriLakimi12Shumesi = [
    { id: 10, short: 'sn.', long: 'Si njëjësi' },
    { id: 11, short: 'p.', long: 'Me prapashtesa' },
    { id: 15, short: 'nnm.', long: 'Me një ndryshim të madh' },
  ];

  public static EmriPrapashtesa = [
    { id: 10, short: 'sn.', long: 'si njëjësi' },
    { id: 11, short: 'e.', long: 'e' },
    { id: 12, short: 'e-ë.', long: 'e-Ë' },
    { id: 13, short: 'a-ë.', long: 'a-Ë' },
    { id: 14, short: 'a.', long: 'a' },
    { id: 15, short: 'ë.', long: 'ë' },
    { id: 16, short: 'nj.', long: 'nj' },
    { id: 17, short: 'inj. ë.', long: 'inj me rënie të ë' },
    { id: 18, short: 'inj.', long: 'inj' },
    { id: 19, short: 'j. zgj. n.', long: 'j + zgjerimi N' },
    { id: 20, short: 'j. zgj. r.', long: 'j + zgjerimi R' },
    { id: 21, short: 'enj.', long: 'enj' },
    { id: 22, short: 'ër.', long: 'ër' },
    { id: 23, short: 'ra.', long: 'ra' },
    { id: 24, short: 'na.', long: 'na' },
    { id: 25, short: 'q. ll>j', long: 'qiellzorizim ll>j' },
    { id: 26, short: 'q. r>j', long: 'qiellzorizim r>j' },
    { id: 27, short: 'q. ll>j. z.p.', long: 'qiellzorizim ll>j dhe ndërrim zanorja e parë' },
    { id: 28, short: 'q. ll>j. z.d.', long: 'qiellzorizim ll>j dhe ndërrim zanorja e dytë' },
    { id: 29, short: 'nd. z. ua>o nj.', long: 'ndërrim zanore ua>o + NJ' },
    { id: 30, short: 'q. ll>j. + e.', long: 'qiellzorizim ll>j, ndërrim zanoresh + E' },
    { id: 31, short: 'a>e.', long: 'a>e' },
    { id: 32, short: 'e>a.', long: 'e>a' },
    { id: 33, short: 'i>e.', long: 'i>e' },
    { id: 34, short: 'nd. z. ë>u + ë.', long: 'ndërrim zanoresh ë>u + Ë' },
    { id: 35, short: 'q. g>gj + e.', long: 'qiellzorizim g>gj + E' },
    { id: 36, short: 'pj. nj.ndr.', long: 'me përbërje pjesërisht të njëjtë ose të ndryshme MANUALISHT' },
    { id: 37, short: 'q. k>q', long: 'vetëm me qiellzorizim (k>q)' },
    { id: 38, short: 'q. g>gj', long: 'vetëm me qiellzorizim (g>gj)' },
    { id: 39, short: 'q. k>q + e', long: 'vetëm me qiellzorizim (k>q) + E' },
    { id: 40, short: 'q. g>gj+ e', long: 'vetëm me qiellzorizim (g>gj) + E' },
    { id: 41, short: 'q. g>gj. z. e>i', long: 'me qiellzorizim g>gj, ndërrim zanoreje e>i + E' },
    { id: 42, short: 'q. k>q. z. a>e', long: 'me qiellzorizim k>q, ndërrim zanoreje a>e + E' },
    { id: 43, short: 'sn. a.', long: 'si njëjësi A' },
    { id: 44, short: 'sn. ja.', long: 'si njëjësi JA' },
    { id: 45, short: 'sn. je.', long: 'si njëjësi JE' },
    { id: 46, short: 'a. nyj.', long: 'a NYJË' },
    { id: 47, short: 'pj. emërz. it.', long: 'pjesore të emërzuara IT' },
    { id: 48, short: 'pj. emërz. ët.', long: 'pjesore të emërzuara ËT' },
    { id: 49, short: 'mb. as. emërz.', long: 'mbiemra asnjanës të emërzuar' },
  ];

  public static EmriLakimi10Shumesi10Prapashtesa = [{ id: 10, short: 'sn.', long: 'si njëjësi' }];

  public static EmriLakimi10Shumesi11Prapashtesa = [
    { id: 11, short: 'e.', long: 'e' },
    { id: 12, short: 'e-ë.', long: 'e-Ë' },
    { id: 13, short: 'a-ë.', long: 'a-Ë' },
    { id: 14, short: 'a.', long: 'a' },
    { id: 15, short: 'ë.', long: 'ë' },
    { id: 16, short: 'nj.', long: 'nj' },
    { id: 17, short: 'inj. ë.', long: 'inj me rënie të ë' },
    { id: 18, short: 'inj.', long: 'inj' },
    { id: 19, short: 'j. zgj. n.', long: 'j + zgjerimi N' },
    { id: 20, short: 'j. zgj. r.', long: 'j + zgjerimi R' },
    { id: 21, short: 'enj.', long: 'enj' },
    { id: 22, short: 'ër.', long: 'ër' },
    { id: 23, short: 'ra.', long: 'ra' },
    { id: 24, short: 'na.', long: 'na' },
  ];

  public static EmriLakimi10Shumesi12Prapashtesa = [
    { id: 25, short: 'q. ll>j', long: 'qiellzorizim ll>j' },
    { id: 26, short: 'q. r>j', long: 'qiellzorizim r>j' },
    { id: 27, short: 'q. ll>j. z.p.', long: 'qiellzorizim ll>j dhe ndërrim zanorja e parë' },
    { id: 28, short: 'q. ll>j. z.d.', long: 'qiellzorizim ll>j dhe ndërrim zanorja e dytë' },
  ];

  public static EmriLakimi10Shumesi13Prapashtesa = [
    { id: 29, short: 'nd. z. ua>o nj.', long: 'ndërrim zanore ua>o + NJ' },
    { id: 30, short: 'q. ll>j. + e.', long: 'qiellzorizim ll>j, ndërrim zanoresh + E' },
  ];

  public static EmriLakimi10Shumesi14Prapashtesa = [
    { id: 31, short: 'a>e.', long: 'a>e' },
    { id: 32, short: 'e>a.', long: 'e>a' },
    { id: 33, short: 'i>e.', long: 'i>e' },
    { id: 34, short: 'nd. z. ë>u + ë.', long: 'ndërrim zanoresh ë>u + Ë' },
    { id: 35, short: 'q. g>gj + e.', long: 'qiellzorizim g>gj + E' },
  ];

  public static EmriLakimi10Shumesi15Prapashtesa = [{ id: 36, short: 'pj. nj.ndr.', long: 'me përbërje pjesërisht të njëjtë ose të ndryshme MANUALISHT' }];

  public static EmriLakimi11Shumesi11Prapashtesa = [
    { id: 15, short: 'ë.', long: 'ë' },
    { id: 23, short: 'ra.', long: 'ra' },
  ];

  public static EmriLakimi11Shumesi12Prapashtesa = [
    { id: 37, short: 'q. k>q', long: 'vetëm me qiellzorizim (k>q)' },
    { id: 38, short: 'q. g>gj', long: 'vetëm me qiellzorizim (g>gj)' },
  ];

  public static EmriLakimi11Shumesi13Prapashtesa = [
    { id: 39, short: 'q. k>q + e', long: 'vetëm me qiellzorizim (k>q) + E' },
    { id: 40, short: 'q. g>gj+ e', long: 'vetëm me qiellzorizim (g>gj) + E' },
    { id: 41, short: 'q. g>gj. z. e>i', long: 'me qiellzorizim g>gj, ndërrim zanoreje e>i + E' },
    { id: 42, short: 'q. k>q. z. a>e', long: 'me qiellzorizim k>q, ndërrim zanoreje a>e + E' },
  ];

  public static EmriLakimi11Shumesi16Prapashtesa = [{ id: 36, short: 'pj. nj.ndr.', long: 'me përbërje pjesërisht të njëjtë ose të ndryshme MANUALISHT' }];

  public static EmriLakimi12Shumesi10Prapashtesa = [
    { id: 43, short: 'sn. a.', long: 'si njëjësi A' },
    { id: 44, short: 'sn. ja.', long: 'si njëjësi JA' },
    { id: 45, short: 'sn. je.', long: 'si njëjësi JE' },
  ];

  public static EmriLakimi12Shumesi11Prapashtesa = [
    { id: 14, short: 'a.', long: 'a' },
    { id: 46, short: 'a. nyj.', long: 'a NYJË' },
    { id: 23, short: 'ra.', long: 'ra' },
  ];

  public static EmriLakimi12Shumesi15Prapashtesa = [{ id: 36, short: 'pj. nj.ndr.', long: 'me përbërje pjesërisht të njëjtë ose të ndryshme MANUALISHT' }];

  public static EmriLakimi13Prapashtesa = [
    { id: 47, short: 'pj. emërz. it.', long: 'pjesore të emërzuara IT' },
    { id: 48, short: 'pj. emërz. ët.', long: 'pjesore të emërzuara ËT' },
    { id: 49, short: 'mb. as. emërz.', long: 'mbiemra asnjanës të emërzuar' },
  ];

  //#endregion

  //#region FOLJA

  public static FoljaKalimtaresia = [
    { id: 10, short: 'jokal.', long: 'Jokalimtare' },
    { id: 11, short: 'kal.', long: 'Kalimtare' },
  ];

  public static FoljaZgjedhimiVecoria = [
    { id: 10, short: 'I', long: 'Zgjedhimi I' },
    { id: 11, short: 'II', long: 'Zgjedhimi II' },
    { id: 12, short: 'III', long: 'Zgjedhimi III' },
    { id: 13, short: 'prrg.', long: 'Zgjedhimi i parregullt' },
  ];

  public static FoljaMenyra = [
    { id: 10, short: 'dëft.', long: 'Dëftore' },
    { id: 11, short: 'hab.', long: 'Habitore' },
    { id: 12, short: 'lidh.', long: 'Lidhore' },
    { id: 13, short: 'lidh.-hab.', long: 'Lidhore-habitore' },
    { id: 14, short: 'kusht.', long: 'Kushtore' },
    { id: 15, short: 'dësh.', long: 'Dëshirore' },
    { id: 16, short: 'urdh.', long: 'Urdhërore' },
    { id: 17, short: 'fr.pashtj.', long: 'Format e pashtjelluara' },
  ];

  //#region Klasa

  public static FoljaZgjedhimi10Klasa = [
    { id: 10, short: 'k. I', long: 'Klasa I' },
    { id: 11, short: 'k. II', long: 'Klasa II' },
  ];

  public static FoljaZgjedhimi11Klasa = [
    { id: 10, short: 'k. I', long: 'Klasa I' },
    { id: 11, short: 'k. II', long: 'Klasa II' },
  ];

  public static FoljaZgjedhimi12Klasa = [
    { id: 10, short: 'k. I', long: 'Klasa I' },
    { id: 11, short: 'k. II', long: 'Klasa II' },
    { id: 12, short: 'k. III', long: 'Klasa III' },
  ];

  //#endregion

  //#region Nenklasa

  public static FoljaNenklasa = [
    { id: 10, short: 'nk. 1', long: 'Nënklasa 1' },
    { id: 11, short: 'nk. 2', long: 'Nënklasa 2' },
    { id: 12, short: 'nk. 3', long: 'Nënklasa 3' },
  ];

  public static FoljaZgjedhimi10Klasa10Nenklasa = [
    { id: 10, short: 'nk. 1', long: 'Nënklasa 1' },
    { id: 11, short: 'nk. 2', long: 'Nënklasa 2' },
  ];

  public static FoljaZgjedhimi10Klasa11Nenklasa = [
    { id: 10, short: 'nk. 1', long: 'Nënklasa 1' },
    { id: 11, short: 'nk. 2', long: 'Nënklasa 2' },
  ];

  public static FoljaZgjedhimi11Klasa10Nenklasa = [{ id: 10, short: 'nk. 1', long: 'Nënklasa 1' }];

  public static FoljaZgjedhimi11Klasa11Nenklasa = [
    { id: 10, short: 'nk. 1', long: 'Nënklasa 1' },
    { id: 11, short: 'nk. 2', long: 'Nënklasa 2' },
    { id: 12, short: 'nk. 3', long: 'Nënklasa 3' },
  ];

  public static FoljaZgjedhimi12Klasa10Nenklasa = [{ id: 10, short: 'nk. 1', long: 'Nënklasa 1' }];

  public static FoljaZgjedhimi12Klasa11Nenklasa = [{ id: 10, short: 'nk. 1', long: 'Nënklasa 1' }];

  public static FoljaZgjedhimi12Klasa12Nenklasa = [{ id: 10, short: 'nk. 1', long: 'Nënklasa 1' }];

  //#endregion

  //#region Lloji i temes

  public static FoljaLlojiTemes = [
    { id: 10, short: 't. -o', long: 'Me temë - o' },
    { id: 11, short: 't. -e', long: 'Me temë - e' },
    { id: 12, short: 't. -ua', long: 'Me temë - ua' },
    { id: 13, short: 't. -ye', long: 'Me temë - ye' },
    { id: 14, short: 'nnt.', long: 'Nuk ndryshojnë temë' },
    { id: 15, short: 'zt. m-t', long: 'Zgjerim teme m -t' },
    { id: 16, short: 'zt. m-jt', long: 'Zgjerim teme m -jt' },
    { id: 17, short: 'zt. m -r', long: 'Zgjerim teme m -r' },
    { id: 18, short: 'nt.', long: 'Kan të njëjtën temë' },
    { id: 19, short: 'fddf t/s', long: 'Folje me dysorë në disa forma t/s' },
    { id: 20, short: 't. -je 1', long: 'Me temë - je 1' },
    { id: 21, short: 't. -je 2', long: 'Me temë - je 2' },
    { id: 22, short: 't. -je 3', long: 'Me temë - je 3' },
    { id: 23, short: 't. - a 1', long: 'Me temë - a 1' },
    { id: 24, short: 't. - a 2', long: 'Me temë - a 2' },
    { id: 25, short: 'ftztp.', long: 'Foljet me temë të zgjeruar të tipit përkas' },
    { id: 26, short: 'f. sh., v., n. dhe g.', long: 'Foljet shkas, vras, ngas dhe gërgas' },
    { id: 27, short: 'kthntz. -r-', long: 'Në të kryerën e thjeshtë, njëjës, me temë të zgjeruar me -r-' },
    { id: 28, short: 'kthpztp. -t-', long: 'Në të kryerën e thjeshtë dhe në pjesore e zgjerojnë temën me prapashtesën -t-' },
    { id: 29, short: 'f. pi', long: 'Folja pi' },
    { id: 30, short: 'f. shtie', long: 'Folja shtie' },
    { id: 31, short: 'supl.', long: 'Supletive' },
    { id: 32, short: 'josupl.', long: 'Josupletive' },
  ];

  public static FoljaZgjedhimi10Klasa10Nenklasa10LlojiTemes = [
    { id: 10, short: 't. -o', long: 'Me temë - o' },
    { id: 11, short: 't. -e', long: 'Me temë - e' },
    { id: 12, short: 't. -ua', long: 'Me temë - ua' },
    { id: 13, short: 't. -ye', long: 'Me temë - ye' },
  ];

  public static FoljaZgjedhimi10Klasa10Nenklasa11LlojiTemes = [{ id: 14, short: 'nnt.', long: 'Nuk ndryshojnë temë' }];

  public static FoljaZgjedhimi10Klasa11Nenklasa10LlojiTemes = [
    { id: 15, short: 'zt. m-t', long: 'Zgjerim teme m -t' },
    { id: 16, short: 'zt. m-jt', long: 'Zgjerim teme m -jt' },
  ];

  public static FoljaZgjedhimi10Klasa11Nenklasa11LlojiTemes = [{ id: 17, short: 'zt. m -r', long: 'Zgjerim teme m -r' }];

  public static FoljaZgjedhimi11Klasa10Nenklasa10LlojiTemes = [
    { id: 18, short: 'nt.', long: 'Kan të njëjtën temë' },
    { id: 19, short: 'fddf t/s', long: 'Folje me dysorë në disa forma t/s' },
  ];

  public static FoljaZgjedhimi11Klasa11Nenklasa10LlojiTemes = [
    { id: 11, short: 't. -e', long: 'Me temë - e' },
    { id: 20, short: 't. -je 1', long: 'Me temë - je 1' },
    { id: 21, short: 't. -je 2', long: 'Me temë - je 2' },
    { id: 22, short: 't. -je 3', long: 'Me temë - je 3' },
    { id: 23, short: 't. - a 1', long: 'Me temë - a 1' },
    { id: 24, short: 't. - a 2', long: 'Me temë - a 2' },
  ];

  public static FoljaZgjedhimi11Klasa11Nenklasa11LlojiTemes = [{ id: 25, short: 'ftztp.', long: 'Foljet me temë të zgjeruar të tipit përkas' }];

  public static FoljaZgjedhimi11Klasa11Nenklasa12LlojiTemes = [{ id: 26, short: 'f. sh., v., n. dhe g.', long: 'Foljet shkas, vras, ngas dhe gërgas' }];

  public static FoljaZgjedhimi12Klasa10Nenklasa10LlojiTemes = [
    { id: 27, short: 'kthntz. -r-', long: 'Në të kryerën e thjeshtë, njëjës, me temë të zgjeruar me -r-' },
  ];

  public static FoljaZgjedhimi12Klasa11Nenklasa10LlojiTemes = [
    { id: 28, short: 'kthpztp. -t-', long: 'Në të kryerën e thjeshtë dhe në pjesore e zgjerojnë temën me prapashtesën -t-' },
  ];

  public static FoljaZgjedhimi12Klasa12Nenklasa10LlojiTemes = [
    { id: 29, short: 'f. pi', long: 'Folja pi' },
    { id: 30, short: 'f. shtie', long: 'Folja shtie' },
  ];

  public static FoljaZgjedhimi13Klasa10LlojiTemes = [{ id: 31, short: 'supl.', long: 'Supletive' }];

  public static FoljaZgjedhimi13Klasa11LlojiTemes = [{ id: 32, short: 'josupl.', long: 'Josupletive' }];

  //#endregion

  public static FoljaForma = [
    { id: 10, short: 'vep.', long: 'Veprore' },
    { id: 11, short: 'jovep.', long: 'Joveprore' },
  ];

  public static FoljaVeta = [
    { id: 10, short: 'v.I', long: 'E parë' },
    { id: 11, short: 'v.II', long: 'E dytë' },
    { id: 12, short: 'v.III', long: 'E tretë' },
    { id: 13, short: 'njëv.', long: 'Njëvetore' },
  ];

  public static FoljaKoha = [
    { id: 10, short: 'tash.', long: 'E tashme' },
    { id: 11, short: 'kr.', long: 'E kryer' },
    { id: 12, short: 'pakr.', long: 'E pakryer' },
    { id: 13, short: 'm. kr.', long: 'Më se e kryer' },
    { id: 14, short: 'kr. thj.', long: 'E kryer e thjeshtë' },
    { id: 15, short: 'kr. tej.', long: 'E kryer e tejshkuar' },
    { id: 16, short: 'ardh.', long: 'E ardhme' },
    { id: 17, short: 'ardh. shk.', long: 'E ardhme e së shkuarës' },
    { id: 18, short: 'ardh. II', long: 'E ardhme II' },
    { id: 19, short: 'ardh. shk. II', long: 'E ardhme e së shkuarës II' },
    { id: 20, short: 'ardh. përp.', long: 'E ardhme e përparme' },
    { id: 21, short: 'ardh. përp. shk.', long: 'E ardhme e përparme e së shkuarës' },
    { id: 22, short: 'kr. mbp.', long: 'E kryer e mbipërbërë' },
    { id: 23, short: 'm. kr. mbp.', long: 'Më se e kryer e mbipërbërë' },
  ];

  public static FoljaDiateza = [
    { id: 10, short: 'kusht.', long: 'Kushtore' },
    { id: 11, short: 'vepr.', long: 'Veprore' },
    { id: 12, short: 'pës.', long: 'Pësore' },
    { id: 13, short: 'vetv.', long: 'Vetvetore' },
    { id: 14, short: 'mes.', long: 'Mesore' },
  ];

  public static FoljaFormaPashtjelluar = [
    { id: 10, short: 'pjes.', long: 'Pjesore' },
    { id: 11, short: 'përcj.', long: 'Përcjellore' },
    { id: 12, short: 'pask. I', long: 'Paskajore I' },
    { id: 13, short: 'pask. II', long: 'Paskajore II' },
    { id: 14, short: 'pask. III', long: 'Paskajore III' },
    { id: 15, short: 'pask. geg.', long: 'Paskajore gege*' },
    { id: 16, short: 'moh.', long: 'Mohore' },
  ];

  public static FoljaPeremri = [
    { id: 10, short: 'u.', long: 'Unë' },
    { id: 11, short: 't.', long: 'Ti' },
    { id: 12, short: 'a.', long: 'Ai/Ajo' },
    { id: 13, short: 'n.', long: 'Ne' },
    { id: 14, short: 'j.', long: 'Ju' },
    { id: 15, short: 'at.', long: 'Ata/Ato' },
  ];

  //#endregion

  //#region MBIEMRI

  public static MbiemriLloji = [
    { id: 10, short: 'nyj.', long: 'I nyjshëm' },
    { id: 11, short: 'panyj.', long: 'I panyjshëm' },
  ];

  public static MbiemriTemaShumesit = [
    { id: 10, short: 'shnn.', long: 'Shumësi me ndryshim nyje' },
    { id: 11, short: 'shnnnj.', long: 'Shumësi me ndryshim nyje + nj' },
    { id: 12, short: 'shnnneëi.', long: 'Shumësi me ndryshim nyje +e>ë + inj' },
    { id: 13, short: 'nnggj.', long: 'Ndryshim nyje +g>gj' },
    { id: 14, short: 'shnnoeggj.', long: 'Shumësi me ndryshim nyje + o>e +g>gj' },
    { id: 15, short: 'shnnaëe.', long: 'Shumësi me ndryshim nyje + a>ë +enj' },
    { id: 16, short: 'mtmsh.', long: 'Mbiemër me temë më-shëm' },
    { id: 17, short: 'mtmë.', long: 'Mbiemër me temë më-ëm' },
    { id: 18, short: 'mtmël.', long: 'Mbiemër me temë më-ël' },
    { id: 19, short: 'mtmm.', long: 'Mbiemër me temë më-më' },
    { id: 20, short: 'shmë.', long: 'Shumësi me mbaresën -ë' },
    { id: 21, short: 'shsnj.', long: 'Shumësi me si njëjësi' },
    { id: 22, short: 'shmnj.', long: 'Shumësi me mbaresën -nj' },
    { id: 23, short: 'shemf.', long: 'Shumësi si emrat mashkullor dhe femërorë (konversion)' },
    { id: 24, short: 'shpm.', long: 'Shumësi si pjesa II e mbiemrit (si mbiemri)' },
    { id: 25, short: 'pem.', long: 'Pjesa II emër mashkullor (kompozitë me pjesë II emër mashkullor)' },
    { id: 26, short: 'shp. II m.', long: 'Shumësi si pjesa II e mbiemrit (kompozitë me pjesë II një emër femerorë ose lënde)' },
  ];

  public static MbiemriLloji10TemaShumesit = [
    { id: 10, short: 'shnn.', long: 'Shumësi me ndryshim nyje' },
    { id: 11, short: 'shnnnj.', long: 'Shumësi me ndryshim nyje + nj' },
    { id: 12, short: 'shnnneëi.', long: 'Shumësi me ndryshim nyje +e>ë + inj' },
    { id: 13, short: 'nnggj.', long: 'Ndryshim nyje +g>gj' },
    { id: 14, short: 'shnnoeggj.', long: 'Shumësi me ndryshim nyje + o>e +g>gj' },
    { id: 15, short: 'shnnaëe.', long: 'Shumësi me ndryshim nyje + a>ë +enj' },
    { id: 16, short: 'mtmsh.', long: 'Mbiemër me temë më-shëm' },
    { id: 17, short: 'mtmë.', long: 'Mbiemër me temë më-ëm' },
    { id: 18, short: 'mtmël.', long: 'Mbiemër me temë më-ël' },
    { id: 19, short: 'mtmm.', long: 'Mbiemër me temë më-më' },
  ];

  public static MbiemriLloji11TemaShumesit = [
    { id: 20, short: 'shmë.', long: 'Shumësi me mbaresën -ë' },
    { id: 21, short: 'shsnj.', long: 'Shumësi me si njëjësi' },
    { id: 22, short: 'shmnj.', long: 'Shumësi me mbaresën -nj' },
    { id: 23, short: 'shemf.', long: 'Shumësi si emrat mashkullor dhe femërorë (konversion)' },
    { id: 24, short: 'shpm.', long: 'Shumësi si pjesa II e mbiemrit (si mbiemri)' },
    { id: 25, short: 'pem.', long: 'Pjesa II emër mashkullor (kompozitë me pjesë II emër mashkullor)' },
    { id: 26, short: 'shp. II m.', long: 'Shumësi si pjesa II e mbiemrit (kompozitë me pjesë II një emër femerorë ose lënde)' },
  ];

  public static MbiemriGjinia = [
    { id: 10, short: 'm.', long: 'Mashkullore' },
    { id: 11, short: 'f.', long: 'Femërore' },
    { id: 12, short: 'as.', long: 'Asnjanëse' },
  ];

  public static MbiemriNumri = [
    { id: 10, short: 'nj.', long: 'Njëjës' },
    { id: 11, short: 'sh.', long: 'Shumës' },
  ];

  public static MbiemriRasa = [
    { id: 10, short: 'e.', long: 'Emërore' },
    { id: 11, short: 'gj.', long: 'Gjinore' },
    { id: 12, short: 'dh.', long: 'Dhanore' },
    { id: 13, short: 'k.', long: 'Kallëzore' },
    { id: 14, short: 'rr.', long: 'Rrjedhore' },
    { id: 15, short: 'vd.', long: 'Vendore' },
    { id: 16, short: 'th.', long: 'Thirrore' },
  ];

  public static MbiemriShkalla = [
    { id: 10, short: 'poh.', long: 'Pohore' },
    { id: 11, short: 'krhs.', long: 'Krahasore' },
    { id: 12, short: 'sip.', long: 'Sipërore' },
  ];

  //#endregion

  //#region PËREMRI

  public static PeremriNumri = [
    { id: 10, short: 'nj.', long: 'Njëjës' },
    { id: 11, short: 'sh.', long: 'Shumës' },
  ];

  public static PeremriLloji = [
    { id: 10, long: 'Vetor', short: 'v.' },
    { id: 11, long: 'Vetvetor', short: 'vv.' },
    { id: 12, long: 'Dëftor', short: 'd.' },
    { id: 13, long: 'Pronor', short: 'p.' },
    { id: 14, long: 'Pyetës', short: 'pt.' },
    { id: 15, long: 'Lidhor', short: 'l.' },
    { id: 16, long: 'I pacaktuar', short: 'pc.' },
  ];

  public static PeremriGjinia = [
    { id: 10, short: 'm.', long: 'Mashkullore' },
    { id: 11, short: 'f.', long: 'Femërore' },
  ];

  public static PeremriRasa = [
    { id: 10, short: 'e.', long: 'Emërore' },
    { id: 11, short: 'gj.', long: 'Gjinore' },
    { id: 12, short: 'dh.', long: 'Dhanore' },
    { id: 13, short: 'k.', long: 'Kallëzore' },
    { id: 14, short: 'rr.', long: 'Rrjedhore' },
    { id: 15, short: 'th.', long: 'Thirrore' },
    { id: 16, short: 'v.', long: 'Vendore*' },
  ];

  //#endregion

  public static EPapercaktuarLloji = [
    { id: 10, short: 'n.', long: 'Nyje' },
    { id: 11, short: 'onomat.', long: 'Onomatope' },
    { id: 12, short: 'shpreh.', long: 'Shprehje' },
    { id: 13, short: 'fj.form.', long: 'Fjaleformë' },
    { id: 14, short: 'shkurt.', long: 'Shkurtim' },
    { id: 15, short: 'shkurts.', long: 'Shkurtesë' },
    { id: 16, short: 'lok.ndjf.', long: 'Lokucion ndajfoljor' },
    { id: 17, short: 'lok.prf.', long: 'Lokucion parafjalor' },
    { id: 18, short: 'lok.ldhz.', long: 'Lokucion lidhëzor' },
    { id: 19, short: 'fraz.', long: 'Frazeologji' },
    { id: 19, short: 'palak.', long: 'I palakueshëm' },
  ];

  public static NumeroriGjinia = [
    { id: 10, short: 'm.', long: 'Mashkullore' },
    { id: 11, short: 'f.', long: 'Femërore' },
  ];

  public static PjesezaLlojiVecoria = [
    { id: 10, short: 'ngj.kp.', long: 'Me ngjyrime kuptimore' },
    { id: 11, short: 'mod.', long: 'Modale' },
    { id: 12, short: 'emc.shp.', long: 'Emocionuese-shprehëse' },
    { id: 13, short: 'fr.gram.', long: 'Formante gramatikore' },
  ];
}
