import { Injectable } from '@angular/core';
import { StrukturaStore } from '@core/classes/struktura-store.class';
import { FrazeologjiaEtiketaStruktura } from '@core/interfaces/models/frazeologjia-etiketa-struktura.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class FrazeologjiaEtiketaStrukturaService extends StrukturaStore<FrazeologjiaEtiketaStruktura> {

  constructor(apiService: ApiService) {
    super(apiService, 'frazeologjiaEtiketaStruktura');
  }

  updateMy(items: FrazeologjiaEtiketaStruktura[]) {
    return this.apiService.put<FrazeologjiaEtiketaStruktura[]>(`${this._endpointUrl}my`, { frazeologjiaEtiketaStrukturaForCreationDTO: items });
  }

}
