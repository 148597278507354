import { Injectable } from '@angular/core';
import { StrukturaStore } from '@core/classes/struktura-store.class';
import { ShembulliFjalaPerzgjedhurStruktura } from '@core/interfaces/models/shembulli-fjala-perzgjedhur-struktura.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ShembulliFjalaPerzgjedhurStrukturaService extends StrukturaStore<ShembulliFjalaPerzgjedhurStruktura> {

  constructor(apiService: ApiService) {
    super(apiService, 'shembulliFjalaPerzgjedhurStruktura');
  }

  updateMy(items: ShembulliFjalaPerzgjedhurStruktura[]) {
    return this.apiService.put<ShembulliFjalaPerzgjedhurStruktura[]>(`${this._endpointUrl}my`, { shembulliFjalaPerzgjedhurStrukturaForCreationDTO: items });
  }

}