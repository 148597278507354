import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OperatoriFaqjaService } from '@core/services/operatori-faqja.service';
import { ResizableDragDropComponent } from '@shared/components/resizable-drag-drop/resizable-drag-drop.component';
import { take } from 'rxjs';

@Component({
  selector: 'app-shenimet-popup',
  templateUrl: './shenimet-popup.component.html',
  styleUrls: ['./shenimet-popup.component.scss'],
})
export class ShenimetPopupComponent extends ResizableDragDropComponent implements OnInit {
  notesValue?: string;

  loading: boolean = false;

  constructor(
    host: ElementRef,
    renderer: Renderer2,
    public dialogRef: MatDialogRef<ShenimetPopupComponent>,
    private operatoriFaqjaService: OperatoriFaqjaService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(host, renderer, 'app-shenimet-popup');
  }

  ngOnInit(): void {
    this.operatoriFaqjaService
      .listenOnFieldChanges('shenimet')
      .pipe(take(1))
      .subscribe((shenimet) => {
        this.notesValue = shenimet;
      });
  }

  notesChanged(value: string) {
    this.notesValue = value;
    this.operatoriFaqjaService.updateItems({ shenimet: value });
  }

  override close() {
    super.close();
    this.dialogRef.close();
  }
}
