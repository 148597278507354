import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { ShembulliEtiketaForCreationDTO } from '@core/interfaces/dto/creation/shembulli-etiketa-for-creation-dto.interface';
import { ShembulliEtiketaForUpdateDTO } from '@core/interfaces/dto/update/shembulli-etiketa-for-update-dto.interface';
import { ShembulliEtiketa } from '@core/interfaces/models/shembulli-etiketa.interface';
import { ShembulliEtiketaParams } from '@core/interfaces/params/shembulli-etiketa-params.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class ShembulliEtiketaService extends Store<ShembulliEtiketa, ShembulliEtiketaParams, ShembulliEtiketaForCreationDTO, ShembulliEtiketaForUpdateDTO> {
  constructor(apiService: ApiService) {
    super(apiService, 'shembulliEtiketa');
  }
}
