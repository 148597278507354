<div class="wrapper">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'search_multiple_words' | phrase }}</mat-label>
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let sItem of searchItems$ | async" (removed)="remove(sItem)">
        {{ sItem }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        #fruitInput
        [formControl]="searchItemCtrl"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        autocomplete="off"
        (matChipInputTokenEnd)="add($event)"
        cdkFocusInitial
      />
    </mat-chip-list>
    <mat-slide-toggle matSuffix [matTooltip]="'whole_word'" [formControl]="searchWholeWord"></mat-slide-toggle>
  </mat-form-field>
  <div class="content">
    <ng-container *ngIf="!loading && list.length > 0">
      <div class="items">
        <ng-container *ngFor="let item of list; let i = index" >
          <div class="item" matRipple (click)="onSentenceClick(item.sentence)">
            <span class="sentence" [innerHTML]="item.sentence | highlight: { phrase: highlightText, wholeWord: false }"></span>
          </div>
          <mat-divider></mat-divider>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="loading">
      <div class="loading">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading && count === 0">
      <div class="notFound">
        {{ 'not_found' | phrase }}
      </div>
    </ng-container>
  </div>
  <mat-paginator #paginator [length]="count" [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 50, 100]" (page)="pageChanged($event)"> </mat-paginator>
</div>
