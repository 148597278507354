import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { authAppInitializerFactory } from './auth/auth-app-initializer.factory';
import { authConfig } from './auth/auth-config';
import { authModuleConfig } from './auth/auth-module-config';
import { AuthService } from './auth/auth.service';
import { ForbiddenComponent } from './auth/forbidden/forbidden.component';
import { LoginCallbackComponent } from './auth/login-callback/login-callback.component';
import { UnauthorizedComponent } from './auth/unauthorized/unauthorized.component';
import { AuthConfig, OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { PerdoruesitComponent } from './header/perdoruesit/perdoruesit.component';
import { PerdoruesiInfoComponent } from './header/perdoruesit/perdoruesi-info/perdoruesi-info.component';
import { frazatInitializerFactory } from './factories/frazat-initializer.factory';
import { FrazatService } from './services/frazat.service';
import { FjalaNavButtonsComponent } from './header/fjala-nav-buttons/fjala-nav-buttons.component';
import { ShembujtNavButtonsComponent } from './header/shembujt-nav-buttons/shembujt-nav-buttons.component';
import { ShenimetPopupComponent } from './header/shenimet-popup/shenimet-popup.component';
import { ProverbaNavButtonsComponent } from './header/proverba-nav-buttons/proverba-nav-buttons.component';
import { FrazeologjiaNavButtonsComponent } from './header/frazeologjia-nav-buttons/frazeologjia-nav-buttons.component';
import { FjalaHuajNavButtonsComponent } from './header/fjala-huaj-nav-buttons/fjala-huaj-nav-buttons.component';
import { FjalaGabimNavButtonsComponent } from './header/fjala-gabim-nav-buttons/fjala-gabim-nav-buttons.component';

@NgModule({
  declarations: [LoginCallbackComponent, HeaderComponent, UnauthorizedComponent, ForbiddenComponent, PerdoruesitComponent, PerdoruesiInfoComponent, FjalaNavButtonsComponent, ShembujtNavButtonsComponent, ShenimetPopupComponent, ProverbaNavButtonsComponent, FrazeologjiaNavButtonsComponent, FjalaHuajNavButtonsComponent, FjalaGabimNavButtonsComponent],
  imports: [SharedModule, RouterModule, OAuthModule.forRoot()],
  exports: [HeaderComponent, UnauthorizedComponent],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: authAppInitializerFactory,
          deps: [AuthService],
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: frazatInitializerFactory,
          deps: [FrazatService],
          multi: true,
        },
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
