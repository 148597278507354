import { Injectable } from '@angular/core';
import { StrukturaStore } from '@core/classes/struktura-store.class';
import { EtiketaStruktura } from '@core/interfaces/models/etiketa-struktura.inteface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class EtiketaStrukturaService extends StrukturaStore<EtiketaStruktura> {

  constructor(apiService: ApiService) {
    super(apiService, 'etiketaStruktura');
  }

  updateMy(items: EtiketaStruktura[]) {
    return this.apiService.put<EtiketaStruktura[]>(`${this._endpointUrl}my`, { etiketaStrukturaForCreationDTO: items });
  }

}
