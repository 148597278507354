import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { ParacaktimiKerkimitForCreationDTO } from '@core/interfaces/dto/creation/paracaktimi-kerkimit-for-creation-dto.interface';
import { ParacaktimiKerkimitForUpdateDTO } from '@core/interfaces/dto/update/paracaktimi-kerkimit-for-update-dto.interface';
import { ParacaktimiKerkimit } from '@core/interfaces/models/paracaktimi-kerkimit.interface';
import { ParacaktimiKerkimitParams } from '@core/interfaces/params/paracaktimi-kerkimit-params.interface';
import { tap, map } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ParacaktimiKerkimitService extends Store<ParacaktimiKerkimit, ParacaktimiKerkimitParams, ParacaktimiKerkimitForCreationDTO, ParacaktimiKerkimitForUpdateDTO> {

  constructor(apiService: ApiService) {
    super(apiService, 'paracaktimiKerkimit');
  }

  override load(params?: ParacaktimiKerkimitParams) {
    return this.getMy(params).pipe(
      map((items) => items.sort((a, b) => a.radhitja - b.radhitja)),
      tap((items) => {
        this._store$.next(items);
        this._dataLoaded$.next(true);
      })
    );
  }

  getMy(params?: ParacaktimiKerkimitParams) {
    return this.apiService.get<ParacaktimiKerkimit[]>(`${this._endpointUrl}my`, params);
  }

  override update(item: ParacaktimiKerkimitForUpdateDTO) {
    return this.apiService.put<ParacaktimiKerkimit>(`${this._endpointUrl}${item.id}`, item).pipe(
      tap(() => {
        this.updateInStore(item);
      })
    )
  }

  updateMultiple(items: ParacaktimiKerkimitForUpdateDTO[]) {
    return this.apiService.put<ParacaktimiKerkimit[]>(`${this._endpointUrl}`, items).pipe(
      tap(() => {
        const store = [...this._store$.value];
        items.forEach(item => {
          const i = store.findIndex(x => x.id == item.id);
          store.splice(i, 1, item);
        });
        this._store$.next(store);
      })
    );
  }

}
