import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { ProverbaParacaktimiKerkimitForCreationDTO } from '@core/interfaces/dto/creation/proverba-paracaktimi-kerkimit-for-creation-dto.interface';
import { ProverbaParacaktimiKerkimitForUpdateDTO } from '@core/interfaces/dto/update/proverba-paracaktimi-kerkimit-for-update-dto.interface';
import { ProverbaParacaktimiKerkimit } from '@core/interfaces/models/proverba-paracaktimi-kerkimit.interface';
import { ProverbaParacaktimiKerkimitParams } from '@core/interfaces/params/provevrba-paracaktimi-kerkimit-params.interface';
import { map, tap } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class ProverbaParacaktimiKerkimitService extends Store<
  ProverbaParacaktimiKerkimit,
  ProverbaParacaktimiKerkimitParams,
  ProverbaParacaktimiKerkimitForCreationDTO,
  ProverbaParacaktimiKerkimitForUpdateDTO
> {
  constructor(apiService: ApiService) {
    super(apiService, 'ProverbaParacaktimiKerkimit');
  }

  override load(params?: ProverbaParacaktimiKerkimitParams) {
    return this.getMy(params).pipe(
      map((items) => items.sort((a, b) => a.radhitja - b.radhitja)),
      tap((items) => {
        this._store$.next(items);
        this._dataLoaded$.next(true);
      })
    );
  }

  getMy(params?: ProverbaParacaktimiKerkimitParams) {
    return this.apiService.get<ProverbaParacaktimiKerkimit[]>(`${this._endpointUrl}my`, params);
  }

  override update(item: ProverbaParacaktimiKerkimitForUpdateDTO) {
    return this.apiService.put<ProverbaParacaktimiKerkimit>(`${this._endpointUrl}${item.id}`, item).pipe(
      tap(() => {
        this.updateInStore(item);
      })
    );
  }

  updateMultiple(items: ProverbaParacaktimiKerkimitForUpdateDTO[]) {
    return this.apiService.put<ProverbaParacaktimiKerkimit[]>(`${this._endpointUrl}`, items).pipe(
      tap(() => {
        const store = [...this._store$.value];
        items.forEach((item) => {
          const i = store.findIndex((x) => x.id == item.id);
          store.splice(i, 1, item);
        });
        this._store$.next(store);
      })
    );
  }
}
