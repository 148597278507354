import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@core/auth/auth.service';
import { FjalaPermission } from '@core/enums/fjala-permission.enum';
import { PermissionName } from '@core/enums/permissions/permission-name.enum';
import { PermissionType } from '@core/enums/permissions/permission-type.enum';
import { OperatoriFaqja } from '@core/interfaces/models/operatori-faqja.interface';
import { BazaService } from '@core/services/baza.service';
import { EditoriService } from '@core/services/editori.service';
import { FjalaNavbarService } from '@core/services/fjala-navbar.service';
import { OperatoriFaqjaService } from '@core/services/operatori-faqja.service';
import { QasjaService } from '@core/services/qasja.service';
import { AsSentenceProcessorComponent } from '@shared/modules/as-sentence-processor/as-sentence-processor.component';
import { AsSnackBar } from '@shared/modules/as-snack-bar/as-snack-bar.service';
import { PhrasePipe } from '@shared/modules/phrase/phrase.pipe';
import { combineLatest, debounceTime, distinctUntilChanged, map, switchMap, of, pairwise, Subject, takeUntil } from 'rxjs';
import { ShenimetPopupComponent } from '../shenimet-popup/shenimet-popup.component';

@Component({
  selector: 'app-fjala-nav-buttons',
  templateUrl: './fjala-nav-buttons.component.html',
  styleUrls: ['./fjala-nav-buttons.component.scss'],
})
export class FjalaNavButtonsComponent implements OnInit, OnDestroy {
  formDirty: boolean = false;
  formValid: boolean = false;

  prevFjalaId: number | null = null;
  nextFjalaId: number | null = null;

  activeBazaId: number | null = null;
  bazaKryesoreId: number | null = null;

  navFjaloriId: number | null = null;
  nrOfVisibleDictionaries: number | null = null;

  jumpBazaId: number | null = null;

  canUndo: boolean = false;
  canRedo: boolean = false;

  saving: boolean = false;

  canAddEditFjala: boolean = false;
  canDelete: boolean = false;

  PermissionName = PermissionName;
  PermissionType = PermissionType;
  FjalaPermission = FjalaPermission;

  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private asSnackBar: AsSnackBar,
    private phrasePipe: PhrasePipe,
    private matDialog: MatDialog,
    private editoriService: EditoriService,
    private bazaService: BazaService,
    private operatoriFaqjaService: OperatoriFaqjaService,
    private fjalaNavbarService: FjalaNavbarService,
    private qasjaService: QasjaService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.editoriService.editoriForm.valueChanges.pipe(debounceTime(0), takeUntil(this._onDestroy$)).subscribe(() => {
      this.formDirty = this.editoriService.editoriForm.dirty;
      this.formValid = this.editoriService.editoriForm.valid;
    });

    combineLatest([
      this.editoriService.editoriForm.controls.editoriBazaKryesore.controls.baza.controls.id.valueChanges.pipe(distinctUntilChanged()),
      this.editoriService.editoriForm.controls.editoriBazaKryesore.controls.baza.controls.fjaloriId.valueChanges.pipe(distinctUntilChanged()),
      this.editoriService.editoriForm.controls.editoriBazaKryesore.controls.baza.controls.burimiId.valueChanges.pipe(distinctUntilChanged()),
      this.editoriService.getSaving(),
    ])
      .pipe(
        debounceTime(0),
        switchMap(([bazaKryesoreId, fjaloriId, burimiId, saving]) => {
          if (bazaKryesoreId != null && !saving) {
            return this.qasjaService.hasQasjet([
              { emri: PermissionName.FjalaFjalori, tipi: PermissionType.Delete, vlera: fjaloriId?.toString() },
              { emri: PermissionName.FjalaBurimi, tipi: PermissionType.View, vlera: burimiId?.toString() },
            ]);
          }
          return of(false);
        }),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((canDelete) => {
        this.canDelete = canDelete;
      });

    this.editoriService.editoriForm.controls.editoriBazaKryesore.controls.baza.controls.id.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this._onDestroy$))
      .subscribe((id) => {
        this.bazaKryesoreId = id;
      });

    this.operatoriFaqjaService
      .listenOnFieldChanges('bazaId')
      .pipe(pairwise(), takeUntil(this._onDestroy$))
      .subscribe(([prev, next]) => {
        if (prev != null && next != null) {
          this.jumpBazaId = prev;
        } else {
          this.jumpBazaId = null;
        }
      });

    this.editoriService
      .getSaving()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((saving) => {
        this.saving = saving;
      });

    this.editoriService
      .canUndo()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((canUndo) => {
        this.canUndo = canUndo;
      });

    this.editoriService
      .canRedo()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((canRedo) => {
        this.canRedo = canRedo;
      });

    this.fjalaNavbarService
      .getNextFjala()
      .pipe(debounceTime(0), takeUntil(this._onDestroy$))
      .subscribe((id) => {
        this.nextFjalaId = id;
      });

    this.fjalaNavbarService
      .getPrevFjala()
      .pipe(debounceTime(0), takeUntil(this._onDestroy$))
      .subscribe((id) => {
        this.prevFjalaId = id;
      });

    this.operatoriFaqjaService
      .listenOnFieldChanges('bazaId')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((id) => {
        this.activeBazaId = id;
      });

    this.operatoriFaqjaService
      .listenOnFieldChanges('navigationDictionaryId')
      .pipe(
        switchMap((fjaloriId) => {
          this.navFjaloriId = fjaloriId;
          return this.qasjaService.hasQasja(PermissionName.FjalaFjalori, PermissionType.AddEdit, fjaloriId.toString());
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe((hasQasje) => {
        this.canAddEditFjala = hasQasje;
      });

    this.operatoriFaqjaService
      .listenOnFieldChanges('nrOfVisibleDictionaries')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((nr) => {
        this.nrOfVisibleDictionaries = nr;
      });
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
      if(this.nextFjalaId != null)
        this.onSaveAndNextClick();
      else
        this.onSaveWordClick();
      event.preventDefault();
    }
  }

  jump() {
    if (this.jumpBazaId && !this.saving) {
      if (this.formDirty) {
        this.editoriService.getCanChangeWord().subscribe((canChange) => {
          if (canChange) {
            this.operatoriFaqjaService.updateItems({ bazaId: this.jumpBazaId });
          }
        });
      } else {
        this.operatoriFaqjaService.updateItems({ bazaId: this.jumpBazaId });
      }
    }
  }

  undo() {
    if (this.canUndo) this.editoriService.undo();
  }

  redo() {
    if (this.canRedo) this.editoriService.redo();
  }

  onEditorClearClick() {
    if (!this.saving) this.fjalaNavbarService.triggerOnEditorClearClick();
  }

  onDeleteClick() {
    if (this.bazaKryesoreId && !this.saving) this.fjalaNavbarService.triggerDeleteClick();
  }

  onNextClick() {
    if (this.nextFjalaId && !this.saving) this.fjalaNavbarService.triggerNextClick();
  }

  onPrevClick() {
    if (this.prevFjalaId && !this.saving) this.fjalaNavbarService.triggerPrevClick();
  }

  onSaveAndPrevClick() {
    if (this.formDirty && this.formValid && this.prevFjalaId && !this.saving) this.fjalaNavbarService.triggerSaveAndPrevClick();
  }

  onSaveWordClick() {
    if (this.formDirty && this.formValid && !this.saving) this.fjalaNavbarService.triggerSaveClick();
  }

  onSaveAndNextClick() {
    if (this.formDirty && this.formValid && this.nextFjalaId && !this.saving) this.fjalaNavbarService.triggerSaveAndNextClick();
  }

  onCommentsClick() {
    if (this.bazaKryesoreId) this.fjalaNavbarService.triggerCommentsClick();
  }

  setNrOfDictionaries(nr: number) {
    if (this.nrOfVisibleDictionaries == nr) return;
    if (this.nrOfVisibleDictionaries && this.nrOfVisibleDictionaries < nr) {
      this.operatoriFaqjaService.updateItems({ nrOfVisibleDictionaries: nr });
    } else {
      if (this.formDirty) {
        this.editoriService.getCanChangeWord().subscribe((canChange) => {
          if (canChange) {
            const items: Partial<OperatoriFaqja> = {};
            items.nrOfVisibleDictionaries = nr;
            if (nr < 3) {
              items.thirdDictionary = null;
              items.thirdDictionaryId = null;
            }
            if (nr < 2) {
              items.secondDictionary = null;
              items.secondDictionaryId = null;
            }
            this.operatoriFaqjaService.updateItems(items);
            this.editoriService.resetForm();
          }
        });
      } else {
        const items: Partial<OperatoriFaqja> = {};
        items.nrOfVisibleDictionaries = nr;
        if (nr < 3) {
          items.thirdDictionary = null;
          items.thirdDictionaryId = null;
        }
        if (nr < 2) {
          items.secondDictionary = null;
          items.secondDictionaryId = null;
        }
        this.operatoriFaqjaService.updateItems(items);
      }
    }
  }

  openSentenceAutosuggest() {
    this.matDialog.open(AsSentenceProcessorComponent, {
      disableClose: true,
      panelClass: 'big-resizable-dialog',
    });
  }

  openShtoFjalenShpejtDialog() {
    if (this.navFjaloriId) {
      this.fjalaNavbarService.triggerShtoFjalenShpejtClick();
    }
  }

  openShenimet() {
    this.matDialog.open(ShenimetPopupComponent, {
      disableClose: true,
      panelClass: 'big-resizable-dialog',
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
