import { Injectable } from '@angular/core';
import { Burimi } from '@core/interfaces/models/burimi.interface';
import { BehaviorSubject, tap, throwError } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class BurimiService {
  private _baseUrl: string = 'burimi/';

  protected _store$: BehaviorSubject<Burimi[]> = new BehaviorSubject<Burimi[]>([]);

  private _dataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService) {}

  load() {
    return this.get().pipe(
      tap((items) => {
        this._store$.next(items);
        this._dataLoaded$.next(true);
      })
    );
  }

  get() {
    return this.apiService.get<Burimi[]>(`${this._baseUrl}`);
  }

  getFromStore() {
    return this._store$.asObservable();
  }
}
