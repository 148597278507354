import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.ssoUrl,
  tokenEndpoint: environment.ssoUrl + '/connect/token',
  revocationEndpoint: environment.ssoUrl + '/connect/revocation',
  clientId: 'lexicography_SPA',
  responseType: 'code',
  redirectUri: environment.baseUrl,
  silentRefreshRedirectUri: environment.baseUrl + 'silent-refresh.html',
  scope: 'openid profile email roles lexicography_API lex_roles offline_access IdProviderAdmin.Api sentence_roles', // Ask offline_access to support refresh token refreshes
  useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  silentRefreshTimeout: 5000, // For faster testing
  timeoutFactor: 0.25, // For faster testing
  sessionChecksEnabled: true,
  showDebugInformation: false, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
};
