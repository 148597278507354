export enum FjalaPermission {
    Fjala = "Fjala",
    Nyja = "Nyja",
    Homonimi = "Homonimi",
    PjesaLigjerates = "PjesaLigjerates",
    Pershkrimi = "Pershkrimi",
    Materialet = "Materialet",
    Shembujt = "Shembujt",
    Sinonimet = "Sinonimet",
    Antonimet = "Antonimet",
    FushaPerdorimit = "FushaPerdorimit",
    Proverba = "Proverba",
    Frazeologjia = "Frazeologjia",
    FjalaGabim = "FjalaGabim",
    FjalaPerzier = "FjalaPerzier",
    FjalaHuaj = "FjalaHuaj",
    Etimologjia = "Etimologjia",
    FjalaPerzgjedhur = "FjalaPerzgjedhur",
    Referenca = "Referenca",
    Etiketa = "Etiketa",
    Burimi = "Burimi"
}