import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { EtiketaForCreationDTO } from '@core/interfaces/dto/creation/etiketa-for-creation-dto.interface';
import { EtiketaForUpdateDTO } from '@core/interfaces/dto/update/etiketa-for-update-dto.interface';
import { Etiketa } from '@core/interfaces/models/etiketa.interface';
import { EtiketaParams } from '@core/interfaces/params/etiketa-params.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class EtiketaService extends Store<Etiketa, EtiketaParams, EtiketaForCreationDTO, EtiketaForUpdateDTO> {
  constructor(apiService: ApiService) {
    super(apiService, 'etiketa');
  }
}
