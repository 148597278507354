import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, UntypedFormArray, AbstractControl, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FjalaGabimForCreationDTO } from '@core/interfaces/dto/creation/fjala-gabim-for-creation-dto.interface';
import { FjalaGabimForUpdateDTO } from '@core/interfaces/dto/update/fjala-gabim-for-update-dto.interface';
import { FjalaGabim } from '@core/interfaces/models/fjala-gabim.interface';
import { ConfirmWordChangeWithoutSaveComponent } from '@shared/dialogs/confirm-word-change-without-save/confirm-word-change-without-save.component';
import { ConfirmWordChangeComponent } from '@shared/dialogs/confirm-word-change/confirm-word-change.component';
import { BehaviorSubject, combineLatest, delay, filter, map, Observable, of, Subject, switchMap, tap, throttleTime } from 'rxjs';
import { FjalaGabimService } from './fjala-gabim.service';

@Injectable({
  providedIn: 'root',
})
export class FjalaGabimEditoriService {
  public editoriForm = new FormGroup({
    id: new FormControl<number | null>(null),
    fjaloriId: new FormControl<number | null>(null, [Validators.required]),
    pershkrimi: new FormControl<string>('', [Validators.required]),
    shpjegimi: new FormControl<string>(''),
    bazaFjalaGabim: new UntypedFormArray([]),
    fjalaGabimFjalaPerzgjedhurMapping: new UntypedFormArray([]),
    fjalaGabimEtiketaMapping: new UntypedFormArray([]),
    burimiId: new FormControl<string | null>(null),
    burimi: new FormControl<string | null>(null),
  });

  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _onEditorReset$: Subject<void> = new Subject();

  private _saveHistory: boolean = true;

  private _initialHistoryValue: any;
  private _formHistoryUndo: Array<any> = new Array<any>();
  private _formHistoryRedo: Array<any> = new Array<any>();
  private _canUndo$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _canRedo$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private matDialog: MatDialog, private fjalaGabimService: FjalaGabimService) {
    this.editoriForm.valueChanges
      .pipe(
        filter(() => this._saveHistory),
        tap(() => {
          this._formHistoryRedo = new Array();
          this._canRedo$.next(false);
        }),
        throttleTime(1000)
      )
      .subscribe((value) => {
        if (this._formHistoryUndo.length == 0) {
          this._formHistoryUndo.splice(0, 0, this._initialHistoryValue);
        } else {
          this._formHistoryUndo.splice(0, 0, this.editoriForm.getRawValue());
        }

        this._canUndo$.next(true);
        this._canRedo$.next(false);

        if (this._formHistoryUndo.length > 50) this._formHistoryUndo.pop();
      });
  }
  
  setFormValue(fjalaGabim: FjalaGabim) {
    this._saveHistory = false;

    this.resetControlValue(this.editoriForm);
    this.setControlValue(fjalaGabim, this.editoriForm);
    this._initialHistoryValue = fjalaGabim;

    this._formHistoryUndo = new Array();
    this._formHistoryRedo = new Array();
    this._canUndo$.next(false);
    this._canRedo$.next(false);

    this._onEditorReset$.next();
    this.editoriForm.markAsPristine();
    this.editoriForm.markAsUntouched();

    this._saveHistory = true;
  }

  setControlValue(object: any, control?: AbstractControl) {
    if (control instanceof FormGroup) {
      for (const field in control.controls) {
        // control.get(field)!.reset(undefined, { emitEvent: false });
        if (object && object[field]) this.setControlValue(object[field], control.get(field)!);
        else this.resetControlValue(object[field]);
      }
    } else if (control instanceof FormArray) {
      (object as []).forEach((obj) => {
        const keys = Object.keys(obj);
        let fg: FormGroup = new FormGroup({});
        keys.forEach((key) => {
          fg.addControl(key, new FormControl({ value: obj[key], disabled: control.disabled }));
        });
        control.push(fg);
      });
    } else if (control instanceof FormControl) {
      control.setValue(object);
    }
  }

  resetForm(control?: AbstractControl) {
    this._saveHistory = false;

    this.resetControlValue(control ?? this.editoriForm);

    this.editoriForm.controls.fjaloriId.setValue(this.fjaloriId);
    this.editoriForm.markAsPristine();
    this.editoriForm.markAsUntouched();

    this._initialHistoryValue = this.editoriForm.getRawValue();

    this._onEditorReset$.next();
    this._formHistoryUndo = new Array();
    this._formHistoryRedo = new Array();
    this._canUndo$.next(false);
    this._canRedo$.next(false);

    this._saveHistory = true;
  }

  resetControlValue(control?: AbstractControl) {
    if (control instanceof FormGroup) {
      for (const field in control.controls) {
        this.resetControlValue(control.get(field)!);
      }
    } else if (control instanceof FormControl) {
      control.setValue(null); //reset();
    } else if (control instanceof FormArray) {
      control.clear();
    }
  }

  updateOrderCtrl(formArray: FormArray, orderFieldName: string) {
    for (let i = 0; i < formArray.length; i++) {
      formArray
        .at(i)
        .get(orderFieldName)
        ?.setValue(i + 1);
    }
  }

  fjaloriId: number | null = null;
  registerFjaloriId(id: number | null) {
    if (this.fjaloriId != id) {
      this.fjaloriId = id;
      this._saveHistory = false;
      this.editoriForm.controls.fjaloriId.setValue(this.fjaloriId);
      this._saveHistory = true;
    }
  }

  getCanChange(): Observable<boolean> {
    if (this.editoriForm.dirty) {
      if (this.editoriForm.valid) {
        const dialog = this.matDialog.open(ConfirmWordChangeComponent, {
          width: '300px',
          disableClose: true,
        });

        return dialog.afterClosed().pipe(
          switchMap((saveChanges) => {
            if (saveChanges === undefined || saveChanges === null) {
              return of(false);
            }
            if (saveChanges) {
              return this.save().pipe(
                tap(() => {
                  this.editoriForm.disable();
                  this.setSaving(true);
                }),
                delay(1000),
                map(() => {
                  this.editoriForm.enable();
                  this.setSaving(false);
                  return true;
                })
              );
            }
            return of(true);
          })
        );
      } else {
        const dialog = this.matDialog.open(ConfirmWordChangeWithoutSaveComponent, {
          width: '400px',
          disableClose: true,
        });

        return dialog.afterClosed().pipe(map((response) => response ?? false));
      }
    }
    return of(true);
  }

  save() {
    return this.editoriForm.value.id != null ? this.update() : this.insert();
  }

  insert() {
    return this.fjalaGabimService.insert(this.getInsertDTO());
  }

  update() {
    return this.fjalaGabimService.update(this.getUpdateDTO());
  }

  getLoading() {
    return this._loading$.asObservable();
  }

  setLoading(loading: boolean) {
    this._saveHistory = !loading;
    this._loading$.next(loading);
  }

  getSaving() {
    return this._saving$.asObservable();
  }

  setSaving(saving: boolean) {
    this._saving$.next(saving);
  }

  getOnEditorReset() {
    return this._onEditorReset$.asObservable();
  }

  undo() {
    if (this._formHistoryUndo.length > 0) {
      let last = this._formHistoryUndo.splice(0, 1)[0];

      const current = this.editoriForm.getRawValue();
      this._formHistoryRedo.splice(0, 0, current);

      this._saveHistory = false;
      this.resetControlValue(this.editoriForm);
      this.setControlValue(last, this.editoriForm);
      this._saveHistory = true;

      if (this._formHistoryUndo.length > 0) {
        this._canUndo$.next(true);
      } else {
        this._canUndo$.next(false);
      }
      this._canRedo$.next(true);
    }
  }

  canUndo() {
    return combineLatest([this._canUndo$, this._loading$, this._saving$]).pipe(map(([undo, loading, saving]) => undo && !loading && !saving));
  }

  redo() {
    if (this._formHistoryRedo.length > 0) {
      const last = this._formHistoryRedo.splice(0, 1)[0];
      const current = this.editoriForm.getRawValue();
      this._formHistoryUndo.splice(0, 0, current);

      this._saveHistory = false;
      this.resetControlValue(this.editoriForm);
      this.setControlValue(last, this.editoriForm);
      this._saveHistory = true;

      if (this._formHistoryRedo.length > 0) {
        this._canRedo$.next(true);
      } else {
        this._canRedo$.next(false);
      }
      this._canUndo$.next(true);
    }
  }

  canRedo() {
    return combineLatest([this._canRedo$, this._loading$, this._saving$]).pipe(map(([redo, loading, saving]) => redo && !loading && !saving));
  }

  private getInsertDTO() {
    const value = this.editoriForm.getRawValue();

    let dto: FjalaGabimForCreationDTO = {
      fjaloriId: value.fjaloriId!,
      pershkrimi: value.pershkrimi!,
      shpjegimi: value.shpjegimi ?? undefined,
      bazaFjalaGabimForCreationDTO: value.bazaFjalaGabim,
      fjalaGabimEtiketaMappingForCreationDTO: value.fjalaGabimEtiketaMapping,
      fjalaGabimFjalaPerzgjedhurMappingForCreationDTO: value.fjalaGabimFjalaPerzgjedhurMapping
      // others
    };

    return dto;
  }

  private getUpdateDTO() {
    const value = this.editoriForm.getRawValue();

    let dto: FjalaGabimForUpdateDTO = {
      id: value.id!,
      fjaloriId: value.fjaloriId!,
      pershkrimi: value.pershkrimi!,
      shpjegimi: value.shpjegimi ?? undefined,
      bazaFjalaGabimForCreationDTO: value.bazaFjalaGabim,
      fjalaGabimEtiketaMappingForCreationDTO: value.fjalaGabimEtiketaMapping,
      fjalaGabimFjalaPerzgjedhurMappingForCreationDTO: value.fjalaGabimFjalaPerzgjedhurMapping
    };

    return dto;
  }

}
