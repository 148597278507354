import { ApiService } from "@core/services/api.service";
import { BehaviorSubject, tap, Observable, switchMap, map, filter, skip, debounceTime } from "rxjs";

export abstract class StrukturaStore<T1> {
  protected _endpointUrl: string;

  protected _store$: BehaviorSubject<{ value: T1[], propagate: boolean } | null> = new BehaviorSubject<{ value: T1[], propagate: boolean } | null>(null);
  protected _dataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(protected apiService: ApiService, baseUrl: string) {
    this._endpointUrl = baseUrl.endsWith('/') ? baseUrl : baseUrl + '/';
  }

  load() {
    return this.getMy().pipe(
      tap((struktura) => {
        this._store$.next({ value: struktura, propagate: false });
        this._dataLoaded$.next(true);
      })
    );
  }

  updateStore(struktura: T1[], propagate: boolean = true) {
    this._store$.next({ value: struktura, propagate: propagate });
  }

  getMyFromStore(propagateOnly: boolean = false): Observable<T1[]> {
    return this._dataLoaded$.pipe(
      switchMap((loaded) => {
        if(loaded)
          return this._store$.asObservable()
        throw `Can't call get before loading store. (${this._endpointUrl})`;
      }),
      filter((store) => propagateOnly ? store!.propagate : true),
      map((store) => {
        return store!.value;
      })
    );
  }

  getMy() {
    return this.apiService.get<T1[]>(`${this._endpointUrl}my`);
  }

  abstract updateMy(items: T1[]): Observable<T1[]>;

  get dataLoaded$(): Observable<boolean> {
    return this._dataLoaded$.asObservable();
  }

  /**
   * Update changes from the store to the API
   * @param debounceTimer time to wait for next update in ms (defaults to 2000)
   * @param nrOfSkips number of skipped values in the store (defaults to 1)
   * @returns Observable of the model
   */
   updateOnChanges(debounceTimer: number = 2000, nrOfSkips: number = 1) {
    return this._dataLoaded$.pipe(
      filter(loaded => loaded),
      switchMap(() => this._store$.asObservable()),
      skip(nrOfSkips),
      filter((item) => item != null && item.value.length > 0 && item.propagate),
      map((item) => item!.value),
      debounceTime(debounceTimer),
      switchMap((items) => {
        return this.updateMy(items);
      }),
    )
  }

}
