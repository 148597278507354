import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileContentResponse } from './interfaces/file-content-response.interface';
import { FileHeader } from './interfaces/file-header.interface';

@Injectable({
  providedIn: 'root',
})
export class AsSentenceProcessorService {
  baseUrl: string = environment.sentenceApiUrl + 'api/';

  private _sentenceSelected$: Subject<string> = new Subject<string>();

  constructor(private http: HttpClient) {}

  sentenceSelected(sentence: string) {
    this._sentenceSelected$.next(sentence);
  }

  onSentenceSelected() {
    return this._sentenceSelected$.asObservable();
  }

  search(phrase: string, pageNumber: number, pageSize: number) {
    return this.get<FileContentResponse>(`File/Search/${encodeURIComponent(phrase)}/${pageNumber}/${pageSize}`);
  }

  searchNew(phrases: string[], pageNumber: number, pageSize: number, wholeWord: boolean) {
    return this.get<FileContentResponse>(`File/Search?phrases=${phrases.join(';')}&pageNumber=${pageNumber}&pageSize=${pageSize}&wholeWord=${wholeWord}`);
  }

  getFiles() {
    return this.get<FileHeader[]>(`File`);
  }

  upload(file: any) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.post(`File/Upload`, formData, { reportProgress: true, observe: 'events' });
  }

  deleteFile(fileId: number, fileName: string) {
    return this.delete(`File/${fileId}/${encodeURIComponent(fileName)}`);
  }

  private get<T>(url: string): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}${url}`);
  }

  private post<T>(url: string, body: any, options?: any): Observable<HttpEvent<T>> {
    return this.http.post<T>(`${this.baseUrl}${url}`, body, options);
  }

  private delete<T>(url: string) {
    return this.http.delete<T>(`${this.baseUrl}${url}`);
  }
}
