import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FjalaGabimNavbarService {

  constructor() {}

  //#region Burimi

  activeBurimiId$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  getBurimiId() {
    return this.activeBurimiId$.asObservable();
  }

  setBurimiId(burimiId: string | null) {
    this.activeBurimiId$.next(burimiId);
  }

  //#endregion

  //#region Editor clear

  private _onEditorClearClick$: Subject<void> = new Subject();

  triggerOnEditorClearClick() {
    this._onEditorClearClick$.next();
  }

  onEditorClearClick() {
    return this._onEditorClearClick$.asObservable();
  }

  //#endregion

  //#region Delete

  private _onDeleteClick$: Subject<void> = new Subject<void>();

  triggerDeleteClick() {
    this._onDeleteClick$.next();
  }

  onDeleteClick() {
    return this._onDeleteClick$.asObservable();
  }

  //#endregion

  //#region Previous

  private _prevFjalaGabim$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private _onPrevClick$: Subject<void> = new Subject<void>();

  getPrevFjalaGabim() {
    return this._prevFjalaGabim$.asObservable();
  }

  setPrevFjalaGabim(id: number | null) {
    this._prevFjalaGabim$.next(id);
  }

  triggerPrevClick() {
    this._onPrevClick$.next();
  }

  onPrevClick() {
    return this._onPrevClick$.asObservable();
  }
  //#endregion

  //#region Next

  private _nextFjalaGabim$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private _onNextClick$: Subject<void> = new Subject<void>();

  getNextFjalaGabim() {
    return this._nextFjalaGabim$.asObservable();
  }

  setNextFjalaGabim(id: number | null) {
    this._nextFjalaGabim$.next(id);
  }

  triggerNextClick() {
    this._onNextClick$.next();
  }

  onNextClick() {
    return this._onNextClick$.asObservable();
  }
  //#endregion

  //#region Enter
  
  private _onEnterClick$: Subject<void> = new Subject<void>();
  
  onEnterClick() {
    return this._onEnterClick$.asObservable();
  }

  triggerEnterClick() {
    this._onEnterClick$.next();
  }

  //#endregion

  //#region Save and previous

  private _onSaveAndPrevClick$: Subject<void> = new Subject<void>();
  
  onSaveAndPrevClick() {
    return this._onSaveAndPrevClick$.asObservable();
  }

  triggerSaveAndPrevClick() {
    this._onSaveAndPrevClick$.next();
  }

  //#endregion

  //#region Save

  private _onSaveClick$: Subject<void> = new Subject<void>();
  
  onSaveClick() {
    return this._onSaveClick$.asObservable();
  }

  triggerSaveClick() {
    this._onSaveClick$.next();
  }

  //#endregion

  //#region Save and next

  private _onSaveAndNextClick$: Subject<void> = new Subject<void>();
  
  onSaveAndNextClick() {
    return this._onSaveAndNextClick$.asObservable();
  }

  triggerSaveAndNextClick() {
    this._onSaveAndNextClick$.next();
  }

  //#endregion

  //#region Comments

  private _onCommentsClick$: Subject<void> = new Subject<void>();

  onCommentsClick() {
    return this._onCommentsClick$.asObservable();
  }

  triggerCommentsClick() {
    this._onCommentsClick$.next();
  }

  //#endregion

}