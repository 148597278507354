import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PermissionName } from '@core/enums/permissions/permission-name.enum';
import { PermissionType } from '@core/enums/permissions/permission-type.enum';
import { OperatoriFaqjaService } from '@core/services/operatori-faqja.service';
import { QasjaService } from '@core/services/qasja.service';
import { FjalaHuajEditoriService } from '@core/services/fjala-huaj/fjala-huaj-editori.service';
import { FjalaHuajNavbarService } from '@core/services/fjala-huaj/fjala-huaj-navbar.service';
import { Subject, takeUntil, debounceTime, switchMap, combineLatest, distinctUntilChanged, of, pairwise } from 'rxjs';
import { ShenimetPopupComponent } from '../shenimet-popup/shenimet-popup.component';

@Component({
  selector: 'app-fjala-huaj-nav-buttons',
  templateUrl: './fjala-huaj-nav-buttons.component.html',
  styleUrls: ['./fjala-huaj-nav-buttons.component.scss'],
})
export class FjalaHuajNavButtonsComponent implements OnInit, OnDestroy {
  formDirty: boolean = false;
  formValid: boolean = false;

  
  prevFjalaHuajId: number | null = null;
  fjalaHuajId: number | null = null;
  nextFjalaHuajId: number | null = null;
  
  jumpFjalaHuajId: number | null = null;
  
  canUndo: boolean = false;
  canRedo: boolean = false;

  saving: boolean = false;
  
  canDelete: boolean = false;

  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private fjalaHuajEditorService: FjalaHuajEditoriService,
    private fjalaHuajNavbarService: FjalaHuajNavbarService,
    private operatoriFaqjaService: OperatoriFaqjaService,
    private matDialog: MatDialog,
    private qasjaService: QasjaService
  ) {}

  ngOnInit(): void {
    this.fjalaHuajEditorService.editoriForm.valueChanges.pipe(debounceTime(0), takeUntil(this._onDestroy$)).subscribe(() => {
      this.formDirty = this.fjalaHuajEditorService.editoriForm.dirty;
      this.formValid = this.fjalaHuajEditorService.editoriForm.valid;
    });

    combineLatest([
      this.fjalaHuajEditorService.editoriForm.controls.id.valueChanges.pipe(distinctUntilChanged()),
      this.fjalaHuajEditorService.editoriForm.controls.fjaloriId.valueChanges.pipe(distinctUntilChanged()),
      this.fjalaHuajEditorService.editoriForm.controls.burimiId.valueChanges.pipe(distinctUntilChanged()),
      this.fjalaHuajEditorService.getSaving()
    ]).pipe(
      debounceTime(0),
      switchMap(([fjalaHuajId, fjaloriId, burimiId, saving]) => {
        if (fjalaHuajId != null && !saving) {
          return this.qasjaService.hasQasjet([
            { emri: PermissionName.FjalaHuajFjalori, tipi: PermissionType.Delete, vlera: fjaloriId?.toString() },
            { emri: PermissionName.FjalaHuajBurimi, tipi: PermissionType.View, vlera: burimiId?.toString() },
          ])
        }
        return of(false);
      }),
      takeUntil(this._onDestroy$)
    ).subscribe((canDelete) => {
      this.canDelete = canDelete;
    })

    this.operatoriFaqjaService
      .listenOnFieldChanges('activeFjalaHuajId')
      .pipe(pairwise(), takeUntil(this._onDestroy$))
      .subscribe(([prev, next]) => {
        if (prev != null && next != null) {
          this.jumpFjalaHuajId = prev;
        } else {
          this.jumpFjalaHuajId = null;
        }
      });

    this.fjalaHuajEditorService
      .canUndo()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((canUndo) => {
        this.canUndo = canUndo;
      });

    this.fjalaHuajEditorService
      .canRedo()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((canRedo) => {
        this.canRedo = canRedo;
      });

    this.fjalaHuajNavbarService
      .getNextFjalaHuaj()
      .pipe(debounceTime(0), takeUntil(this._onDestroy$))
      .subscribe((id) => {
        this.nextFjalaHuajId = id;
      });

    this.fjalaHuajNavbarService
      .getPrevFjalaHuaj()
      .pipe(debounceTime(0), takeUntil(this._onDestroy$))
      .subscribe((id) => {
        this.prevFjalaHuajId = id;
      });

    this.operatoriFaqjaService
      .listenOnFieldChanges('activeFjalaHuajId')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((fjalaHuajId) => {
        this.fjalaHuajId = fjalaHuajId;
      });

    this.fjalaHuajEditorService
      .getSaving()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((saving) => {
        this.saving = saving;
      });
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
      if(this.nextFjalaHuajId != null)
        this.onSaveAndNextClick();
      else
        this.onSaveClick();
      event.preventDefault();
    }
  }

  jump() {
    if (this.jumpFjalaHuajId && !this.saving) {
      if(this.formDirty) {
        this.fjalaHuajEditorService.getCanChange().subscribe((canChange) => {
          if(canChange) {
            this.operatoriFaqjaService.updateItems({ activeFjalaHuajId: this.jumpFjalaHuajId });
          }
        });
      } else {
        this.operatoriFaqjaService.updateItems({ activeFjalaHuajId: this.jumpFjalaHuajId });
      }
    }
  }

  undo() {
    if (this.canUndo) this.fjalaHuajEditorService.undo();
  }

  redo() {
    if (this.canRedo) this.fjalaHuajEditorService.redo();
  }

  onEditorClearClick() {
    if(!this.saving) this.fjalaHuajNavbarService.triggerOnEditorClearClick();
  }

  onDeleteClick() {
    if(this.canDelete && !this.saving) this.fjalaHuajNavbarService.triggerDeleteClick();
  }

  onPrevClick() {
    if (this.prevFjalaHuajId && !this.saving) this.fjalaHuajNavbarService.triggerPrevClick();
  }

  onSaveAndPrevClick() {
    if (this.formDirty && this.formValid && this.prevFjalaHuajId && !this.saving) this.fjalaHuajNavbarService.triggerSaveAndPrevClick();
  }

  onSaveClick() {
    if (this.formDirty && this.formValid && !this.saving) this.fjalaHuajNavbarService.triggerSaveClick();
  }

  onSaveAndNextClick() {
    if (this.formDirty && this.formValid && this.nextFjalaHuajId && !this.saving) this.fjalaHuajNavbarService.triggerSaveAndNextClick();
  }

  onNextClick() {
    if (this.nextFjalaHuajId && !this.saving) this.fjalaHuajNavbarService.triggerNextClick();
  }

  onCommentsClick() {
    if(this.fjalaHuajId) this.fjalaHuajNavbarService.triggerCommentsClick();
  }

  openShenimet() {
    this.matDialog.open(ShenimetPopupComponent, {
      disableClose: true,
      panelClass: 'big-resizable-dialog',
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
