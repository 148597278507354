import { Injectable } from '@angular/core';
import { BaseApi } from '@core/classes/base-api.class';
import { ShembulliForCreationDTO } from '@core/interfaces/dto/creation/shembulli-for-creation-dto.interface';
import { ShembulliForUpdateDTO } from '@core/interfaces/dto/update/shembulli-for-update-dto.interface';
import { Shembulli } from '@core/interfaces/models/shembulli.interface';
import { ShembulliParams } from '@core/interfaces/params/shembulli-params.interface';
import { BehaviorSubject, filter, map } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class ShembulliService extends BaseApi<Shembulli, ShembulliParams, ShembulliForCreationDTO, ShembulliForUpdateDTO> {

  private _shembulliList$: BehaviorSubject<{ list: Shembulli[], propagate: boolean } | undefined> = new BehaviorSubject<{ list: Shembulli[], propagate: boolean } | undefined>(undefined);
  private _loadedFjaloriId$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  constructor(apiService: ApiService) {
    super(apiService, 'shembulli');
  }

  getShembulliList(propagateOnly: boolean = true) {
    return this._shembulliList$.asObservable().pipe(
      filter((value) => (propagateOnly ? value?.propagate! : true)),
      map((value) => {
        return value ? value.list : undefined;
      })
    );
  }

  resetShembulliList() {
    this._shembulliList$.next(undefined);
  }

  setShembulliList(list: Shembulli[], propagate: boolean = true) {
    this._shembulliList$.next({ list: list, propagate: propagate });
  }

  resetLoadedFjaloriId() {
    this._loadedFjaloriId$.next(undefined);
  }

  getLoadedFjaloriId() {
    return this._loadedFjaloriId$.asObservable();
  }

  setLoadedFjaloriId(fjaloriId: number) {
    this._loadedFjaloriId$.next(fjaloriId);
  }

}
