import { Pipe, PipeTransform } from '@angular/core';
import { FrazatService } from '@core/services/frazat.service';

@Pipe({
  name: 'phrase'
})
export class PhrasePipe implements PipeTransform {
  constructor(private frazatService: FrazatService) {}

  transform(value: string, ...args: unknown[]): string {
    return this.frazatService.fn(value) || value;
  }
}
