import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@shared/material/material.module';
import { PhraseModule } from '../phrase/phrase.module';
import { AsSearchAndReplaceBox } from './components/as-search-and-replace-box/as-search-and-replace-box.component';
import { AsSearchAndReplaceHighightTextarea } from './components/as-search-and-replace-highight-textarea/as-search-and-replace-highight-textarea.component';
import { AsSearchAndReplaceHightlightPipe } from './pipes/as-search-and-replace-hightlight.pipe';
import { AsSearchAndReplaceService } from './services/as-search-and-replace.service';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AsSearchAndReplaceBox,
    AsSearchAndReplaceHighightTextarea,
    AsSearchAndReplaceHightlightPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PhraseModule
  ],
  exports: [
    AsSearchAndReplaceBox,
    AsSearchAndReplaceHighightTextarea,
    AsSearchAndReplaceHightlightPipe
  ],
  providers: [
    AsSearchAndReplaceService,
    AsSearchAndReplaceHightlightPipe
  ]
})
export class AsSearchAndReplaceModule { }
