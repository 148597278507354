import { FilterType } from '@core/enums/filter-type.enum';
import { ListingType } from '@core/enums/listing-type.enum';
import { OrderBy } from '@core/enums/order-by.enum';
import { OrderType } from '@core/enums/order-type.enum';
import { WordElement } from '@core/enums/word-element.enum';
import { OperatoriFaqja } from '@core/interfaces/models/operatori-faqja.interface';
import { Struktura } from '@core/interfaces/models/struktura.interface';
import { SplitConfig } from '@core/interfaces/split-config.interface';

export class Defaults {
  public static Struktura: Struktura[] = [
    {
      id: 0,
      pershkrimi: WordElement.Description,
      radhitja: 1,
      showHide: true,
    },
    {
      id: 0,
      pershkrimi: WordElement.Materials,
      radhitja: 2,
      showHide: true,
    },
    {
      id: 0,
      pershkrimi: WordElement.Examples,
      radhitja: 3,
      showHide: true,
    },
    {
      id: 0,
      pershkrimi: WordElement.Synonyms,
      radhitja: 4,
      showHide: true,
    },
    {
      id: 0,
      pershkrimi: WordElement.Antonyms,
      radhitja: 5,
      showHide: true,
    },
    {
      id: 0,
      pershkrimi: WordElement.PartOfSpeech,
      radhitja: 6,
      showHide: true,
    },
    {
      id: 0,
      pershkrimi: WordElement.FieldOfUsage,
      radhitja: 7,
      showHide: true,
    },
    {
      id: 0,
      pershkrimi: WordElement.Proverbs,
      radhitja: 8,
      showHide: true,
    },
    {
      id: 0,
      pershkrimi: WordElement.Phrases,
      radhitja: 9,
      showHide: true,
    },
    {
      id: 0,
      pershkrimi: WordElement.Mistakes,
      radhitja: 10,
      showHide: true,
    },
    {
      id: 0,
      pershkrimi: WordElement.MixUps,
      radhitja: 11,
      showHide: true,
    },
    {
      id: 0,
      pershkrimi: WordElement.ForeignWords,
      radhitja: 12,
      showHide: true,
    },
    {
      id: 0,
      pershkrimi: WordElement.Etymology,
      radhitja: 13,
      showHide: true,
    },
  ];

  public static SplitConfig: SplitConfig = {
    columns: [
      {
        size: 20,
        rows: [
          {
            size: 50,
            order: 1,
            visible: true,
          },
          {
            size: 50,
            order: 2,
            visible: true,
          },
        ],
      },
      {
        size: 60,
        rows: [
          {
            size: 50,
            order: 1,
            visible: true,
          },
          {
            size: 50,
            order: 2,
            visible: true,
          },
        ],
      },
      {
        size: 20,
        rows: [
          {
            size: 30,
            order: 1,
            visible: true,
          },
          {
            size: 20,
            order: 2,
            visible: true,
          },
          {
            size: 30,
            order: 3,
            visible: true,
          },
          {
            size: 10,
            order: 4,
            visible: true,
          },
          {
            size: 10,
            order: 5,
            visible: true,
          },
        ],
      },
      {
        size: 20,
        rows: [
          {
            size: 50,
            order: 1,
            visible: true,
          },
          {
            size: 50,
            order: 2,
            visible: true,
          },
        ],
      },
      { // ShembulliRightNav
        size: 20,
        rows: [
          {
            size: 60,
            order: 1,
            visible: true,
          },
          {
            size: 40,
            order: 2,
            visible: true,
          },
        ],
      },
    ],
  };
  
  public static OperatoriFaqja: OperatoriFaqja = {
    id: -1,
    nrOfVisibleDictionaries: 1,
    mainDictionaryViewMode: false,
    secondDictionaryViewMode: false,
    thirdDictionaryViewMode: false,
    termiKerkimit: '',
    termiFilterit: FilterType.AllWords,
    tipiListimit: ListingType.Expanded,
    renditjaSipas: OrderBy.Headword,
    tipiRenditjes: OrderType.Ascending,
    splitConfig: JSON.stringify(Defaults.SplitConfig),
    shenimet: '',
    darkMode: false,
  };
}