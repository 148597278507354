import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { HasRolesDirective } from './directives/has-roles.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NyjaPipe } from './pipes/nyja.pipe';
import { PartOfSpeechPipe } from './pipes/part-of-speech.pipe';
import { RomanNumberPipe } from './pipes/roman-number.pipe';
import { AsSnackBarModule } from './modules/as-snack-bar/as-snack-bar.module';
import { ConfirmDeleteDialogComponent } from './dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { PermissionPipe } from './pipes/permission.pipe';
import { AsQueryBuilderComponent } from './components/as-query-builder/as-query-builder.component';
import { NgxAngularQueryBuilderModule } from 'ngx-angular-query-builder';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CustomToolTipComponent } from './components/custom-tool-tip/custom-tool-tip.component';
import { ToolTipRendererDirective } from './directives/tool-tip-renderer.directive';
import { ASCkEditorComponent } from './components/as-ck-editor/as-ck-editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AsSearchAndReplaceModule } from './modules/as-search-and-replace/as-search-and-replace.module';
import { AsCardComponent } from './components/as-card/as-card.component';
import { ConfirmWordChangeComponent } from './dialogs/confirm-word-change/confirm-word-change.component';
import { ConfirmWordChangeWithoutSaveComponent } from './dialogs/confirm-word-change-without-save/confirm-word-change-without-save.component';
import { ConfirmPageChangeComponent } from './dialogs/confirm-page-change/confirm-page-change.component';
import { ResizableDragDropComponent } from './components/resizable-drag-drop/resizable-drag-drop.component';
import { AddEditFjaloriDialogComponent } from './dialogs/add-edit-fjalori-dialog/add-edit-fjalori-dialog.component';
import { AsSortPipe } from './pipes/as-sort.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { AsBottomSheetComponent } from './components/as-bottom-sheet/as-bottom-sheet.component';
import { AsSentenceProcessorModule } from './modules/as-sentence-processor/as-sentence-processor.module';
import { PhraseModule } from './modules/phrase/phrase.module';
import { SendNjoftimiDialogComponent } from './dialogs/send-njoftimi-dialog/send-njoftimi-dialog.component';
import { ReplaceFEShTagsPipe } from './pipes/replace-fesh-tags.pipe';
import { UserAgentPipe } from './pipes/user-agent.pipe';
import { IsOnlinePipe } from './pipes/is-online.pipe';

@NgModule({
  declarations: [
    HasRolesDirective,
    HasPermissionDirective,
    NyjaPipe,
    PartOfSpeechPipe,
    RomanNumberPipe,
    ConfirmDeleteDialogComponent,
    PermissionPipe,
    AsQueryBuilderComponent,
    CustomToolTipComponent,
    ToolTipRendererDirective,
    ASCkEditorComponent,
    AsCardComponent,
    ConfirmWordChangeComponent,
    ConfirmWordChangeWithoutSaveComponent,
    ConfirmPageChangeComponent,
    ResizableDragDropComponent,
    AsSortPipe,
    AddEditFjaloriDialogComponent,
    SafePipe,
    AsBottomSheetComponent,
    SendNjoftimiDialogComponent,
    ReplaceFEShTagsPipe,
    UserAgentPipe,
    IsOnlinePipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AsSnackBarModule,
    NgxAngularQueryBuilderModule,
    NgxMatSelectSearchModule,
    PhraseModule,
    CKEditorModule,
    AsSearchAndReplaceModule,
    AsSentenceProcessorModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PhraseModule,
    PermissionPipe,
    HasRolesDirective,
    HasPermissionDirective,
    NyjaPipe,
    PartOfSpeechPipe,
    RomanNumberPipe,
    AsSnackBarModule,
    AsQueryBuilderComponent,
    CustomToolTipComponent,
    ToolTipRendererDirective,
    ASCkEditorComponent,
    AsSearchAndReplaceModule,
    AsCardComponent,
    SafePipe,
    AsBottomSheetComponent,
    AsSentenceProcessorModule,
    ReplaceFEShTagsPipe,
    UserAgentPipe,
    IsOnlinePipe
  ],
  providers: [],
})
export class SharedModule {}
