import { Component, Inject, PLATFORM_ID, forwardRef, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'as-ckeditor',
  templateUrl: './as-ck-editor.component.html',
  styleUrls: ['./as-ck-editor.component.scss'],
})
export class ASCkEditorComponent {
  oldConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'alignment',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'link',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        '|',
        'undo',
        'redo',
        '|',
        'code',
      ],
    },
    fontColor: {
      colors: [
        {
          color: 'hsl(0, 0%, 0%)',
          label: 'Black',
        },
        {
          color: 'hsl(0, 0%, 30%)',
          label: 'Dim grey',
        },
        {
          color: 'hsl(0, 0%, 60%)',
          label: 'Grey',
        },
        {
          color: 'hsl(0, 0%, 90%)',
          label: 'Light grey',
        },
        {
          color: 'hsl(0, 0%, 100%)',
          label: 'White',
          hasBorder: true,
        },
        {
          color: 'hsl(0, 75%, 60%)',
          label: 'Red',
        },
        {
          color: 'hsl(30, 75%, 60%)',
          label: 'Orange',
        },
        {
          color: 'hsl(60, 75%, 60%)',
          label: 'Yellow',
        },
        {
          color: 'hsl(90, 75%, 60%)',
          label: 'Light green',
        },
        {
          color: 'hsl(120, 75%, 60%)',
          label: 'Green',
        },
        {
          color: 'hsl(150, 75%, 60%)',
          label: 'Aquamarine',
        },
        {
          color: 'hsl(180, 75%, 60%)',
          label: 'Turquoise',
        },
        {
          color: 'hsl(210, 75%, 60%)',
          label: 'Light blue',
        },
        {
          color: 'hsl(240, 75%, 60%)',
          label: 'Blue',
        },
        {
          color: 'hsl(270, 75%, 60%)',
          label: 'Purple',
        },
        {
          color: 'hsl(210, 53%, 49%)',
          label: 'GJSH Primare',
        },
        {
          color: 'hsl(204, 47%, 22%)',
          label: 'GJSH Sekondare',
        },
      ],
    },
    language: 'en',
    image: {
      toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    },
    licenseKey: '',
  };

  config = {
    toolbar: {
      items: [
        'heading',
        '|',
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        'removeFormat',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'bulletedList',
        'numberedList',
        '|',
        'alignment',
        'indent',
        'outdent',
        '|',
        'link',
        'imageInsert',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
      ],
    },
    language: 'en',
    image: {
      toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    },
    licenseKey: '',
  };

  ckEditor;

  isBrowser = false;

  isDisabled = false;

  @Input() value?: string;
  @Output() onValueChanges: EventEmitter<string> = new EventEmitter<string>();

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      const ClassicEditor = require('src/assets/js/ckeditor/build/ckeditor');
      this.ckEditor = ClassicEditor;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onEditorChange({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.onValueChanges.emit(data);
  }
}
