<div class="welcome-screen">
  <div class="logo">
    <mat-icon class="mat-primary" svgIcon="as_logo"></mat-icon>
  </div>
  <h1>{{ 'welcome_message' | phrase }}</h1>
  <h3>{{ 'unauthorized' | phrase }}</h3>
  <div class="logout">
    <button mat-button (click)="openGjuhaShqipe()">{{ 'Gjuha shqipe' | phrase }}</button>
    <button mat-button (click)="logout()">{{ 'settings_menu_logoff' | phrase }}</button>
  </div>
</div>
