export enum PjesaLigjerates {
  Emer = 10,
  Folje = 11,
  Mbiemer = 12,
  Peremer = 13,
  Ndajfolje = 14,
  Numeror = 15,
  Parafjale = 16,
  Lidhez = 17,
  Pjesez = 18,
  Pasthirrme = 19,
  Epapercaktuar = 20,
}
