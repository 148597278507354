import { Injectable } from '@angular/core';
import { Operatori } from '@core/interfaces/models/operatori.interface';
import { BehaviorSubject, tap, Observable, switchMap, map } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OperatoriService  {
  
  private _endpointUrl: string = `operatori`;

  private _store$: BehaviorSubject<Operatori[]> = new BehaviorSubject<Operatori[]>([]);
  private _dataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService) {

  }

  load() {
    return this.apiService.get<Operatori[]>(this._endpointUrl).pipe(
      tap((items) => {
        this._store$.next(items);
        this._dataLoaded$.next(true);
      })
    );
  }

  getFromStore(): Observable<Operatori[]> {
    return this._dataLoaded$.pipe(
      switchMap((loaded) => {
        if(loaded)
          return this._store$.asObservable()
        throw `Can't call get before loading store. (${this._endpointUrl})`;
      })
    );
  }

  getByIdFromStore(id: string): Observable<Operatori | undefined> {
    return this._store$.pipe(
      map((data) => {
        return data.find(d => d.id === id)
      })
    );
  }
}
