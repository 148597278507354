import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { FushaPerdorimitForCreationDTO } from '@core/interfaces/dto/creation/fusha-perdorimit-for-creation-dto.interface';
import { FushaPerdorimitForUpdateDTO } from '@core/interfaces/dto/update/fusha-perdorimit-for-update-dto.interface';
import { FushaPerdorimit } from '@core/interfaces/models/fusha-perdorimit.interface';
import { FushaPerdorimitParams } from '@core/interfaces/params/fusha-perdorimit-params.interface';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FushaPerdorimitService extends Store<FushaPerdorimit, FushaPerdorimitParams, FushaPerdorimitForCreationDTO, FushaPerdorimitForUpdateDTO> {
  private _restructured$ = new BehaviorSubject<{ rangimi: string, rangimiId: number, fushaPerdorimit: FushaPerdorimit[] }[]>([]);

  constructor(apiService: ApiService) {
    super(apiService, 'fushaPerdorimit');
  }

  override load(params?: FushaPerdorimitParams): Observable<FushaPerdorimit[]> {
    return super.load(params).pipe(
      tap((fushat) => {
        let rangimet = fushat
          .map((x) => {
            return { rangimiId: x.rangimiId, rangimi: x.rangimi };
          })
          .filter((v, i, s) => s.findIndex((x) => x.rangimiId === v.rangimiId) === i);
        let restructured: any[] = [];
        
        rangimet.forEach((r) => {
          restructured.push({
            rangimi: r.rangimi,
            rangimiId: r.rangimiId,
            fushaPerdorimit: fushat.filter((x) => x.rangimiId === r.rangimiId).sort((a, b) => a.id - b.id),
          });
        });
        this._restructured$.next(restructured.sort((a, b) => a.rangimiId - b.rangimiId));
      })
    );
  }

  getRestructured() {
    return this._restructured$.asObservable();
  }
}
