import { Injectable } from '@angular/core';
import { BaseApi } from '@core/classes/base-api.class';
import { BazaForCreationDTO } from '@core/interfaces/dto/creation/baza-for-creation-dto.interface';
import { EditoriForCreationDTO } from '@core/interfaces/dto/creation/editori-for-creation-dto.interface';
import { EditoriForUpdateInsertDTO } from '@core/interfaces/dto/editori-for-update-insert-dto.interface';
import { BazaForUpdateDTO } from '@core/interfaces/dto/update/baza-for-update-dto.interface';
import { BazaHomonimiForUpdateDTO } from '@core/interfaces/dto/update/baza-homonimi-for-update-dto.interface';
import { Baza } from '@core/interfaces/models/baza.interface';
import { Editori } from '@core/interfaces/models/editori.interface';
import { BazaParams } from '@core/interfaces/params/baza-params.interface';
import { EditoriParams } from '@core/interfaces/params/editori-params.interface';
import { FjalaSipasFjaloreveParams } from '@core/interfaces/params/fjala-sipas-fjaloreve-params.interface';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class BazaService extends BaseApi<Baza, BazaParams, BazaForCreationDTO, BazaForUpdateDTO> {
  constructor(apiService: ApiService) {
    super(apiService, 'baza/');
  }

  getEditori(params: EditoriParams): Observable<Editori> {
    return this.apiService.get<Editori>(`${this._endpointUrl}editori`, params)
  }
  
  ruajEditorin(dto: EditoriForUpdateInsertDTO){
    return this.apiService.post<Editori>(`${this._endpointUrl}editori`, dto);
  }

  // saveEditor(dto: EditoriForCreationDTO) {
  //   return this.apiService.post<Editori>(`${this._endpointUrl}editori`, dto);
  // }

  // updateEditor(dto: EditoriForCreationDTO) {
  //   return this.apiService.put<Editori>(`${this._endpointUrl}editori`, dto);
  // }

  fjaletSipasFjalorit(fjala: string, fjaloriId: number) {
    return this.apiService.get<Baza[]>(`${this._endpointUrl}fjaletSipasFjalorit`, { fjala: fjala, fjaloriId: fjaloriId })
  }
  
  fjalaSipasFjaloreve(params: FjalaSipasFjaloreveParams) {
    return this.apiService.post<Record<number, number>>(`${this._endpointUrl}fjalaSipasFjaloreve`, params);
  }

  deleteMultiple(ids: number[]) {
    return this.apiService.post<boolean>(`${this._endpointUrl}delete`, ids);
  }

  updateHomonimi(dto: BazaHomonimiForUpdateDTO) {
    return this.apiService.put<boolean>(`${this._endpointUrl}homonimi`, dto);
  }

}
