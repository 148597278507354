import { Injectable } from '@angular/core';
import { filter, map, Observable, skip, Subject } from 'rxjs';
import { FjaloriStrukturaService } from './fjalori-struktura.service';
import { OperatoriFaqjaService } from './operatori-faqja.service';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  broadcastChannel?: BroadcastChannel;
  onMessage = new Subject<any>();

  constructor(private operatoriFaqjaService: OperatoriFaqjaService, private fjaloriStrukturaService: FjaloriStrukturaService) {}

  initialize() {
    const name = 'leksikografia';
    this.broadcastChannel = new BroadcastChannel(name);
    this.broadcastChannel!.onmessage = (message: MessageEvent) => this.onMessage.next(message.data);

    this.operatoriFaqjaService.getFromStore(true).subscribe((op) => {
      this.publish({ type: 'operatoriFaqja', payload: op });
    });
    this.messageOfType('operatoriFaqja').subscribe((op) => {
      this.operatoriFaqjaService.updateItems({ splitConfig: op.splitConfig }, false);
    });

    this.fjaloriStrukturaService.getMyFromStore(true).subscribe((struktura) => {
      this.publish({ type: 'fjaloriStruktura', payload: struktura });
    });
    this.messageOfType('fjaloriStruktura').subscribe((struktura) => {
      this.fjaloriStrukturaService.updateStore(struktura, false);
    });
  }

  publish(message: any): void {
    this.broadcastChannel?.postMessage(message);
  }

  messageOfType(type: string): Observable<any> {
    return this.onMessage.pipe(
      filter((message) => message.type === type),
      map((message) => message.payload)
    );
  }
}
