import { Injectable } from '@angular/core';
import { QueryBuilderConfigs } from '@core/constants/query-builder-configs';
import { FjalaGabim } from '@core/interfaces/models/fjala-gabim.interface';
import { FjalaHuaj } from '@core/interfaces/models/fjala-huaj.interface';
import { Frazeologjia } from '@core/interfaces/models/frazeologjia.interface';
import { KerkimiGrupuarElementet } from '@core/interfaces/models/kerkimi-grupuar-elementet.interface';
import { Kerkimi } from '@core/interfaces/models/kerkimi.interface';
import { Proverba } from '@core/interfaces/models/proverba.interface';
import { Shembulli } from '@core/interfaces/models/shembulli.interface';
import { QueryBuilderConfig } from 'ngx-angular-query-builder';
import { BehaviorSubject, map, take, combineLatest } from 'rxjs';
import { ApiService } from './api.service';
import { BurimiService } from './burimi.service';
import { EtiketaService } from './etiketa.service';
import { FjalaGabimEtiketaService } from './fjala-gabim/fjala-gabim-etiketa.service';
import { FjalaGabimFjalaPerzgjedhurService } from './fjala-gabim/fjala-gabim-fjala-perzgjedhur.service';
import { FjalaHuajEtiketaService } from './fjala-huaj/fjala-huaj-etiketa.service';
import { FjalaHuajFjalaPerzgjedhurService } from './fjala-huaj/fjala-huaj-fjala-perzgjedhur.service';
import { FjalaPerzgjedhurService } from './fjala-perzgjedhur.service';
import { FrazeologjiaEtiketaService } from './frazeologjia/frazeologjia-etiketa.service';
import { FrazeologjiaFjalaPerzgjedhurService } from './frazeologjia/frazeologjia-fjala-perzgjedhur.service';
import { FushaPerdorimitService } from './fusha-perdorimit.service';
import { ProverbaEtiketaService } from './proverba/proverba-etiketa.service';
import { ProverbaFjalaPerzgjedhurService } from './proverba/proverba-fjala-perzgjedhur.service';
import { ShembulliEtiketaService } from './shembulli/shembulli-etiketa.service';
import { ShembulliFjalaPerzgjedhurService } from './shembulli/shembulli-fjala-perzgjedhur.service';

@Injectable({
  providedIn: 'root',
})
export class QueryBuilderService {
  private baseUrl: string = 'queryBuilder';

  private _temporaryQueryBuilder$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _advancedSearchAndReplaceQuery$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private _wordConfig: QueryBuilderConfig = QueryBuilderConfigs.Word;
  private _exampleConfig: QueryBuilderConfig = QueryBuilderConfigs.Example;
  private _proverbConfig: QueryBuilderConfig = QueryBuilderConfigs.Proverb;
  private _frazeologjiaConfig: QueryBuilderConfig = QueryBuilderConfigs.Frazeologjia;
  private _fjalaHuajConfig: QueryBuilderConfig = QueryBuilderConfigs.FjalaHuaj;
  private _fjalaGabimConfig: QueryBuilderConfig = QueryBuilderConfigs.FjalaGabim;

  private fields: string[] = ['description', 'material', 'example', 'proverb', 'phrase', 'mistake', 'mixUp', 'foreignWord', 'etymology'];

  constructor(
    private apiService: ApiService,
    private fjalaPerzgjedhurService: FjalaPerzgjedhurService,
    private etiketaService: EtiketaService,
    private fushaPerdorimitService: FushaPerdorimitService,
    private burimiService: BurimiService,
    private shembulliFjalaPerzgjedhurService: ShembulliFjalaPerzgjedhurService,
    private shembulliEtiketaService: ShembulliEtiketaService,
    private proverbaFjalaPerzgjedhurService: ProverbaFjalaPerzgjedhurService,
    private proverbaEtiketaService: ProverbaEtiketaService,
    private frazeologjiaFjalaPerzgjedhurService: FrazeologjiaFjalaPerzgjedhurService,
    private frazeologjiaEtiketaService: FrazeologjiaEtiketaService,
    private fjalaHuajFjalaPerzgjedhurService: FjalaHuajFjalaPerzgjedhurService,
    private fjalaHuajEtiketaService: FjalaHuajEtiketaService,
    private fjalaGabimFjalaPerzgjedhurService: FjalaGabimFjalaPerzgjedhurService,
    private fjalaGabimEtiketaService: FjalaGabimEtiketaService
  ) {}

  get(fjaloriId: number, queryBuilder: any) {
    return this.apiService.post<Kerkimi[]>(`${this.baseUrl}/${fjaloriId}`, queryBuilder);
  }

  getCollapsed(fjaloriId: number, queryBuilder: any) {
    return this.apiService.post<KerkimiGrupuarElementet[]>(`${this.baseUrl}/duplicates/${fjaloriId}`, queryBuilder);
  }

  getTemporaryQueryBuilder() {
    return this._temporaryQueryBuilder$.asObservable();
  }

  setTemporaryQueryBuilder(queryBuilder: any) {
    this._temporaryQueryBuilder$.next(queryBuilder);
  }

  getAdvancedSearchAndReplaceQuery() {
    return this._advancedSearchAndReplaceQuery$.asObservable();
  }

  setAdvancedSearchAndReplaceQuery(phrase: string | null) {
    if (phrase == null) {
      this._advancedSearchAndReplaceQuery$.next(null);
      return;
    }

    const qbObj = {
      condition: 'or',
      rules: this.fields.map((f) => {
        return {
          field: f,
          operator: 'contains',
          value: {
            value: phrase,
          },
        };
      }),
    };

    this._advancedSearchAndReplaceQuery$.next(qbObj);
  }

  getShembujt(fjaloriId: number, queryBuilder: any) {
    return this.apiService.post<Shembulli[]>(`${this.baseUrl}/shembujt/${fjaloriId}`, queryBuilder);
  }

  getProverba(fjaloriId: number, queryBuilder: any) {
    return this.apiService.post<Proverba[]>(`${this.baseUrl}/proverba/${fjaloriId}`, queryBuilder);
  }

  getFrazeologjite(fjaloriId: number, queryBuilder: any) {
    return this.apiService.post<Frazeologjia[]>(`${this.baseUrl}/frazeologjia/${fjaloriId}`, queryBuilder);
  }

  getFjalaHuaj(fjaloriId: number, queryBuilder: any) {
    return this.apiService.post<FjalaHuaj[]>(`${this.baseUrl}/fjalaHuaj/${fjaloriId}`, queryBuilder);
  }

  getFjalaGabim(fjaloriId: number, queryBuilder: any) {
    return this.apiService.post<FjalaGabim[]>(`${this.baseUrl}/fjalaGabim/${fjaloriId}`, queryBuilder);
  }

  getConfig(tableType: 'word' | 'example' | 'proverb' | 'frazeologjia' | 'fjalaHuaj' | 'fjalaGabim' = 'word') {
    switch (tableType) {
      case 'example':
        return this._exampleConfig;
      case 'proverb':
        return this._proverbConfig;
      case 'frazeologjia':
        return this._frazeologjiaConfig;
      case 'fjalaHuaj':
        return this._fjalaHuajConfig;
      case 'fjalaGabim':
        return this._fjalaGabimConfig;
      case 'word':
        return this._wordConfig;
    }
  }

  load() {
    return combineLatest([
      this.fjalaPerzgjedhurService.getFromStore(),
      this.etiketaService.getFromStore(),
      this.fushaPerdorimitService.getFromStore(),
      this.burimiService.getFromStore(),
      this.shembulliFjalaPerzgjedhurService.getFromStore(),
      this.shembulliEtiketaService.getFromStore(),
      this.proverbaFjalaPerzgjedhurService.getFromStore(),
      this.proverbaEtiketaService.getFromStore(),
      this.frazeologjiaFjalaPerzgjedhurService.getFromStore(),
      this.frazeologjiaEtiketaService.getFromStore(),
      this.fjalaHuajFjalaPerzgjedhurService.getFromStore(),
      this.fjalaHuajEtiketaService.getFromStore(),
      this.fjalaGabimFjalaPerzgjedhurService.getFromStore(),
      this.fjalaGabimEtiketaService.getFromStore(),
    ]).pipe(
      take(1),
      map(
        ([
          fjalaPerzgjedhur,
          etiketat,
          fushaPerdorimit,
          burimet,
          shembulliFjalaPerzgjedhur,
          shembulliEtiketa,
          proverbaFjalaPerzgjedhur,
          proverbaEtiketa,
          frazeologjiaFjalaPerzgjedhur,
          frazeologjiaEtiketa,
          fjalaHuajFjalaPerzgjedhur,
          fjalaHuajEtiketa,
          fjalaGabimFjalaPerzgjedhur,
          fjalaGabimEtiketa,
        ]) => {
          this._wordConfig.fields['approval'].options = fjalaPerzgjedhur.map((item) => {
            return { name: item.emri, value: item.id };
          });
          this._wordConfig.fields['etiquette'].options = etiketat.map((item) => {
            return { name: item.emri, value: item.id };
          });
          this._wordConfig.fields['fieldOfUsage'].options = fushaPerdorimit.map((item) => {
            return { name: item.pershkrimi + ' (' + item.shkurtimi + ')', value: item.id };
          });
          this._wordConfig.fields['wordSource'].options = burimet.map((item) => {
            return { name: item.pershkrimi, value: item.id };
          });
          this._wordConfig.fields['comments'].options = burimet
            .filter((b) => b.operator)
            .map((item) => {
              return { name: item.pershkrimi, value: item.id };
            });

          this._exampleConfig.fields['exampleApproval'].options = shembulliFjalaPerzgjedhur.map((item) => {
            return { name: item.emri, value: item.id };
          });
          this._exampleConfig.fields['exampleEtiquette'].options = shembulliEtiketa.map((item) => {
            return { name: item.emri, value: item.id };
          });
          this._exampleConfig.fields['source'].options = burimet.map((item) => {
            return { name: item.pershkrimi, value: item.id };
          });
          this._exampleConfig.fields['comments'].options = burimet
            .filter((b) => b.operator)
            .map((item) => {
              return { name: item.pershkrimi, value: item.id };
            });

          this._proverbConfig.fields['proverbApproval'].options = proverbaFjalaPerzgjedhur.map((item) => {
            return { name: item.emri, value: item.id };
          });
          this._proverbConfig.fields['proverbEtiquette'].options = proverbaEtiketa.map((item) => {
            return { name: item.emri, value: item.id };
          });
          this._proverbConfig.fields['source'].options = burimet.map((item) => {
            return { name: item.pershkrimi, value: item.id };
          });
          this._proverbConfig.fields['comments'].options = burimet
            .filter((b) => b.operator)
            .map((item) => {
              return { name: item.pershkrimi, value: item.id };
            });

          this._frazeologjiaConfig.fields['frazeologjiaApproval'].options = frazeologjiaFjalaPerzgjedhur.map((item) => {
            return { name: item.emri, value: item.id };
          });
          this._frazeologjiaConfig.fields['frazeologjiaEtiquette'].options = frazeologjiaEtiketa.map((item) => {
            return { name: item.emri, value: item.id };
          });
          this._frazeologjiaConfig.fields['source'].options = burimet.map((item) => {
            return { name: item.pershkrimi, value: item.id };
          });
          this._frazeologjiaConfig.fields['comments'].options = burimet
            .filter((b) => b.operator)
            .map((item) => {
              return { name: item.pershkrimi, value: item.id };
            });

          this._fjalaHuajConfig.fields['fjalaHuajApproval'].options = fjalaHuajFjalaPerzgjedhur.map((item) => {
            return { name: item.emri, value: item.id };
          });
          this._fjalaHuajConfig.fields['fjalaHuajEtiquette'].options = fjalaHuajEtiketa.map((item) => {
            return { name: item.emri, value: item.id };
          });
          this._fjalaHuajConfig.fields['source'].options = burimet.map((item) => {
            return { name: item.pershkrimi, value: item.id };
          });
          this._fjalaHuajConfig.fields['comments'].options = burimet
            .filter((b) => b.operator)
            .map((item) => {
              return { name: item.pershkrimi, value: item.id };
            });

          this._fjalaGabimConfig.fields['fjalaGabimApproval'].options = fjalaGabimFjalaPerzgjedhur.map((item) => {
            return { name: item.emri, value: item.id };
          });
          this._fjalaGabimConfig.fields['fjalaGabimEtiquette'].options = fjalaGabimEtiketa.map((item) => {
            return { name: item.emri, value: item.id };
          });
          this._fjalaGabimConfig.fields['source'].options = burimet.map((item) => {
            return { name: item.pershkrimi, value: item.id };
          });
          this._fjalaGabimConfig.fields['comments'].options = burimet
            .filter((b) => b.operator)
            .map((item) => {
              return { name: item.pershkrimi, value: item.id };
            });

          return true;
        }
      )
    );
  }
}
