import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { FjalaGabimFjalaPerzgjedhurForCreationDTO } from '@core/interfaces/dto/creation/fjala-gabim-fjala-perzgjedhur-for-creation-dto.interface';
import { FjalaGabimFjalaPerzgjedhurForUpdateDTO } from '@core/interfaces/dto/update/fjala-gabim-fjala-perzgjedhur-for-update-dto.interface';
import { FjalaGabimFjalaPerzgjedhur } from '@core/interfaces/models/fjala-gabim-fjala-perzgjedhur.interface';
import { BaseParams } from '@core/interfaces/params/base-params.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class FjalaGabimFjalaPerzgjedhurService extends Store<
  FjalaGabimFjalaPerzgjedhur,
  BaseParams,
  FjalaGabimFjalaPerzgjedhurForCreationDTO,
  FjalaGabimFjalaPerzgjedhurForUpdateDTO
> {
  constructor(apiService: ApiService) {
    super(apiService, 'fjalaGabimFjalaPerzgjedhur/');
  }
}
