import { Injectable } from '@angular/core';
import { BaseApi } from '@core/classes/base-api.class';
import { FjalaHuajForCreationDTO } from '@core/interfaces/dto/creation/fjala-huaj-for-creation-dto.interface';
import { FjalaHuajForUpdateDTO } from '@core/interfaces/dto/update/fjala-huaj-for-update-dto.interface';
import { FjalaHuaj } from '@core/interfaces/models/fjala-huaj.interface';
import { FjalaHuajParams } from '@core/interfaces/params/fjala-huaj-params.interface';
import { BehaviorSubject, filter, map } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class FjalaHuajService extends BaseApi<FjalaHuaj, FjalaHuajParams, FjalaHuajForCreationDTO, FjalaHuajForUpdateDTO> {

  private _fjalaHuajList$: BehaviorSubject<{ list: FjalaHuaj[], propagate: boolean } | undefined> = new BehaviorSubject<{ list: FjalaHuaj[], propagate: boolean } | undefined>(undefined);
  private _loadedFjaloriId$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  constructor(apiService: ApiService) {
    super(apiService, 'fjalaHuaj');
  }

  getFjalaHuajList(propagateOnly: boolean = true) {
    return this._fjalaHuajList$.asObservable().pipe(
      filter((value) => (propagateOnly ? value?.propagate! : true)),
      map((value) => {
        return value ? value.list : undefined;
      })
    );
  }

  resetFjalaHuajList() {
    this._fjalaHuajList$.next(undefined);
  }

  setFjalaHuajList(list: FjalaHuaj[], propagate: boolean = true) {
    this._fjalaHuajList$.next({ list: list, propagate: propagate });
  }

  resetLoadedFjaloriId() {
    this._loadedFjaloriId$.next(undefined);
  }

  getLoadedFjaloriId() {
    return this._loadedFjaloriId$.asObservable();
  }

  setLoadedFjaloriId(fjaloriId: number) {
    this._loadedFjaloriId$.next(fjaloriId);
  }

}
