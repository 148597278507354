<button mat-icon-button class="mat-primary" [disabled]="jumpBazaId == null || saving" (click)="jump()">
  <mat-icon svgIcon="as_jump" [matTooltip]="'navigation_jump' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!canUndo && !saving" (click)="undo()">
  <mat-icon svgIcon="as_back" [matTooltip]="'navigation_undo' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary mirrored" [disabled]="!canRedo && !saving" (click)="redo()">
  <mat-icon svgIcon="as_back" [matTooltip]="'navigation_redo' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="true" >
  <mat-icon svgIcon="as_a_plus_time" [matTooltip]="'add_words_quick' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="saving" (click)="onEditorClearClick()">
  <mat-icon svgIcon="as_a_plus" [matTooltip]="'navigation_new' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!canDelete" (click)="onDeleteClick()">
  <mat-icon svgIcon="as_delete" [matTooltip]="'navigation_delete' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="true">
  <mat-icon svgIcon="as_attachment" [matTooltip]="'navigation_attach' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" routerLink="struktura">
  <mat-icon svgIcon="as_structure" [matTooltip]="'navigation_word_structure' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!prevFjalaId || saving" (click)="onPrevClick()">
  <mat-icon svgIcon="as_cancel_and_back" [matTooltip]="'navigation_back' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!formDirty || !formValid || !prevFjalaId || saving" (click)="onSaveAndPrevClick()">
  <mat-icon svgIcon="as_save_and_back" [matTooltip]="'navigation_save_and_back' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!formDirty || !formValid || saving">
  <mat-icon svgIcon="as_save" [matTooltip]="'navigation_save' | phrase" matTooltipPosition="below" (click)="onSaveWordClick()"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!formDirty || !formValid || !nextFjalaId || saving" (click)="onSaveAndNextClick()">
  <mat-icon svgIcon="as_save_and_next" [matTooltip]="'navigation_save_and_next' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!nextFjalaId || saving" (click)="onNextClick()">
  <mat-icon svgIcon="as_cancel_and_next" [matTooltip]="'navigation_next' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" (click)="openShenimet()">
  <mat-icon svgIcon="as_notes" [matTooltip]="'navigation_notes' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!bazaKryesoreId">
  <mat-icon svgIcon="as_comments" [matTooltip]="'navigation_comments' | phrase" matTooltipPosition="below" (click)="onCommentsClick()"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" disabled>
  <mat-icon svgIcon="as_history" [matTooltip]="'navigation_history' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [matMenuTriggerFor]="nrOfEditors">
  <mat-icon svgIcon="as_dictionary"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" (click)="openSentenceAutosuggest()">
  <mat-icon class="material-icons-outlined corpus-icon" [matTooltip]="'lexicography_corpus' | phrase" matTooltipPosition="below">plagiarism</mat-icon>
</button>

<mat-menu #nrOfEditors="matMenu" class="drop-down-menu">
  <div class="button-group">
    <span class="title">{{ 'secondary_dictionaries_title' | phrase }}:</span>
    <mat-radio-group [value]="nrOfVisibleDictionaries">
      <button mat-menu-item (click)="setNrOfDictionaries(1)">
        <mat-radio-button [value]="1" (click)="$event.preventDefault()">{{ 'secondary_dictionaries_none' | phrase }}</mat-radio-button>
      </button>
      <button mat-menu-item (click)="setNrOfDictionaries(2)">
        <mat-radio-button [value]="2" (click)="$event.preventDefault()">{{ 'secondary_dictionaries_one' | phrase }}</mat-radio-button>
      </button>
      <button mat-menu-item (click)="setNrOfDictionaries(3)">
        <mat-radio-button [value]="3" (click)="$event.preventDefault()">{{ 'secondary_dictionaries_two' | phrase }}</mat-radio-button>
      </button>
    </mat-radio-group>
  </div>
</mat-menu>
