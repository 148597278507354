import { Injectable } from '@angular/core';
import { StrukturaStore } from '@core/classes/struktura-store.class';
import { FrazeologjiaFjalaPerzgjedhurStruktura } from '@core/interfaces/models/frazeologjia-fjala-perzgjedhur-struktura.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class FrazeologjiaFjalaPerzgjedhurStrukturaService extends StrukturaStore<FrazeologjiaFjalaPerzgjedhurStruktura> {

  constructor(apiService: ApiService) {
    super(apiService, 'frazeologjiaFjalaPerzgjedhurStruktura');
  }

  updateMy(items: FrazeologjiaFjalaPerzgjedhurStruktura[]) {
    return this.apiService.put<FrazeologjiaFjalaPerzgjedhurStruktura[]>(`${this._endpointUrl}my`, { frazeologjiaFjalaPerzgjedhurStrukturaForCreationDTO: items });
  }

}