import { Injectable } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { PerdoruesiStatusi } from '@core/enums/perdoruesi-statusi.enum';
import { BazaKomenti } from '@core/interfaces/models/baza-komenti.interface';
import { FjalaGabimKomenti } from '@core/interfaces/models/fjala-gabim-komenti.interface';
import { FjalaGabim } from '@core/interfaces/models/fjala-gabim.interface';
import { FjalaHuajKomenti } from '@core/interfaces/models/fjala-huaj-komenti.interface';
import { FjalaHuaj } from '@core/interfaces/models/fjala-huaj.interface';
import { FrazeologjiaKomenti } from '@core/interfaces/models/frazeologjia-komenti.interface';
import { Frazeologjia } from '@core/interfaces/models/frazeologjia.interface';
import { Kerkimi } from '@core/interfaces/models/kerkimi.interface';
import { Njoftimi } from '@core/interfaces/models/njoftimi.interface';
import { ProverbaKomenti } from '@core/interfaces/models/proverba-komenti.interface';
import { Proverba } from '@core/interfaces/models/proverba.interface';
import { ShembulliKomenti } from '@core/interfaces/models/shembulli-komenti.interface';
import { Shembulli } from '@core/interfaces/models/shembulli.interface';
import { PerdoruesiConnection } from '@core/interfaces/signalr/perdoruesi-connection.interface';
import { Perdoruesi } from '@core/interfaces/signalr/perdoruesi.interface';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { BehaviorSubject, combineLatest, map, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LeksikografiaHubService {
  private _connectionEstablished: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _perdoruesit$: BehaviorSubject<Perdoruesi[]> = new BehaviorSubject<Perdoruesi[]>([]);
  private _connectionId$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private _hubConnection: HubConnection | undefined;

  constructor(private authService: AuthService) {}

  getConnectionId() {
    return this._connectionId$.asObservable();
  }

  getPerdoruesit() {
    return this._perdoruesit$.asObservable();
  }

  statusiUpdate(statusi: PerdoruesiStatusi) {
    this._hubConnection?.invoke('StatusiUpdate', statusi);
  }

  getConnectionEstablished() {
    return this._connectionEstablished.asObservable();
  }

  //#region Baza

  private _bazaInserted$: Subject<Kerkimi[]> = new Subject();
  private _bazaUpdated$: Subject<Kerkimi[]> = new Subject();
  private _bazaDeleted$: Subject<number[]> = new Subject();
  private _radhitjaUpdated$: Subject<Record<number, number>> = new Subject<Record<number, number>>();
  private _bazaKomentiInserted$: Subject<BazaKomenti> = new Subject();
  private _bazaKomentiUpdated$: Subject<BazaKomenti> = new Subject();
  private _bazaKomentiDeleted$: Subject<BazaKomenti> = new Subject();

  getLockedBazat() {
    return combineLatest([this._perdoruesit$, this._connectionId$]).pipe(
      map(([perdoruesit, connectionId]) => {
        return perdoruesit.map((p) => {
          return { operatoriName: p.operatoriName, bazaIds: p.connections.filter((c) => c?.connectionId != connectionId).flatMap((c) => c?.bazaIds) } || [];
        });
      })
    );
  }

  addLockedBazaId(id: number) {
    this._hubConnection?.invoke('AddBazaId', id);
  }

  removeLockedBazaId(id: number) {
    this._hubConnection?.invoke('RemoveBazaId', id);
  }

  setLockedBazaIds(ids: number[]) {
    this._hubConnection?.invoke('SetBazaIds', ids);
  }

  onBazaInserted() {
    return this._bazaInserted$.asObservable();
  }

  onBazaUpdated() {
    return this._bazaUpdated$.asObservable();
  }

  onBazaDeleted() {
    return this._bazaDeleted$.asObservable();
  }

  onRadhitjaUpdated() {
    return this._radhitjaUpdated$.asObservable();
  }

  onBazaKomentiInserted() {
    return this._bazaKomentiInserted$.asObservable();
  }

  onBazaKomentiUpdated() {
    return this._bazaKomentiUpdated$.asObservable();
  }

  onBazaKomentiDeleted() {
    return this._bazaKomentiDeleted$.asObservable();
  }

  //#endregion

  //#region Shembulli

  private _shembulliInserted$: Subject<Shembulli> = new Subject();
  private _shembulliUpdated$: Subject<Shembulli> = new Subject();
  private _shembulliDeleted$: Subject<number> = new Subject();
  private _shembulliKomentiInserted$: Subject<ShembulliKomenti> = new Subject();
  private _shembulliKomentiUpdated$: Subject<ShembulliKomenti> = new Subject();
  private _shembulliKomentiDeleted$: Subject<ShembulliKomenti> = new Subject();

  getLockedShembujt() {
    return combineLatest([this._perdoruesit$, this._connectionId$]).pipe(
      map(([perdoruesit, connectionId]) => {
        return perdoruesit.map((p) => {
          return { operatoriName: p.operatoriName, shembulliIds: p.connections.filter((c) => c.connectionId != connectionId).flatMap((c) => c.shembulliIds) };
        });
      })
    );
  }

  addLockedShembulliId(id: number) {
    this._hubConnection?.invoke('AddShembulliId', id);
  }

  removeLockedShembulliId(id: number) {
    this._hubConnection?.invoke('RemoveShembulliId', id);
  }

  onShembulliInserted() {
    return this._shembulliInserted$.asObservable();
  }

  onShembulliUpdated() {
    return this._shembulliUpdated$.asObservable();
  }

  onShembulliDeleted() {
    return this._shembulliDeleted$.asObservable();
  }

  onShembulliKomentiInserted() {
    return this._shembulliKomentiInserted$.asObservable();
  }

  onShembulliKomentiUpdated() {
    return this._shembulliKomentiUpdated$.asObservable();
  }

  onShembulliKomentiDeleted() {
    return this._shembulliKomentiDeleted$.asObservable();
  }

  //#endregion

  //#region Proverba

  private _proverbaInserted$: Subject<Proverba> = new Subject();
  private _proverbaUpdated$: Subject<Proverba> = new Subject();
  private _proverbaDeleted$: Subject<number> = new Subject();
  private _proverbaKomentiInserted$: Subject<ProverbaKomenti> = new Subject();
  private _proverbaKomentiUpdated$: Subject<ProverbaKomenti> = new Subject();
  private _proverbaKomentiDeleted$: Subject<ProverbaKomenti> = new Subject();

  getLockedProverba() {
    return combineLatest([this._perdoruesit$, this._connectionId$]).pipe(
      map(([perdoruesit, connectionId]) => {
        return perdoruesit.map((p) => {
          return { operatoriName: p.operatoriName, proverbaIds: p.connections.filter((c) => c.connectionId != connectionId).flatMap((c) => c.proverbaIds) };
        });
      })
    );
  }

  addLockedProverbaId(id: number) {
    this._hubConnection?.invoke('AddProverbaId', id);
  }

  removeLockedProverbaId(id: number) {
    this._hubConnection?.invoke('RemoveProverbaId', id);
  }

  onProverbaInserted() {
    return this._proverbaInserted$.asObservable();
  }

  onProverbaUpdated() {
    return this._proverbaUpdated$.asObservable();
  }

  onProverbaDeleted() {
    return this._proverbaDeleted$.asObservable();
  }

  onProverbaKomentiInserted() {
    return this._proverbaKomentiInserted$.asObservable();
  }

  onProverbaKomentiUpdated() {
    return this._proverbaKomentiUpdated$.asObservable();
  }

  onProverbaKomentiDeleted() {
    return this._proverbaKomentiDeleted$.asObservable();
  }

  //#endregion

  //#region Frazeologjia

  private _frazeologjiaInserted$: Subject<Frazeologjia> = new Subject();
  private _frazeologjiaUpdated$: Subject<Frazeologjia> = new Subject();
  private _frazeologjiaDeleted$: Subject<number> = new Subject();
  private _frazeologjiaKomentiInserted$: Subject<FrazeologjiaKomenti> = new Subject();
  private _frazeologjiaKomentiUpdated$: Subject<FrazeologjiaKomenti> = new Subject();
  private _frazeologjiaKomentiDeleted$: Subject<FrazeologjiaKomenti> = new Subject();

  getLockedFrazeologjite() {
    return combineLatest([this._perdoruesit$, this._connectionId$]).pipe(
      map(([perdoruesit, connectionId]) => {
        return perdoruesit.map((p) => {
          return { operatoriName: p.operatoriName, frazeologjiaIds: p.connections.filter((c) => c.connectionId != connectionId).flatMap((c) => c.frazeologjiaIds) };
        });
      })
    );
  }

  addLockedFrazeologjiaId(id: number) {
    this._hubConnection?.invoke('AddFrazeologjiaId', id);
  }

  removeLockedFrazeologjiaId(id: number) {
    this._hubConnection?.invoke('RemoveFrazeologjiaId', id);
  }

  onFrazeologjiaInserted() {
    return this._frazeologjiaInserted$.asObservable();
  }

  onFrazeologjiaUpdated() {
    return this._frazeologjiaUpdated$.asObservable();
  }

  onFrazeologjiaDeleted() {
    return this._frazeologjiaDeleted$.asObservable();
  }

  onFrazeologjiaKomentiInserted() {
    return this._frazeologjiaKomentiInserted$.asObservable();
  }

  onFrazeologjiaKomentiUpdated() {
    return this._frazeologjiaKomentiUpdated$.asObservable();
  }

  onFrazeologjiaKomentiDeleted() {
    return this._frazeologjiaKomentiDeleted$.asObservable();
  }

  //#endregion

  //#region FjalaHuaj

  private _fjalaHuajInserted$: Subject<FjalaHuaj> = new Subject();
  private _fjalaHuajUpdated$: Subject<FjalaHuaj> = new Subject();
  private _fjalaHuajDeleted$: Subject<number> = new Subject();
  private _fjalaHuajKomentiInserted$: Subject<FjalaHuajKomenti> = new Subject();
  private _fjalaHuajKomentiUpdated$: Subject<FjalaHuajKomenti> = new Subject();
  private _fjalaHuajKomentiDeleted$: Subject<FjalaHuajKomenti> = new Subject();

  getLockedFjalaHuaj() {
    return combineLatest([this._perdoruesit$, this._connectionId$]).pipe(
      map(([perdoruesit, connectionId]) => {
        return perdoruesit.map((p) => {
          return { operatoriName: p.operatoriName, fjalaHuajIds: p.connections.filter((c) => c.connectionId != connectionId).flatMap((c) => c.fjalaHuajIds) };
        });
      })
    );
  }

  addLockedFjalaHuajId(id: number) {
    this._hubConnection?.invoke('AddFjalaHuajId', id);
  }

  removeLockedFjalaHuajId(id: number) {
    this._hubConnection?.invoke('RemoveFjalaHuajId', id);
  }

  onFjalaHuajInserted() {
    return this._fjalaHuajInserted$.asObservable();
  }

  onFjalaHuajUpdated() {
    return this._fjalaHuajUpdated$.asObservable();
  }

  onFjalaHuajDeleted() {
    return this._fjalaHuajDeleted$.asObservable();
  }

  onFjalaHuajKomentiInserted() {
    return this._fjalaHuajKomentiInserted$.asObservable();
  }

  onFjalaHuajKomentiUpdated() {
    return this._fjalaHuajKomentiUpdated$.asObservable();
  }

  onFjalaHuajKomentiDeleted() {
    return this._fjalaHuajKomentiDeleted$.asObservable();
  }

  //#endregion

  //#region FjalaGabim

  private _fjalaGabimInserted$: Subject<FjalaGabim> = new Subject();
  private _fjalaGabimUpdated$: Subject<FjalaGabim> = new Subject();
  private _fjalaGabimDeleted$: Subject<number> = new Subject();
  private _fjalaGabimKomentiInserted$: Subject<FjalaGabimKomenti> = new Subject();
  private _fjalaGabimKomentiUpdated$: Subject<FjalaGabimKomenti> = new Subject();
  private _fjalaGabimKomentiDeleted$: Subject<FjalaGabimKomenti> = new Subject();

  getLockedFjalaGabim() {
    return combineLatest([this._perdoruesit$, this._connectionId$]).pipe(
      map(([perdoruesit, connectionId]) => {
        return perdoruesit.map((p) => {
          return { operatoriName: p.operatoriName, fjalaGabimIds: p.connections.filter((c) => c.connectionId != connectionId).flatMap((c) => c.fjalaGabimIds) };
        });
      })
    );
  }

  addLockedFjalaGabimId(id: number) {
    this._hubConnection?.invoke('AddFjalaGabimId', id);
  }

  removeLockedFjalaGabimId(id: number) {
    this._hubConnection?.invoke('RemoveFjalaGabimId', id);
  }

  onFjalaGabimInserted() {
    return this._fjalaGabimInserted$.asObservable();
  }

  onFjalaGabimUpdated() {
    return this._fjalaGabimUpdated$.asObservable();
  }

  onFjalaGabimDeleted() {
    return this._fjalaGabimDeleted$.asObservable();
  }

  onFjalaGabimKomentiInserted() {
    return this._fjalaGabimKomentiInserted$.asObservable();
  }

  onFjalaGabimKomentiUpdated() {
    return this._fjalaGabimKomentiUpdated$.asObservable();
  }

  onFjalaGabimKomentiDeleted() {
    return this._fjalaGabimKomentiDeleted$.asObservable();
  }

  //#endregion

  private _notificationReceived$: Subject<Njoftimi> = new Subject();

  onNotificationReceived() {
    return this._notificationReceived$.asObservable();
  }

  initializeSignalR() {
    this.createConnection();
    return this.startConnection();
  }

  async closeConnection() {
    await this._hubConnection?.stop();
    this._perdoruesit$.next([]);
    this._connectionEstablished.next(false);
    return true;
  }

  private createConnection() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(environment.signalRUrl, {
        accessTokenFactory: () => {
          return this.authService.accessToken;
        },
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(environment.production ? LogLevel.None : LogLevel.Error)
      .withAutomaticReconnect()
      .build();

    this._hubConnection.serverTimeoutInMilliseconds = 5000000;

    this._hubConnection?.onclose(() => {
      this._connectionEstablished.next(false);
      this._perdoruesit$.next([]);
    });
    this._hubConnection?.onreconnected(() => {
      this._connectionEstablished.next(true);
    });
  }

  private async startConnection() {
    try {
      await this._hubConnection?.start().then(() => {
        this._hubConnection?.invoke('OnClientConnected');
        this._connectionEstablished.next(true);
        this.registerOnServerEvents();
      });
      // this._hubConnection?.invoke('OnClientConnected');
      return true;
    } catch (err) {
      console.log('Error while establishing connection, retrying...');
      this._connectionEstablished.next(false);
      this._perdoruesit$.next([]);
      setTimeout(() => {
        this.startConnection();
      }, 5000);
      return false;
    }
  }

  private registerOnServerEvents(): void {
    this._hubConnection?.on('OnConnect', (connectionId: string, perdoruesit: Perdoruesi[]) => {
      // console.log('On connect perdoruesit', perdoruesit);

      this._connectionId$.next(connectionId);
      this._perdoruesit$.next(perdoruesit);
      this._connectionEstablished.next(true);
    });
    this._hubConnection?.on('PerdoruesiConnected', (perdoruesi: Perdoruesi) => {
      // console.log("PerdoruesiConnected:", perdoruesi);
      const perdoruesit: Perdoruesi[] = [...this._perdoruesit$.value];
      perdoruesit.push(perdoruesi);
      this._perdoruesit$.next(perdoruesit);
    });
    this._hubConnection?.on('PerdoruesiNewConnection', (operatoriId: string, connection: PerdoruesiConnection) => {
      // console.log("PerdoruesiNewConnection:" + operatoriId, connection);
      const perdoruesit: Perdoruesi[] = [...this._perdoruesit$.value];
      if (perdoruesit.length > 0) {
        const index = perdoruesit.findIndex((p) => p.operatoriId == operatoriId);
        perdoruesit[index].connections.push(connection);
        this._perdoruesit$.next(perdoruesit);
      }
    });
    this._hubConnection?.on('PerdoruesiConnectionUpdated', (connection: PerdoruesiConnection) => {
      // console.log("connection updated:", connection);
      const perdoruesit: Perdoruesi[] = [...this._perdoruesit$.value];
      if (perdoruesit.length > 0) {
        const index = perdoruesit.findIndex((p) => p.connections.some((c) => c.connectionId == connection.connectionId));
        const connectionIndex = perdoruesit[index].connections.findIndex((c) => c.connectionId == connection.connectionId);
        perdoruesit[index].connections[connectionIndex] = connection;
        this._perdoruesit$.next(perdoruesit);
      }
    });
    this._hubConnection?.on('PerdoruesiConnectionsUpdated', (connections: PerdoruesiConnection[]) => {
      const perdoruesit: Perdoruesi[] = [...this._perdoruesit$.value];
      if (perdoruesit.length > 0) {
        connections.forEach((connection) => {
          const index = perdoruesit.findIndex((p) => p.connections.some((c) => c.connectionId == connection.connectionId));
          const connectionIndex = perdoruesit[index].connections.findIndex((c) => c.connectionId == connection.connectionId);
          perdoruesit[index].connections[connectionIndex] = connection;
        });
        this._perdoruesit$.next(perdoruesit);
      }
    });
    this._hubConnection?.on('PerdoruesiDroppedConnection', (connectionId: string) => {
      // console.log("Perdoruesi dropped connection:", connectionId);
      const perdoruesit: Perdoruesi[] = [...this._perdoruesit$.value];
      const index = perdoruesit.findIndex((p) => p.connections.some((c) => c.connectionId == connectionId));
      const connectionIndex = perdoruesit[index].connections.findIndex((c) => c.connectionId == connectionId);
      perdoruesit[index].connections.splice(connectionIndex, 1);
      this._perdoruesit$.next(perdoruesit);
    });
    this._hubConnection?.on('PerdoruesiDisconnected', (perdoruesi: Perdoruesi) => {
      // console.log("Perdoruesi disconnected :", perdoruesi);
      const perdoruesit: Perdoruesi[] = [...this._perdoruesit$.value];
      const index = perdoruesit.findIndex((p) => p.operatoriId == perdoruesi.operatoriId);
      perdoruesit.splice(index, 1);
      this._perdoruesit$.next(perdoruesit);
    });
    this._hubConnection?.on('NotificationReceived', (njoftimi: Njoftimi) => {
      this._notificationReceived$.next(njoftimi);
    });

    this.registerBazaServerEvents();
    this.registerShembulliServerEvents();
    this.registerProverbaServerEvents();
    this.registerFrazeologjiaServerEvents();
    this.registerFjalaHuajServerEvents();
    this.registerFjalaGabimServerEvents();
  }

  registerBazaServerEvents() {
    this._hubConnection?.on('OnBazaInserted', (kerkimet: Kerkimi[]) => {
      this._bazaInserted$.next(kerkimet);
    });
    this._hubConnection?.on('OnBazaUpdated', (kerkimet: Kerkimi[]) => {
      this._bazaUpdated$.next(kerkimet);
    });
    this._hubConnection?.on('OnBazaDeleted', (bazaIds: number[]) => {
      this._bazaDeleted$.next(bazaIds);
    });
    this._hubConnection?.on('OnKerkimiRadhitjaUpdated', (radhitja: Record<number, number>) => {
      this._radhitjaUpdated$.next(radhitja);
    });
    this._hubConnection?.on('OnBazaKomentiInserted', (komenti: BazaKomenti) => {
      this._bazaKomentiInserted$.next(komenti);
    });
    this._hubConnection?.on('OnBazaKomentiUpdated', (komenti: BazaKomenti) => {
      this._bazaKomentiUpdated$.next(komenti);
    });
    this._hubConnection?.on('OnBazaKomentiDeleted', (komenti: BazaKomenti) => {
      this._bazaKomentiDeleted$.next(komenti);
    });
  }

  unregisterBazaServerEvents() {
    this._hubConnection?.off('OnBazaInserted');
    this._hubConnection?.off('OnBazaUpdated');
    this._hubConnection?.off('OnBazaDeleted');
    this._hubConnection?.off('OnKerkimiRadhitjaUpdated');
    this._hubConnection?.off('OnBazaKomentiInserted');
    this._hubConnection?.off('OnBazaKomentiUpdated');
    this._hubConnection?.off('OnBazaKomentiDeleted');
  }
  
  registerShembulliServerEvents() {
    this._hubConnection?.on('OnShembulliInserted', (shembulli: Shembulli) => {
      this._shembulliInserted$.next(shembulli);
    });
    this._hubConnection?.on('OnShembulliUpdated', (shembulli: Shembulli) => {
      this._shembulliUpdated$.next(shembulli);
    });
    this._hubConnection?.on('OnShembulliDeleted', (id: number) => {
      this._shembulliDeleted$.next(id);
    });
    this._hubConnection?.on('OnShembulliKomentiInserted', (komenti: ShembulliKomenti) => {
      this._shembulliKomentiInserted$.next(komenti);
    });
    this._hubConnection?.on('OnShembulliKomentiUpdated', (komenti: ShembulliKomenti) => {
      this._shembulliKomentiUpdated$.next(komenti);
    });
    this._hubConnection?.on('OnShembulliKomentiDeleted', (komenti: ShembulliKomenti) => {
      this._shembulliKomentiDeleted$.next(komenti);
    });
  }

  unregisterShembulliServerEvents() {
    this._hubConnection?.off('OnShembulliInserted');
    this._hubConnection?.off('OnShembulliUpdated');
    this._hubConnection?.off('OnShembulliDeleted');
    this._hubConnection?.off('OnShembulliKomentiInserted');
    this._hubConnection?.off('OnShembulliKomentiUpdated');
    this._hubConnection?.off('OnShembulliKomentiDeleted');
  }
  
  registerProverbaServerEvents() {
    this._hubConnection?.on('OnProverbaInserted', (proverba: Proverba) => {
      this._proverbaInserted$.next(proverba);
    });
    this._hubConnection?.on('OnProverbaUpdated', (proverba: Proverba) => {
      this._proverbaUpdated$.next(proverba);
    });
    this._hubConnection?.on('OnProverbaDeleted', (id: number) => {
      this._proverbaDeleted$.next(id);
    });
    this._hubConnection?.on('OnProverbaKomentiInserted', (komenti: ProverbaKomenti) => {
      this._proverbaKomentiInserted$.next(komenti);
    });
    this._hubConnection?.on('OnProverbaKomentiUpdated', (komenti: ProverbaKomenti) => {
      this._proverbaKomentiUpdated$.next(komenti);
    });
    this._hubConnection?.on('OnProverbaKomentiDeleted', (komenti: ProverbaKomenti) => {
      this._proverbaKomentiDeleted$.next(komenti);
    });
  }

  unregisterProverbaServerEvents() {
    this._hubConnection?.off('OnProverbaInserted');
    this._hubConnection?.off('OnProverbaUpdated');
    this._hubConnection?.off('OnProverbaDeleted');
    this._hubConnection?.off('OnProverbaKomentiInserted');
    this._hubConnection?.off('OnProverbaKomentiUpdated');
    this._hubConnection?.off('OnProverbaKomentiDeleted');
  }
  
  registerFrazeologjiaServerEvents() {
    this._hubConnection?.on('OnFrazeologjiaInserted', (frazeologjia: Frazeologjia) => {
      this._frazeologjiaInserted$.next(frazeologjia);
    });
    this._hubConnection?.on('OnFrazeologjiaUpdated', (frazeologjia: Frazeologjia) => {
      this._frazeologjiaUpdated$.next(frazeologjia);
    });
    this._hubConnection?.on('OnFrazeologjiaDeleted', (id: number) => {
      this._frazeologjiaDeleted$.next(id);
    });
    this._hubConnection?.on('OnFrazeologjiaKomentiInserted', (komenti: FrazeologjiaKomenti) => {
      this._frazeologjiaKomentiInserted$.next(komenti);
    });
    this._hubConnection?.on('OnFrazeologjiaKomentiUpdated', (komenti: FrazeologjiaKomenti) => {
      this._frazeologjiaKomentiUpdated$.next(komenti);
    });
    this._hubConnection?.on('OnFrazeologjiaKomentiDeleted', (komenti: FrazeologjiaKomenti) => {
      this._frazeologjiaKomentiDeleted$.next(komenti);
    });
  }

  unregisterFrazeologjiaServerEvents() {
    this._hubConnection?.off('OnFrazeologjiaInserted');
    this._hubConnection?.off('OnFrazeologjiaUpdated');
    this._hubConnection?.off('OnFrazeologjiaDeleted');
    this._hubConnection?.off('OnFrazeologjiaKomentiInserted');
    this._hubConnection?.off('OnFrazeologjiaKomentiUpdated');
    this._hubConnection?.off('OnFrazeologjiaKomentiDeleted');
  }
  
  registerFjalaHuajServerEvents() {
    this._hubConnection?.on('OnFjalaHuajInserted', (fjalaHuaj: FjalaHuaj) => {
      this._fjalaHuajInserted$.next(fjalaHuaj);
    });
    this._hubConnection?.on('OnFjalaHuajUpdated', (fjalaHuaj: FjalaHuaj) => {
      this._fjalaHuajUpdated$.next(fjalaHuaj);
    });
    this._hubConnection?.on('OnFjalaHuajDeleted', (id: number) => {
      this._fjalaHuajDeleted$.next(id);
    });
    this._hubConnection?.on('OnFjalaHuajKomentiInserted', (komenti: FjalaHuajKomenti) => {
      this._fjalaHuajKomentiInserted$.next(komenti);
    });
    this._hubConnection?.on('OnFjalaHuajKomentiUpdated', (komenti: FjalaHuajKomenti) => {
      this._fjalaHuajKomentiUpdated$.next(komenti);
    });
    this._hubConnection?.on('OnFjalaHuajKomentiDeleted', (komenti: FjalaHuajKomenti) => {
      this._fjalaHuajKomentiDeleted$.next(komenti);
    });
  }

  unregisterFjalaHuajServerEvents() {
    this._hubConnection?.off('OnFjalaHuajInserted');
    this._hubConnection?.off('OnFjalaHuajUpdated');
    this._hubConnection?.off('OnFjalaHuajDeleted');
    this._hubConnection?.off('OnFjalaHuajKomentiInserted');
    this._hubConnection?.off('OnFjalaHuajKomentiUpdated');
    this._hubConnection?.off('OnFjalaHuajKomentiDeleted');
  }
  
  registerFjalaGabimServerEvents() {
    this._hubConnection?.on('OnFjalaGabimInserted', (fjalaGabim: FjalaGabim) => {
      this._fjalaGabimInserted$.next(fjalaGabim);
    });
    this._hubConnection?.on('OnFjalaGabimUpdated', (fjalaGabim: FjalaGabim) => {
      this._fjalaGabimUpdated$.next(fjalaGabim);
    });
    this._hubConnection?.on('OnFjalaGabimDeleted', (id: number) => {
      this._fjalaGabimDeleted$.next(id);
    });
    this._hubConnection?.on('OnFjalaGabimKomentiInserted', (komenti: FjalaGabimKomenti) => {
      this._fjalaGabimKomentiInserted$.next(komenti);
    });
    this._hubConnection?.on('OnFjalaGabimKomentiUpdated', (komenti: FjalaGabimKomenti) => {
      this._fjalaGabimKomentiUpdated$.next(komenti);
    });
    this._hubConnection?.on('OnFjalaGabimKomentiDeleted', (komenti: FjalaGabimKomenti) => {
      this._fjalaGabimKomentiDeleted$.next(komenti);
    });
  }

  unregisterFjalaGabimServerEvents() {
    this._hubConnection?.off('OnFjalaGabimInserted');
    this._hubConnection?.off('OnFjalaGabimUpdated');
    this._hubConnection?.off('OnFjalaGabimDeleted');
    this._hubConnection?.off('OnFjalaGabimKomentiInserted');
    this._hubConnection?.off('OnFjalaGabimKomentiUpdated');
    this._hubConnection?.off('OnFjalaGabimKomentiDeleted');
  }
}
