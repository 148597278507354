import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { ShembulliFjalaPerzgjedhurForCreationDTO } from '@core/interfaces/dto/creation/shembulli-fjala-perzgjedhur-for-creation-dto.interface';
import { ShembulliFjalaPerzgjedhurForUpdateDTO } from '@core/interfaces/dto/update/shembulli-fjala-perzgjedhur-for-update-dto.interface';
import { ShembulliFjalaPerzgjedhur } from '@core/interfaces/models/shembulli-fjala-perzgjedhur.interface';
import { BaseParams } from '@core/interfaces/params/base-params.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class ShembulliFjalaPerzgjedhurService extends Store<
  ShembulliFjalaPerzgjedhur,
  BaseParams,
  ShembulliFjalaPerzgjedhurForCreationDTO,
  ShembulliFjalaPerzgjedhurForUpdateDTO
> {
  constructor(apiService: ApiService) {
    super(apiService, 'shembulliFjalaPerzgjedhur/');
  }
}
