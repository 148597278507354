<div class="navWrap">
  <div class="left">
    <a #contextTrigger [matMenuTriggerFor]="contextMenu">
      <mat-icon id="logo" class="mat-primary" svgIcon="as_logo"></mat-icon>
    </a>
    <mat-menu #contextMenu="matMenu" class="drop-down-menu">
      <button mat-menu-item routerLink="home">{{ 'Fjalët' | phrase }}</button>
      <button mat-menu-item routerLink="shembujt">{{ 'Shembujt' | phrase }}</button>
      <button mat-menu-item routerLink="proverba">{{ 'Fjalë të urta' | phrase }}</button>
      <button mat-menu-item routerLink="frazeologjia">{{ 'Frazeologjitë' | phrase }}</button>
      <button mat-menu-item routerLink="fjala-huaj">{{ 'Fjalë të huaja' | phrase }}</button>
      <button mat-menu-item routerLink="fjala-gabim">{{ 'Zakonisht gabohet' | phrase }}</button>
    </mat-menu>
  </div>
  <mat-divider [vertical]="true"></mat-divider>
  <div class="middle">
    <app-fjala-nav-buttons *ngIf="showFjalaNavButtons"></app-fjala-nav-buttons>
    <app-shembujt-nav-buttons *ngIf="showShembujtNavButtons"></app-shembujt-nav-buttons>
    <app-proverba-nav-buttons *ngIf="showProverbaNavButtons"></app-proverba-nav-buttons>
    <app-frazeologjia-nav-buttons *ngIf="showFrazeologjiaNavButtons"></app-frazeologjia-nav-buttons>
    <app-fjala-huaj-nav-buttons *ngIf="showFjalaHuajNavButtons"></app-fjala-huaj-nav-buttons>
    <app-fjala-gabim-nav-buttons *ngIf="showFjalaGabimNavButtons"></app-fjala-gabim-nav-buttons>
  </div>

  <div class="right">
    <app-perdoruesit></app-perdoruesit>
    <button (click)="toggleTheme()" type="button" mat-icon-button>
      <mat-icon class="light-icon" *ngIf="darkMode">light_mode</mat-icon>
      <mat-icon class="dark-icon" *ngIf="!darkMode">dark_mode</mat-icon>
    </button>
    <button mat-icon-button class="mat-primary" [routerLink]="['./blog']" disabled>
      <mat-icon svgIcon="as_blog" [matTooltip]="'navigation_blog' | phrase" matTooltipPosition="below"></mat-icon>
    </button>
    <button mat-icon-button class="mat-primary" [matMenuTriggerFor]="settings">
      <mat-icon svgIcon="as_settings"></mat-icon>
    </button>
    <mat-menu #settings="matMenu" xPosition="before" class="drop-down-menu">
      <ng-container *hasRoles="['admin']">
        <button mat-menu-item [routerLink]="['./admin']">
          {{ 'settings_menu_admin' | phrase }}
        </button>
      </ng-container>
      <!-- <button mat-menu-item [routerLink]="['./app/parameters']">{{ 'settings_menu_parameters' | phrase }}</button> -->
      <button mat-menu-item [routerLink]="['./contributions']" disabled>
        {{ 'settings_menu_contributions' | phrase }}
      </button>
      <button mat-menu-item (click)="logout()">
        {{ 'settings_menu_logoff' | phrase }}
      </button>
    </mat-menu>
  </div>
  <mat-progress-bar [mode]="loading ? 'query' : 'indeterminate'" *ngIf="saving || loading"></mat-progress-bar>
</div>
