<button mat-icon-button class="mat-primary" [disabled]="jumpProverbaId == null || saving" (click)="jump()">
  <mat-icon svgIcon="as_jump" [matTooltip]="'navigation_jump' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!canUndo && !saving" (click)="undo()">
  <mat-icon svgIcon="as_back" [matTooltip]="'navigation_undo' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary mirrored" [disabled]="!canRedo && !saving" (click)="redo()">
  <mat-icon svgIcon="as_back" [matTooltip]="'navigation_redo' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" (click)="onEditorClearClick()">
  <mat-icon svgIcon="as_a_plus" [matTooltip]="'navigation_new' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!canDelete" (click)="onDeleteClick()">
  <mat-icon svgIcon="as_delete" [matTooltip]="'navigation_delete' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="true">
  <mat-icon svgIcon="as_attachment" [matTooltip]="'navigation_attach' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!prevProverbaId || saving" (click)="onPrevClick()">
  <mat-icon svgIcon="as_cancel_and_back" [matTooltip]="'navigation_back' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!formDirty || !formValid || !prevProverbaId || saving" (click)="onSaveAndPrevClick()">
  <mat-icon svgIcon="as_save_and_back" [matTooltip]="'navigation_save_and_back' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!formDirty || !formValid || saving" (click)="onSaveClick()">
  <mat-icon svgIcon="as_save" [matTooltip]="'navigation_save' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!formDirty || !formValid || !nextProverbaId || saving" (click)="onSaveAndNextClick()">
  <mat-icon svgIcon="as_save_and_next" [matTooltip]="'navigation_save_and_next' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!nextProverbaId || saving" (click)="onNextClick()">
  <mat-icon svgIcon="as_cancel_and_next" [matTooltip]="'navigation_next' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" (click)="openShenimet()">
  <mat-icon svgIcon="as_notes" [matTooltip]="'navigation_notes' | phrase" matTooltipPosition="below"></mat-icon>
</button>
<button mat-icon-button class="mat-primary" [disabled]="!proverbaId">
  <mat-icon svgIcon="as_comments" [matTooltip]="'navigation_comments' | phrase" matTooltipPosition="below" (click)="onCommentsClick()"></mat-icon>
</button>
