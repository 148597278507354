import { Injectable } from '@angular/core';
import { StrukturaStore } from '@core/classes/struktura-store.class';
import { FjalaHuajFjalaPerzgjedhurStruktura } from '@core/interfaces/models/fjala-huaj-fjala-perzgjedhur-struktura.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class FjalaHuajFjalaPerzgjedhurStrukturaService extends StrukturaStore<FjalaHuajFjalaPerzgjedhurStruktura> {

  constructor(apiService: ApiService) {
    super(apiService, 'fjalaHuajFjalaPerzgjedhurStruktura');
  }

  updateMy(items: FjalaHuajFjalaPerzgjedhurStruktura[]) {
    return this.apiService.put<FjalaHuajFjalaPerzgjedhurStruktura[]>(`${this._endpointUrl}my`, { fjalaHuajFjalaPerzgjedhurStrukturaForCreationDTO: items });
  }

}