import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
})
export class AsSearchAndReplaceHightlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, args: { phrase: string | string[]; caseSensitive?: boolean; sanitize?: boolean; wholeWord?: boolean }): any {
    if (!value) {
      return null;
    }
    value = value.escapeHtml();
    if (!args) {
      return value;
    }

    let items: string[] = [];
    if (typeof args.phrase === 'string' || args.phrase instanceof String) {
      if (args.phrase != '') items.push(args.phrase as string);
    } else if (args.phrase) {
      items = args.phrase as string[];
    }

    let replacedValue = value;

    for (let i = 0; i < items.length; i++) {
      const re = new RegExp((args.wholeWord ? '\\b' : '') + this.escapeRegExp(items[i]) + (args.wholeWord ? '\\b' : ''), `g${args.caseSensitive ? '' : 'i'}`);
      const match = value.match(re);

      if (match) {
        replacedValue = replacedValue.replace(re, (str: string) => {
          return '<mark>' + str + '</mark>';
        });
      }
    }

    if (args.sanitize) return this.sanitizer.bypassSecurityTrustHtml(replacedValue);

    return replacedValue;
    // // Match in a case insensitive maneer
    // const re = new RegExp(args, 'gi');
    // const match = value.match(re);

    // // If there's no match, just return the original value.
    // if (!match) {
    //     return value;
    // }

    // const replacedValue = value.replace(re, "<mark>" + match[0] + "</mark>")
    // return this.sanitizer.bypassSecurityTrustHtml(replacedValue)
  }

  private escapeRegExp(text: string) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
}
