import { Injectable } from '@angular/core';
import { SingleStore } from '@core/classes/single-store.class';
import { Defaults } from '@core/globals/defaults';
import { OperatoriFaqjaForCreationDTO } from '@core/interfaces/dto/creation/operatori-faqja-for-creation-dto.interface';
import { OperatoriFaqjaForUpdateDTO } from '@core/interfaces/dto/update/operatori-faqja-for-update-dto.interface';
import { OperatoriFaqja } from '@core/interfaces/models/operatori-faqja.interface';
import { OperatoriFaqjaParams } from '@core/interfaces/params/operatori-faqja-params.interface';
import { ApiService } from './api.service';
import { tap } from 'rxjs';
import { SplitConfig } from '@core/interfaces/split-config.interface';

@Injectable({
  providedIn: 'root',
})
export class OperatoriFaqjaService extends SingleStore<OperatoriFaqja, OperatoriFaqjaParams, OperatoriFaqjaForCreationDTO, OperatoriFaqjaForUpdateDTO> {
  constructor(apiService: ApiService) {
    super(apiService, 'operatoriFaqja', Defaults.OperatoriFaqja);
  }

  override load(params?: OperatoriFaqjaParams) {
    return super.get(params).pipe(
      tap((item) => {
        if (item) {
          const splitConfig = JSON.parse(item.splitConfig) as SplitConfig;
          if (splitConfig.columns.length < Defaults.SplitConfig.columns.length) {
            item.splitConfig = JSON.stringify(Defaults.SplitConfig);
          } else {
            for (let i = 0; i < splitConfig.columns.length; i++) {
              if (splitConfig.columns[i].rows?.length != Defaults.SplitConfig.columns[i].rows?.length) {
                item.splitConfig = JSON.stringify(Defaults.SplitConfig);
                break;
              }
            }
          }
          super.updateStore(item, false);
        } else {
          super.updateStore(Defaults.OperatoriFaqja, false);
        }
        this._dataLoaded$.next(true);
      })
    );
  }
}
