import { NgModule } from '@angular/core';
import { PhrasePipe } from './phrase.pipe';

@NgModule({
  declarations: [PhrasePipe],
  imports: [],
  exports: [PhrasePipe],
  providers: [PhrasePipe]
})
export class PhraseModule { }
