export const environment = {
  production: true,
  staging: false,
  fjaloriKryesorId: 13,
  baseUrl: 'https://leks.gjuhashqipe.com/',
  apiUrl: "https://leksapi.gjuhashqipe.com/api/",
  signalRUrl: "https://leksapi.gjuhashqipe.com/leksikografiahub",
  ssoUrl: 'https://sso.gjuhashqipe.com',
  sentenceApiUrl: 'https://sentenceapi.gjuhashqipe.com/',
};
