export enum FilterType {
  StartsWith = 0,
  WordStartsWith = 1,
  Contains = 2,
  StartsFrom = 3,
  ById = 4,
  ByPartOfSpeech = 5,
  ByWordSource = 6,
  AllWords = 7,
}
