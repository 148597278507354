import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForbiddenComponent } from '@core/auth/forbidden/forbidden.component';
import { UnauthorizedComponent } from '@core/auth/unauthorized/unauthorized.component';
import { AdminGuard } from '@core/guards/admin.guard';
import { AppGuard } from '@core/guards/app.guard';
import { AuthGuard } from '@core/guards/auth.guard';
import { BasicAuthGuard } from '@core/guards/basic-auth.guard';
import { FjalaGabimGuard } from '@core/guards/fjala-gabim.guard';
import { FjalaHuajGuard } from '@core/guards/fjala-huaj.guard';
import { FrazeologjiaGuard } from '@core/guards/frazeologjia.guard';
import { HomeGuard } from '@core/guards/home.guard';
import { ProverbaGuard } from '@core/guards/proverba.guard';
import { ShembulliGuard } from '@core/guards/shembulli.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        canLoad: [AuthGuard],
        canDeactivate: [HomeGuard],
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'shembujt',
        canLoad: [AuthGuard],
        canDeactivate: [ShembulliGuard],
        loadChildren: () => import('./modules/shembujt/shembujt.module').then((m) => m.ShembujtModule),
      },
      {
        path: 'proverba',
        canLoad: [AuthGuard],
        canDeactivate: [ProverbaGuard],
        loadChildren: () => import('./modules/proverba/proverba.module').then((m) => m.ProverbaModule),
      },
      {
        path: 'frazeologjia',
        canLoad: [AuthGuard],
        canDeactivate: [FrazeologjiaGuard],
        loadChildren: () => import('./modules/frazeologjite/frazeologjite.module').then((m) => m.FrazeologjiteModule),
      },
      {
        path: 'fjala-huaj',
        canLoad: [AuthGuard],
        canDeactivate: [FjalaHuajGuard],
        loadChildren: () => import('./modules/fjala-huaj/fjala-huaj.module').then((m) => m.FjalaHuajModule),
      },
      {
        path: 'fjala-gabim',
        canLoad: [AuthGuard],
        canDeactivate: [FjalaGabimGuard],
        loadChildren: () => import('./modules/fjala-gabim/fjala-gabim.module').then((m) => m.FjalaGabimModule),
      },
      {
        path: 'struktura',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/word-structure/word-structure.module').then((m) => m.WordStructureModule),
      },
      {
        path: 'admin',
        canLoad: [AdminGuard],
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    canActivate: [BasicAuthGuard],
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    canActivate: [BasicAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
