import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { EditoriService } from '@core/services/editori.service';
import { HomeComponent } from '@home/home.component';
import { ConfirmPageChangeComponent } from '@shared/dialogs/confirm-page-change/confirm-page-change.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanDeactivate<HomeComponent> {

  constructor(private editoriService: EditoriService, private matDialog: MatDialog) {}

  canDeactivate(component: HomeComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(this.editoriService.editoriForm.dirty) {
      const dialog = this.matDialog.open(ConfirmPageChangeComponent, {
        width: '400px',
        disableClose: false
      });

      return dialog.afterClosed();
    }
    return true;
  }

}
