interface String {
  /**
   * Determines whether two strings are equivalent in the albanian locale.
   * @param that String to compare to target string
   */
  albanianCompare(that?: string): number;

  /**
   * Replaces the last occurrence of a text in a string.
   * @param searchValue A string to search for.
   * @param replaceValue A string containing the text to replace the last successful match of searchValue in this string.
   */
  replaceLastOccurrence(searchValue: string, replaceValue?: string): string;

  /**
   * Escapes HTML tags (&, <, >)
   */
  escapeHtml(): string;
}

String.prototype.albanianCompare = function (that: string): number {
  const albanianAlphabet: string[] = [
    'a',
    'b',
    'c',
    'ç',
    'd',
    'dh',
    'e',
    'ë',
    'f',
    'g',
    'gj',
    'h',
    'i',
    'j',
    'k',
    'l',
    'll',
    'm',
    'n',
    'nj',
    'o',
    'p',
    'q',
    'r',
    'rr',
    's',
    'sh',
    't',
    'th',
    'u',
    'v',
    'x',
    'xh',
    'y',
    'z',
    'zh',
  ];
  const albanianAlphabetRegex = new RegExp('(dh|gj|ll|nj|rr|sh|th|xh|zh|.)');

  const firstArray = this.toLowerCase()
    .split(albanianAlphabetRegex)
    .filter((s) => s != '');
  const secondArray = that
    .toLowerCase()
    .split(albanianAlphabetRegex)
    .filter((s) => s != '');

  for (let i = 0; i < firstArray.length; i++) {
    if (!secondArray[i]) {
      return 1;
    }
    if (albanianAlphabet.indexOf(firstArray[i]) == -1 || albanianAlphabet.indexOf(secondArray[i]) == -1) {
      let lc = firstArray[i].localeCompare(secondArray[i]);
      if (lc != 0) return lc;
    }
    if (albanianAlphabet.indexOf(firstArray[i]) > albanianAlphabet.indexOf(secondArray[i])) {
      return 1;
    } else if (albanianAlphabet.indexOf(firstArray[i]) < albanianAlphabet.indexOf(secondArray[i])) {
      return -1;
    }
  }
  if (secondArray.length > firstArray.length) {
    return -1;
  }
  return 0;
};

String.prototype.replaceLastOccurrence = function (searchValue: string, replaceValue: string = ''): string {
  return this.replace(new RegExp(searchValue + '([^' + searchValue + ']*)$'), replaceValue + '$1');
};

String.prototype.escapeHtml = function () {
  var tagsToReplace: any = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
  };
  return this.replace(/[&<>]/g, function (tag) {
    return tagsToReplace[tag] || tag;
  });
};
