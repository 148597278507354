import { Pipe, PipeTransform } from '@angular/core';
import { PerdoruesiStatusi } from '@core/enums/perdoruesi-statusi.enum';
import { Perdoruesi } from '@core/interfaces/signalr/perdoruesi.interface';

@Pipe({
  name: 'isOnline'
})
export class IsOnlinePipe implements PipeTransform {

  transform(perdoruesi: Perdoruesi): boolean {
    return perdoruesi.connections.some((c) => c?.perdoruesiStatusi == PerdoruesiStatusi.Online);
  }

}
