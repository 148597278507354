import { trigger, transition, style, animate } from '@angular/animations';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@core/auth/auth.service';
import { AsSnackBar } from '@shared/modules/as-snack-bar/as-snack-bar.service';
import { PhrasePipe } from '@shared/modules/phrase/phrase.pipe';
import { Subject, takeUntil, catchError, throwError } from 'rxjs';
import { AsSentenceProcessorService } from '../as-sentence-processor.service';
import { FileHeader } from '../interfaces/file-header.interface';

@Component({
  selector: 'as-sentence-processor-upload',
  templateUrl: './as-sentence-processor-upload.component.html',
  styleUrls: ['./as-sentence-processor-upload.component.scss'],
  animations: [
      trigger('fadeOut', [
          // state('void', style({ opacity: 0, transform: 'translateY(-5px)', height: 0 })),
          transition('void => animState', [
              style({ opacity: 0, transform: 'translateX(-500px)', height: 0 }),
              animate('.5s ease', style({ opacity: 1, transform: 'translateX(0px)', height: '*' })),
          ]),
          transition('animState => void', [
              style({ opacity: 1, transform: 'translateX(0px)', height: '*' }),
              animate('.5s ease', style({ opacity: 0, transform: 'translateX(500px)', height: 0 })),
          ]),
      ]),
      trigger('uploadInfo', [
          transition(':enter', [
              style({ opacity: 0, transform: 'translateY(-10px)' }),
              animate('200ms ease', style({ opacity: 1, transform: 'translateY(0px)' })),
          ]),
          transition(':leave', [
              style({ opacity: 1, transform: 'translateY(0px)' }),
              animate('200ms ease', style({ opacity: 0, transform: 'translateY(10px)' })),
          ]),
      ]),
  ],
})
export class AsSentenceProcessorUploadComponent implements OnInit, OnDestroy {
  loading: boolean = false;

  public dataSource: MatTableDataSource<FileHeader> = new MatTableDataSource();
  count?: number;
  displayedColumns: string[] = ['id', 'name', 'type', 'words', 'date', 'delete'];

  allowedFileTypes = ['doc', 'docx', 'pdf'];

  uploadFile: any;
  uploadFileName?: string;

  uploading: boolean = false;
  uploadMessage: string = '';
  progress: number = 0;

  animatedRowId: any;

  isAdmin: boolean = false;

  @ViewChild('file') file?: ElementRef;
  @ViewChild('paginator') paginator?: MatPaginator;

  private afterUploadFunc: any;
  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private sentenceService: AsSentenceProcessorService,
    private asSnackBar: AsSnackBar,
    private phrasePipe: PhrasePipe,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this.authService.isLoggedInAsAdmin$.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    });

    this.sentenceService.getFiles().subscribe((list) => {
      this.loading = false;
      this.count = list.length;
      this.dataSource = new MatTableDataSource<FileHeader>(list);
      if(this.paginator)
        this.dataSource.paginator = this.paginator;
    });
  }

  onFileChange(event: any) {
    this.progress = 0;
    this.uploadMessage = '';
    this.animatedRowId = null;
    this.uploadFile = event.target.files[0];
    this.uploadFileName = event.target.files[0].name;

    if (this.afterUploadFunc) clearTimeout(this.afterUploadFunc);

    this.upload();
  }

  upload() {
    this.uploading = true;
    this.sentenceService
      .upload(this.uploadFile)
      .pipe(
        catchError((err) => {
          this.uploading = false;
          this.uploadMessage = 'error';

          setTimeout(() => {
            this.progress = 0;
            this.uploadFile = null;
            this.uploadFileName = undefined;
            this.uploadMessage = '';
          }, 1500);
          return throwError(err);
        })
      )
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / (event.total ?? 1)) * 100);
            break;
          case HttpEventType.Response:
            this.uploading = false;
            this.uploadMessage = event.body.message;

            if (event.body.message == 'file-inserted') {
              this.animatedRowId = event.body.fileHeaderObject.fileID;
              this.dataSource?.data.splice(0, 0, event.body.fileHeaderObject);
              this.dataSource?._updateChangeSubscription();
              this.asSnackBar.openSnackBar(this.phrasePipe.transform('message_file_inserted'), 'Success', 5000);
            } else if (event.body.message == 'file-exists') {
              this.asSnackBar.openSnackBar(this.phrasePipe.transform('message_file_exists'), 'Warn', 5000);
            }

            this.afterUploadFunc = setTimeout(() => {
              this.progress = 0;
              this.uploadFile = null;
              this.uploadFileName = undefined;
              this.uploadMessage = '';
              this.file!.nativeElement.value = '';
              if (this.afterUploadFunc) clearTimeout(this.afterUploadFunc);
            }, 3000);

            setTimeout(() => {
              this.animatedRowId = null;
            }, 500);
        }
      });
  }

  deleteElement(fileID: number, fileName: string) {
    this.animatedRowId = fileID;
    this.sentenceService.deleteFile(fileID, fileName).subscribe((deleted) => {
      if (deleted) {
        let index = this.dataSource?.data.findIndex((f) => f.fileID == fileID);
        if (index && index != -1) {
          this.dataSource?.data.splice(index, 1);
          this.dataSource?._updateChangeSubscription();

          setTimeout(() => {
            this.animatedRowId = null;
          }, 500);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
