import { BaseForCreationDTO } from '@core/interfaces/dto/creation/base-for-creation-dto.interface';
import { BaseForUpdateDTO } from '@core/interfaces/dto/update/base-for-update-dto.interface';
import { BaseParams } from '@core/interfaces/params/base-params.interface';
import { BaseModel } from '../interfaces/models/base-model.interface';
import { ApiService } from '../services/api.service';

export class SingleBaseApi<T1 extends BaseModel, T2 extends BaseParams, T3 extends BaseForCreationDTO, T4 extends BaseForUpdateDTO> {
  protected _baseUrl: string;

  constructor(protected apiService: ApiService, baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  get(params?: T2) {
    return this.apiService.get<T1>(`${this._baseUrl}`, params);
  }

  insert(item: T3) {
    return this.apiService.post<T1>(`${this._baseUrl}`, item);
  }

  update(item: T4) {
    return this.apiService.put<T1>(`${this._baseUrl}`, item);
  }
}
