import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AsSearchAndReplaceService {
  private _filterActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _searchingPhrase$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _caseSensitive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _inputsValueChanges$: Subject<void> = new Subject<void>();

  private _replaceAndNext$: Subject<string> = new Subject<string>();
  private _replaceAll$: Subject<string> = new Subject<string>();
  private _valuesReplaced$: Subject<void> = new Subject<void>();
  private _allValuesReplaced$: Subject<void> = new Subject<void>();

  private _replaceAllAndSave$: Subject<string> = new Subject<string>();

  constructor() {}

  setFilterActive(active: boolean) {
    this._filterActive$.next(active);
  }

  getFilterActive() {
    return this._filterActive$.asObservable();
  }

  setPhrase(phrase: string) {
    this._searchingPhrase$.next(phrase);
  }

  getPhrase() {
    return this._searchingPhrase$.asObservable();
  }

  getCaseSensitive() {
    return this._caseSensitive$.asObservable();
  }

  setCaseSensitive(caseSensitive: boolean) {
    this._caseSensitive$.next(caseSensitive);
  }

  valueChanges() {
    return this._inputsValueChanges$.asObservable();
  }

  valuesChanged() {
    this._inputsValueChanges$.next();
  }

  replaceAndNext(replacement: string) {
    this._replaceAndNext$.next(replacement);
  }

  onReplaceAndNext() {
    return this._replaceAndNext$.asObservable();
  }

  valuesReplaced() {
    this._valuesReplaced$.next();
  }

  onValuesReplaced() {
    return this._valuesReplaced$.asObservable();
  }

  replaceAll(replacement: string) {
    this._replaceAll$.next(replacement);
  }

  onReplaceAll() {
    return this._replaceAll$.asObservable();
  }

  allValuesReplaced() {
    this._allValuesReplaced$.next();
  }

  onAllValuesReplaced() {
    return this._allValuesReplaced$.asObservable();
  }
  
  replaceAllAndSave(replacement: string) {
    this._replaceAllAndSave$.next(replacement);
  }

  onReplaceAllAndSave() {
    return this._replaceAllAndSave$.asObservable();
  }

}
