import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { FjalaGabimEtiketaForCreationDTO } from '@core/interfaces/dto/creation/fjala-gabim-etiketa-for-creation-dto.interface';
import { FjalaGabimEtiketaForUpdateDTO } from '@core/interfaces/dto/update/fjala-gabim-etiketa-for-update-dto.interface';
import { FjalaGabimEtiketa } from '@core/interfaces/models/fjala-gabim-etiketa.interface';
import { FjalaGabimEtiketaParams } from '@core/interfaces/params/fjala-gabim-etiketa-params.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class FjalaGabimEtiketaService extends Store<FjalaGabimEtiketa, FjalaGabimEtiketaParams, FjalaGabimEtiketaForCreationDTO, FjalaGabimEtiketaForUpdateDTO> {
  constructor(apiService: ApiService) {
    super(apiService, 'fjalaGabimEtiketa');
  }
}
