import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { ProverbaFjalaPerzgjedhurForCreationDTO } from '@core/interfaces/dto/creation/proverba-fjala-perzgjedhur-for-creation-dto.interface';
import { ProverbaFjalaPerzgjedhurForUpdateDTO } from '@core/interfaces/dto/update/proverba-fjala-perzgjedhur-for-update-dto.interface';
import { ProverbaFjalaPerzgjedhur } from '@core/interfaces/models/proverba-fjala-perzgjedhur.interface';
import { BaseParams } from '@core/interfaces/params/base-params.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class ProverbaFjalaPerzgjedhurService extends Store<
  ProverbaFjalaPerzgjedhur,
  BaseParams,
  ProverbaFjalaPerzgjedhurForCreationDTO,
  ProverbaFjalaPerzgjedhurForUpdateDTO
> {
  constructor(apiService: ApiService) {
    super(apiService, 'proverbaFjalaPerzgjedhur/');
  }
}
