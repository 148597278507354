import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { FjaloriForCreationDTO } from '@core/interfaces/dto/creation/fjalori-for-creation-dto.interface';
import { FjaloriForUpdateDTO } from '@core/interfaces/dto/update/fjalori-for-update-dto.interface';
import { Fjalori } from '@core/interfaces/models/fjalori.interface';
import { BaseParams } from '@core/interfaces/params/base-params.interface';
import { BehaviorSubject, map } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FjaloriService extends Store<Fjalori, BaseParams, FjaloriForCreationDTO, FjaloriForUpdateDTO> {

  constructor(apiService: ApiService) {
    super(apiService, 'fjalori/');
  }

  getFjaloretPunues() {
    return this._store$.pipe(
      map((fjaloret) => {
        return fjaloret.filter(f => !f.isExcluded)
      })
    );
  }

  getFjaloretEPerjashtuar() {
    return this._store$.pipe(
      map((fjaloret) => {
        return fjaloret.filter(f => f.isExcluded)
      })
    );
  }

}
