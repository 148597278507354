import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { filter, map, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BasicAuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkLogin(state.url);
  }

  private checkLogin(url?: string) {
    return this.authService.isDoneLoading$.pipe(
      filter((isDone) => isDone),
      switchMap((_) => this.authService.isAuthenticated$),
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.authService.login(url);
        }
        return isAuthenticated;
      })
    );
  }
}
