import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { FjalaHuajEtiketaForCreationDTO } from '@core/interfaces/dto/creation/fjala-huaj-etiketa-for-creation-dto.interface';
import { FjalaHuajEtiketaForUpdateDTO } from '@core/interfaces/dto/update/fjala-huaj-etiketa-for-update-dto.interface';
import { FjalaHuajEtiketa } from '@core/interfaces/models/fjala-huaj-etiketa.interface';
import { FjalaHuajEtiketaParams } from '@core/interfaces/params/fjala-huaj-etiketa-params.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class FjalaHuajEtiketaService extends Store<FjalaHuajEtiketa, FjalaHuajEtiketaParams, FjalaHuajEtiketaForCreationDTO, FjalaHuajEtiketaForUpdateDTO> {
  constructor(apiService: ApiService) {
    super(apiService, 'fjalaHuajEtiketa');
  }
}
