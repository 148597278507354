import { Injectable } from '@angular/core';
import { BaseApi } from '@core/classes/base-api.class';
import { FjalaGabimForCreationDTO } from '@core/interfaces/dto/creation/fjala-gabim-for-creation-dto.interface';
import { FjalaGabimForUpdateDTO } from '@core/interfaces/dto/update/fjala-gabim-for-update-dto.interface';
import { FjalaGabim } from '@core/interfaces/models/fjala-gabim.interface';
import { FjalaGabimParams } from '@core/interfaces/params/fjala-gabim-params.interface';
import { BehaviorSubject, filter, map } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class FjalaGabimService extends BaseApi<FjalaGabim, FjalaGabimParams, FjalaGabimForCreationDTO, FjalaGabimForUpdateDTO> {

  private _fjalaGabimList$: BehaviorSubject<{ list: FjalaGabim[], propagate: boolean } | undefined> = new BehaviorSubject<{ list: FjalaGabim[], propagate: boolean } | undefined>(undefined);
  private _loadedFjaloriId$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  constructor(apiService: ApiService) {
    super(apiService, 'fjalaGabim');
  }

  getFjalaGabimList(propagateOnly: boolean = true) {
    return this._fjalaGabimList$.asObservable().pipe(
      filter((value) => (propagateOnly ? value?.propagate! : true)),
      map((value) => {
        return value ? value.list : undefined;
      })
    );
  }

  resetFjalaGabimList() {
    this._fjalaGabimList$.next(undefined);
  }

  setFjalaGabimList(list: FjalaGabim[], propagate: boolean = true) {
    this._fjalaGabimList$.next({ list: list, propagate: propagate });
  }

  resetLoadedFjaloriId() {
    this._loadedFjaloriId$.next(undefined);
  }

  getLoadedFjaloriId() {
    return this._loadedFjaloriId$.asObservable();
  }

  setLoadedFjaloriId(fjaloriId: number) {
    this._loadedFjaloriId$.next(fjaloriId);
  }

}
