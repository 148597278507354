import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ResizableDragDropComponent } from '@shared/components/resizable-drag-drop/resizable-drag-drop.component';

@Component({
  selector: 'as-sentence-processor',
  templateUrl: './as-sentence-processor.component.html',
  styleUrls: ['./as-sentence-processor.component.scss']
})
export class AsSentenceProcessorComponent extends ResizableDragDropComponent implements OnInit {

  constructor(
    host: ElementRef,
    renderer: Renderer2,
    private dialogRef: MatDialogRef<AsSentenceProcessorComponent>,
  ) {
    super(host, renderer, 'as-sentence-processor');
  }

  ngOnInit(): void {
  }

  override close() {
    this.dialogRef.close();
    super.close();
  }

}
