import { FormGroup } from '@angular/forms';
import { combineLatest, map, Observable, startWith } from 'rxjs';

declare module '@angular/forms' {
  interface FormGroup {
    listenChangesOn(fields: string[]): Observable<any>;
    listenOnFieldChanges(field: string): Observable<any>;
  }
}

FormGroup.prototype.listenChangesOn = function (fields: string[]) {
  return combineLatest(fields.map((field) => this.get(field)?.valueChanges.pipe(startWith(this.get(field)?.value)))).pipe(
    map((arr) => {
      let obj: any = {};
      for (let i = 0; i < arr.length; i++) {
        obj[fields[i]] = arr[i];
      }
      return obj;
    })
  );
};


FormGroup.prototype.listenOnFieldChanges = function (field: string) {
  return this.get(field)!.valueChanges.pipe(startWith(this.get(field)?.value));
}

export {};
