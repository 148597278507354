import { Injectable } from '@angular/core';
import { StrukturaStore } from '@core/classes/struktura-store.class';
import { FjaloriStruktura } from '@core/interfaces/models/fjalori-struktura.interface';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FjaloriStrukturaService extends StrukturaStore<FjaloriStruktura> {
  constructor(apiService: ApiService) {
    super(apiService, 'fjaloriStruktura');
  }

  updateMy(items: FjaloriStruktura[]): Observable<FjaloriStruktura[]> {
    return this.apiService.put<FjaloriStruktura[]>(`${this._endpointUrl}my`, { fjaloriStrukturaForCreationDTO: items });
  }

}
