import { Injectable } from '@angular/core';
import { StrukturaStore } from '@core/classes/struktura-store.class';
import { ProverbaEtiketaStruktura } from '@core/interfaces/models/proverba-etiketa-struktura.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ProverbaEtiketaStrukturaService extends StrukturaStore<ProverbaEtiketaStruktura> {

  constructor(apiService: ApiService) {
    super(apiService, 'proverbaEtiketaStruktura');
  }

  updateMy(items: ProverbaEtiketaStruktura[]) {
    return this.apiService.put<ProverbaEtiketaStruktura[]>(`${this._endpointUrl}my`, { proverbaEtiketaStrukturaForCreationDTO: items });
  }

}
