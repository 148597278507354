import { BaseForCreationDTO } from '@core/interfaces/dto/creation/base-for-creation-dto.interface';
import { BaseForUpdateDTO } from '@core/interfaces/dto/update/base-for-update-dto.interface';
import { BaseParams } from '@core/interfaces/params/base-params.interface';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, map, Observable, skip, switchMap, tap } from 'rxjs';
import { BaseModel } from '../interfaces/models/base-model.interface';
import { ApiService } from '../services/api.service';
import { SingleBaseApi } from './single-base-api.class';

export class SingleStore<T1 extends BaseModel, T2 extends BaseParams, T3 extends BaseForCreationDTO, T4 extends BaseForUpdateDTO> extends SingleBaseApi<
  T1,
  T2,
  T3,
  T4
> {
  protected _store$: BehaviorSubject<{ value: T1, propagate: boolean } | null> = new BehaviorSubject<{ value: T1, propagate: boolean } | null>(null);
  protected _dataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _defaults: T1 | null;

  constructor(apiService: ApiService, baseUrl: string, defaults?: T1) {
    super(apiService, baseUrl);
    this._defaults = defaults ?? null;
  }

  protected get storeValue() {
    return this._store$.getValue()?.value;
  }
 
  load(params?: T2) {
    return super.get(params).pipe(
      tap((item) => {
        this._store$.next({ value: item ?? this._defaults, propagate: false });
        this._dataLoaded$.next(true);
      })
    );
  }

  getFromStore(propagateOnly: boolean = false): Observable<T1> {
    return this._dataLoaded$.pipe(
      switchMap((loaded) => {
        if (loaded) return this._store$.asObservable();
        throw "Can't call get before loading store.";
      }),
      filter((store) => propagateOnly ? store!.propagate : true),
      map((store) => {
        if (!store?.value && !this._defaults) throw `Single store has not loaded, and it doesn't have a default value. (${this._baseUrl})`;
        return (store?.value ?? this._defaults)!;
      })
    );
  }

  /**
   * Update only the defined items in the store
   * @param items
   */
  updateItems(items: Partial<T1>, propagate: boolean = true) {
    if (!this.storeValue && !this._defaults) {
      throw `Single store has not loaded, and it doesn't have a default value. (${this._baseUrl})`;
    }
    const of = JSON.parse(JSON.stringify(this.storeValue ?? this._defaults));
    const keys = Object.keys(items);
    if (keys.length == 0) {
      throw 'No keys defined.';
    }
    keys.forEach((key) => {
      (of as any)[key] = (items as any)[key];
    });
    this._store$.next({ value: of, propagate: propagate });
  }

  /**
   * Update changes from the store to the API
   * @param debounceTimer time to wait for next update in ms (defaults to 2000)
   * @param nrOfSkips number of skipped values in the store (defaults to 1)
   * @returns Observable of the model
   */
  updateOnChanges(debounceTimer: number = 2000, nrOfSkips: number = 1) {
    return this.dataLoaded$.pipe(
      filter((loaded) => loaded),
      switchMap(() => this._store$.asObservable()),
      skip(nrOfSkips),
      filter((item) => item != null && item.value != null && item.propagate),
      map((item) => item!.value),
      debounceTime(debounceTimer),
      switchMap((item) => {
        if (item.id != -1) return this.update(item as unknown as T4);
        return this.insert(item as unknown as T3);
      })
    );
  }

  updateStore(item: T1, propagate: boolean = true) {
    this._store$.next({ value: item, propagate: propagate });
  }

  listenOnChanges(fields: (keyof T1)[], distinct: boolean = true) {
    return this._store$.pipe(
      filter((store) => store !== null),
      map((store) => {
        const storeValue = store!.value;
        const returnObj: any = {};
        fields.forEach((field) => {
          returnObj[field] = storeValue![field];
        });
        return returnObj;
      }),
      distinctUntilChanged((a, b) => {
        if (!distinct) {
          return false;
        }
        let hasChanges = false;
        fields.forEach((field) => {
          if (JSON.stringify(a[field]) !== JSON.stringify(b[field])) {
            hasChanges = true;
            return;
          }
        });
        return !hasChanges;
      })
    );
  }

  listenOnFieldChanges(field: keyof T1, distinct: boolean = true) {
    return this._store$.pipe(
      filter((store) => store !== null),
      map((store) => {
        return store!.value[field] as any;
      }),
      distinctUntilChanged((a, b) => {
        return distinct ? JSON.stringify(a) === JSON.stringify(b) : false;
      })
    );
  }

  // override insert(item: T3) {
  //   return super.insert(item).pipe(
  //     tap((f) => {
  //       this.insertIntoStore(f);
  //     })
  //   );
  // }

  // override update(item: T4) {
  //   return super.update(item).pipe(
  //     tap((f) => {
  //       this.insertIntoStore(f);
  //     })
  //   )
  // }

  get dataLoaded$(): Observable<boolean> {
    return this._dataLoaded$.asObservable();
  }
}
