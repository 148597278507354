import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { FrazeologjiaFjalaPerzgjedhurForCreationDTO } from '@core/interfaces/dto/creation/frazeologjia-fjala-perzgjedhur-for-creation-dto.interface';
import { FrazeologjiaFjalaPerzgjedhurForUpdateDTO } from '@core/interfaces/dto/update/frazeologjia-fjala-perzgjedhur-for-update-dto.interface';
import { FrazeologjiaFjalaPerzgjedhur } from '@core/interfaces/models/frazeologjia-fjala-perzgjedhur.interface';
import { BaseParams } from '@core/interfaces/params/base-params.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class FrazeologjiaFjalaPerzgjedhurService extends Store<
  FrazeologjiaFjalaPerzgjedhur,
  BaseParams,
  FrazeologjiaFjalaPerzgjedhurForCreationDTO,
  FrazeologjiaFjalaPerzgjedhurForUpdateDTO
> {
  constructor(apiService: ApiService) {
    super(apiService, 'frazeologjiaFjalaPerzgjedhur/');
  }
}
