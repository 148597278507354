<h2 mat-dialog-title>{{ 'popup_are_you_sure' | phrase }}</h2>
<button class="close-dialog-button" mat-icon-button mat-dialog-title [mat-dialog-close]="null">
    <mat-icon>close</mat-icon>
</button>
<div mat-dialog-content>
  <p>{{ 'popup_change_word_save' | phrase }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button color="secondary" (click)="onNoClick()">{{ 'button_no' | phrase }}</button>
  <button mat-raised-button color="primary" (click)="onYesClick()" cdkFocusInitial>{{ 'button_yes' | phrase }}</button>
</div>