import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BroadcastService } from '@core/services/broadcast.service';
import { BurimiService } from '@core/services/burimi.service';
import { EtiketaStrukturaService } from '@core/services/etiketa-struktura.service';
import { EtiketaService } from '@core/services/etiketa.service';
import { FjalaGabimEtiketaStrukturaService } from '@core/services/fjala-gabim/fjala-gabim-etiketa-struktura.service';
import { FjalaGabimEtiketaService } from '@core/services/fjala-gabim/fjala-gabim-etiketa.service';
import { FjalaGabimFjalaPerzgjedhurStrukturaService } from '@core/services/fjala-gabim/fjala-gabim-fjala-perzgjedhur-struktura.service';
import { FjalaGabimFjalaPerzgjedhurService } from '@core/services/fjala-gabim/fjala-gabim-fjala-perzgjedhur.service';
import { FjalaGabimParacaktimiKerkimitService } from '@core/services/fjala-gabim/fjala-gabim-paracaktimi-kerkimit.service';
import { FjalaHuajEtiketaStrukturaService } from '@core/services/fjala-huaj/fjala-huaj-etiketa-struktura.service';
import { FjalaHuajEtiketaService } from '@core/services/fjala-huaj/fjala-huaj-etiketa.service';
import { FjalaHuajFjalaPerzgjedhurStrukturaService } from '@core/services/fjala-huaj/fjala-huaj-fjala-perzgjedhur-struktura.service';
import { FjalaHuajFjalaPerzgjedhurService } from '@core/services/fjala-huaj/fjala-huaj-fjala-perzgjedhur.service';
import { FjalaHuajParacaktimiKerkimitService } from '@core/services/fjala-huaj/fjala-huaj-paracaktimi-kerkimit.service';
import { FjalaPerzgjedhurStrukturaService } from '@core/services/fjala-perzgjedhur-struktura.service';
import { FjalaPerzgjedhurService } from '@core/services/fjala-perzgjedhur.service';
import { FjaloriStrukturaService } from '@core/services/fjalori-struktura.service';
import { FjaloriService } from '@core/services/fjalori.service';
import { FrazeologjiaEtiketaStrukturaService } from '@core/services/frazeologjia/frazeologjia-etiketa-struktura.service';
import { FrazeologjiaEtiketaService } from '@core/services/frazeologjia/frazeologjia-etiketa.service';
import { FrazeologjiaFjalaPerzgjedhurStrukturaService } from '@core/services/frazeologjia/frazeologjia-fjala-perzgjedhur-struktura.service';
import { FrazeologjiaFjalaPerzgjedhurService } from '@core/services/frazeologjia/frazeologjia-fjala-perzgjedhur.service';
import { FrazeologjiaParacaktimiKerkimitService } from '@core/services/frazeologjia/frazeologjia-paracaktimi-kerkimit.service';
import { FushaPerdorimitService } from '@core/services/fusha-perdorimit.service';
import { NjoftimiService } from '@core/services/njoftimi.service';
import { OperatoriFaqjaService } from '@core/services/operatori-faqja.service';
import { OperatoriService } from '@core/services/operatori.service';
import { ParacaktimiKerkimitService } from '@core/services/paracaktimi-kerkimit.service';
import { ProverbaEtiketaStrukturaService } from '@core/services/proverba/proverba-etiketa-struktura.service';
import { ProverbaEtiketaService } from '@core/services/proverba/proverba-etiketa.service';
import { ProverbaFjalaPerzgjedhurStrukturaService } from '@core/services/proverba/proverba-fjala-perzgjedhur-struktura.service';
import { ProverbaFjalaPerzgjedhurService } from '@core/services/proverba/proverba-fjala-perzgjedhur.service';
import { ProverbaParacaktimiKerkimitService } from '@core/services/proverba/proverba-paracaktimi-kerkimit.service';
import { QasjaService } from '@core/services/qasja.service';
import { QueryBuilderService } from '@core/services/query-builder.service';
import { ShembulliEtiketaStrukturaService } from '@core/services/shembulli/shembulli-etiketa-struktura.service';
import { ShembulliEtiketaService } from '@core/services/shembulli/shembulli-etiketa.service';
import { ShembulliFjalaPerzgjedhurStrukturaService } from '@core/services/shembulli/shembulli-fjala-perzgjedhur-struktura.service';
import { ShembulliFjalaPerzgjedhurService } from '@core/services/shembulli/shembulli-fjala-perzgjedhur.service';
import { ShembulliParacaktimiKerkimitService } from '@core/services/shembulli/shembulli-paracaktimi-kerkimit.service';
import { LeksikografiaHubService } from '@core/services/signalr/leksikografia-hub.service';
import { StrukturaService } from '@core/services/struktura.service';
import { catchError, distinctUntilChanged, filter, forkJoin, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private leksikografiaHubService: LeksikografiaHubService,
    private fjaloriService: FjaloriService,
    private fjaloriStrukturaService: FjaloriStrukturaService,
    private burimiService: BurimiService,
    private strukturaService: StrukturaService,
    private operatoriFaqjaService: OperatoriFaqjaService,
    private qasjaService: QasjaService,
    private fjalaPerzgjedhurService: FjalaPerzgjedhurService,
    private fjalaPerzgjedhurStrukturaService: FjalaPerzgjedhurStrukturaService,
    private paracaktimiKerkimitService: ParacaktimiKerkimitService,
    private fushaPerdorimitService: FushaPerdorimitService,
    private etiketaService: EtiketaService,
    private etiketaStrukturaService: EtiketaStrukturaService,

    private shembulliParacaktimiKerkimitService: ShembulliParacaktimiKerkimitService,
    private shembulliFjalaPerzgjedhurService: ShembulliFjalaPerzgjedhurService,
    private shembulliFjalaPerzgjedhurStrukturaService: ShembulliFjalaPerzgjedhurStrukturaService,
    private shembulliEtiketaService: ShembulliEtiketaService,
    private shembulliEtiketaStrukturaService: ShembulliEtiketaStrukturaService,

    private proverbaParacaktimiKerkimitService: ProverbaParacaktimiKerkimitService,
    private proverbaFjalaPerzgjedhurService: ProverbaFjalaPerzgjedhurService,
    private proverbaFjalaPerzgjedhurStrukturaService: ProverbaFjalaPerzgjedhurStrukturaService,
    private proverbaEtiketaService: ProverbaEtiketaService,
    private proverbaEtiketaStrukturaService: ProverbaEtiketaStrukturaService,

    private frazeologjiaParacaktimiKerkimitService: FrazeologjiaParacaktimiKerkimitService,
    private frazeologjiaFjalaPerzgjedhurService: FrazeologjiaFjalaPerzgjedhurService,
    private frazeologjiaFjalaPerzgjedhurStrukturaService: FrazeologjiaFjalaPerzgjedhurStrukturaService,
    private frazeologjiaEtiketaService: FrazeologjiaEtiketaService,
    private frazeologjiaEtiketaStrukturaService: FrazeologjiaEtiketaStrukturaService,

    private fjalaHuajParacaktimiKerkimitService: FjalaHuajParacaktimiKerkimitService,
    private fjalaHuajFjalaPerzgjedhurService: FjalaHuajFjalaPerzgjedhurService,
    private fjalaHuajFjalaPerzgjedhurStrukturaService: FjalaHuajFjalaPerzgjedhurStrukturaService,
    private fjalaHuajEtiketaService: FjalaHuajEtiketaService,
    private fjalaHuajEtiketaStrukturaService: FjalaHuajEtiketaStrukturaService,

    private fjalaGabimParacaktimiKerkimitService: FjalaGabimParacaktimiKerkimitService,
    private fjalaGabimFjalaPerzgjedhurService: FjalaGabimFjalaPerzgjedhurService,
    private fjalaGabimFjalaPerzgjedhurStrukturaService: FjalaGabimFjalaPerzgjedhurStrukturaService,
    private fjalaGabimEtiketaService: FjalaGabimEtiketaService,
    private fjalaGabimEtiketaStrukturaService: FjalaGabimEtiketaStrukturaService,

    private queryBuilderService: QueryBuilderService,
    private njoftimiService: NjoftimiService,
    private operatoriService: OperatoriService,

    private broadcastService: BroadcastService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin(state.url);
  }

  private checkLogin(url?: string) {
    return this.authService.isDoneLoading$.pipe(
      filter((isDone) => isDone),
      switchMap((_) => this.authService.isAuthenticated$),
      distinctUntilChanged(),
      switchMap((isAuthenticated) => {
        if (isAuthenticated) {
          if (this.authService.hasLinguistPermission()) {
            return this.dataInitializor();
          } else {
            this.router.navigateByUrl('/unauthorized');
          }
        } else {
          this.authService.login(url);
        }
        return of(false);
      })
    );
  }

  private dataInitializor() {
    return forkJoin([
      this.leksikografiaHubService.initializeSignalR(),
      this.fjaloriService.load(),
      this.fjaloriStrukturaService.load(),
      this.burimiService.load(),
      this.strukturaService.load(),
      this.operatoriFaqjaService.load(),
      this.qasjaService.load(),
      this.fjalaPerzgjedhurService.load(),
      this.fjalaPerzgjedhurStrukturaService.load(),
      this.paracaktimiKerkimitService.load(),
      this.fushaPerdorimitService.load(),
      this.etiketaService.load(),
      this.etiketaStrukturaService.load(),
      this.shembulliParacaktimiKerkimitService.load(),
      this.shembulliFjalaPerzgjedhurService.load(),
      this.shembulliFjalaPerzgjedhurStrukturaService.load(),
      this.shembulliEtiketaService.load(),
      this.shembulliEtiketaStrukturaService.load(),
      this.proverbaParacaktimiKerkimitService.load(),
      this.proverbaFjalaPerzgjedhurService.load(),
      this.proverbaFjalaPerzgjedhurStrukturaService.load(),
      this.proverbaEtiketaService.load(),
      this.proverbaEtiketaStrukturaService.load(),
      this.frazeologjiaParacaktimiKerkimitService.load(),
      this.frazeologjiaFjalaPerzgjedhurService.load(),
      this.frazeologjiaFjalaPerzgjedhurStrukturaService.load(),
      this.frazeologjiaEtiketaService.load(),
      this.frazeologjiaEtiketaStrukturaService.load(),
      this.fjalaHuajParacaktimiKerkimitService.load(),
      this.fjalaHuajFjalaPerzgjedhurService.load(),
      this.fjalaHuajFjalaPerzgjedhurStrukturaService.load(),
      this.fjalaHuajEtiketaService.load(),
      this.fjalaHuajEtiketaStrukturaService.load(),
      this.fjalaGabimParacaktimiKerkimitService.load(),
      this.fjalaGabimFjalaPerzgjedhurService.load(),
      this.fjalaGabimFjalaPerzgjedhurStrukturaService.load(),
      this.fjalaGabimEtiketaService.load(),
      this.fjalaGabimEtiketaStrukturaService.load(),
      this.njoftimiService.load(),
      this.operatoriService.load()
      //preload all necessary data into the store on load
    ]).pipe(
      switchMap(() => this.queryBuilderService.load()),
      tap(() => {
        this.broadcastService.initialize();
      }),
      map(() => true),
      catchError((err) => {
        if (err?.status == 403) {
          this.router.navigateByUrl('/forbidden');
        }
        return throwError(err);
      })
    );
  }
}
