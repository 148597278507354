import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { Component, AfterViewInit, ElementRef, Renderer2, Inject } from '@angular/core';

@Component({
  selector: 'app-resizable-drag-drop',
  template: '',
})
export class ResizableDragDropComponent implements AfterViewInit {
  dialogInfo: { width: string; height: string; x: number; y: number } = { width: '', height: '', x: 0, y: 0 };

  protected itemName: string;

  constructor(private h: ElementRef, private r: Renderer2, @Inject(String) private cn: string) {
    this.itemName = cn;
    let tr = localStorage.getItem(this.itemName);
    if (tr) {
      this.dialogInfo = JSON.parse(tr);
    }
  }

  ngAfterViewInit(): void {
    if (this.dialogInfo.width != '' && this.dialogInfo.height != '') {
      this.r.setStyle(this.h.nativeElement.parentElement, 'width', this.dialogInfo.width);
      this.r.setStyle(this.h.nativeElement.parentElement, 'height', this.dialogInfo.height);
    }
  }

  resetSize() {
    this.r.removeStyle(this.h.nativeElement.parentElement, 'width');
    this.r.removeStyle(this.h.nativeElement.parentElement, 'height');
    this.r.removeStyle(this.h.nativeElement.parentElement.parentElement, 'transform');
    this.dialogInfo.width = '';
    this.dialogInfo.height = '';
    this.dialogInfo.x = 0;
    this.dialogInfo.y = 0;

    localStorage.removeItem(this.itemName);
  }

  close(): void {
    const styleChanged =
      this.h.nativeElement.parentElement.style.width != '' ||
      this.h.nativeElement.parentElement.style.height != '' ||
      (this.h.nativeElement.parentElement.parentElement.style.transform != 'translate3d(0px, 0px, 0px)' &&
        this.h.nativeElement.parentElement.parentElement.style.transform != '');

    if (styleChanged) {
      this.dialogInfo.height = this.h.nativeElement.offsetParent.offsetHeight + 'px';
      this.dialogInfo.width = this.h.nativeElement.offsetParent.offsetWidth + 'px';

      localStorage.setItem(this.itemName, JSON.stringify(this.dialogInfo));
    }
  }

  dragEnd($event: CdkDragEnd) {
    this.dialogInfo.x = $event.source.getFreeDragPosition().x;
    this.dialogInfo.y = $event.source.getFreeDragPosition().y;
  }
}
