import { Injectable } from '@angular/core';
import { StrukturaStore } from '@core/classes/struktura-store.class';
import { FjalaHuajEtiketaStruktura } from '@core/interfaces/models/fjala-huaj-etiketa-struktura.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class FjalaHuajEtiketaStrukturaService extends StrukturaStore<FjalaHuajEtiketaStruktura> {

  constructor(apiService: ApiService) {
    super(apiService, 'fjalaHuajEtiketaStruktura');
  }

  updateMy(items: FjalaHuajEtiketaStruktura[]) {
    return this.apiService.put<FjalaHuajEtiketaStruktura[]>(`${this._endpointUrl}my`, { fjalaHuajEtiketaStrukturaForCreationDTO: items });
  }

}
