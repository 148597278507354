export enum PermissionName {
  Fjalori="Fjalori",
  DefaultFjalori="DefaultFjalori",
  Fjala="Fjala",
  FjalaFjalori="FjalaFjalori",
  DefaultFjalaFjalori="DefaultFjalaFjalori",
  FjalaEtiketa="FjalaEtiketa",
  DefaultFjalaEtiketa="DefaultFjalaEtiketa",
  FjalaFjalaPerzgjedhur="FjalaFjalaPerzgjedhur",
  DefaultFjalaFjalaPerzgjedhur="DefaultFjalaFjalaPerzgjedhur",
  FjalaBurimi="FjalaBurimi",
  DefaultFjalaBurimi="DefaultFjalaBurimi",
  Shembulli="Shembulli",
  ShembulliFjalori="ShembulliFjalori",
  DefaultShembulliFjalori="DefaultShembulliFjalori",
  ShembulliEtiketa="ShembulliEtiketa",
  DefaultShembulliEtiketa="DefaultShembulliEtiketa",
  ShembulliFjalaPerzgjedhur="ShembulliFjalaPerzgjedhur",
  DefaultShembulliFjalaPerzgjedhur="DefaultShembulliFjalaPerzgjedhur",
  ShembulliBurimi="ShembulliBurimi",
  DefaultShembulliBurimi="DefaultShembulliBurimi",
  Frazeologjia="Frazeologjia",
  FrazeologjiaFjalori="FrazeologjiaFjalori",
  DefaultFrazeologjiaFjalori="DefaultFrazeologjiaFjalori",
  FrazeologjiaEtiketa="FrazeologjiaEtiketa",
  DefaultFrazeologjiaEtiketa="DefaultFrazeologjiaEtiketa",
  FrazeologjiaFjalaPerzgjedhur="FrazeologjiaFjalaPerzgjedhur",
  DefaultFrazeologjiaFjalaPerzgjedhur="DefaultFrazeologjiaFjalaPerzgjedhur",
  FrazeologjiaBurimi="FrazeologjiaBurimi",
  DefaultFrazeologjiaBurimi="DefaultFrazeologjiaBurimi",
  Proverba="Proverba",
  ProverbaFjalori="ProverbaFjalori",
  DefaultProverbaFjalori="DefaultProverbaFjalori",
  ProverbaEtiketa="ProverbaEtiketa",
  DefaultProverbaEtiketa="DefaultProverbaEtiketa",
  ProverbaFjalaPerzgjedhur="ProverbaFjalaPerzgjedhur",
  DefaultProverbaFjalaPerzgjedhur="DefaultProverbaFjalaPerzgjedhur",
  ProverbaBurimi="ProverbaBurimi",
  DefaultProverbaBurimi="DefaultProverbaBurimi",
  FjalaHuaj="FjalaHuaj",
  FjalaHuajFjalori="FjalaHuajFjalori",
  DefaultFjalaHuajFjalori="DefaultFjalaHuajFjalori",
  FjalaHuajEtiketa="FjalaHuajEtiketa",
  DefaultFjalaHuajEtiketa="DefaultFjalaHuajEtiketa",
  FjalaHuajFjalaPerzgjedhur="FjalaHuajFjalaPerzgjedhur",
  DefaultFjalaHuajFjalaPerzgjedhur="DefaultFjalaHuajFjalaPerzgjedhur",
  FjalaHuajBurimi="FjalaHuajBurimi",
  DefaultFjalaHuajBurimi="DefaultFjalaHuajBurimi",
  FjalaGabim="FjalaGabim",
  FjalaGabimFjalori="FjalaGabimFjalori",
  DefaultFjalaGabimFjalori="DefaultFjalaGabimFjalori",
  FjalaGabimEtiketa="FjalaGabimEtiketa",
  DefaultFjalaGabimEtiketa="DefaultFjalaGabimEtiketa",
  FjalaGabimFjalaPerzgjedhur="FjalaGabimFjalaPerzgjedhur",
  DefaultFjalaGabimFjalaPerzgjedhur="DefaultFjalaGabimFjalaPerzgjedhur",
  FjalaGabimBurimi="FjalaGabimBurimi",
  DefaultFjalaGabimBurimi="DefaultFjalaGabimBurimi",
  FjalaPerzier="FjalaPerzier",
  FjalaPerzierFjalori="FjalaPerzierFjalori",
  DefaultFjalaPerzierFjalori="DefaultFjalaPerzierFjalori",
  FjalaPerzierEtiketa="FjalaPerzierEtiketa",
  DefaultFjalaPerzierEtiketa="DefaultFjalaPerzierEtiketa",
  FjalaPerzierFjalaPerzgjedhur="FjalaPerzierFjalaPerzgjedhur",
  DefaultFjalaPerzierFjalaPerzgjedhur="DefaultFjalaPerzierFjalaPerzgjedhur",
  FjalaPerzierBurimi="FjalaPerzierBurimi",
  DefaultFjalaPerzierBurimi="DefaultFjalaPerzierBurimi",
}