import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsSentenceProcessorComponent } from './as-sentence-processor.component';
import { AsSentenceProcessorUploadComponent } from './as-sentence-processor-upload/as-sentence-processor-upload.component';
import { AsSentenceProcessorSearchComponent } from './as-sentence-processor-search/as-sentence-processor-search.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AsSearchAndReplaceModule } from '../as-search-and-replace/as-search-and-replace.module';
import { AsSentenceProcessorService } from './as-sentence-processor.service';
import { MatDividerModule } from '@angular/material/divider';
import { PhraseModule } from '../phrase/phrase.module';

@NgModule({
  declarations: [AsSentenceProcessorComponent, AsSentenceProcessorUploadComponent, AsSentenceProcessorSearchComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatTabsModule,
    MatIconModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatRippleModule,
    DragDropModule,
    MatSlideToggleModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    ClipboardModule,
    MatDividerModule,
    AsSearchAndReplaceModule,
    PhraseModule
  ],
  providers: [],
})
export class AsSentenceProcessorModule {}
