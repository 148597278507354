<div class="wrapper">
  <div class="upload">
      <div class="info">
          <div class="uploading" *ngIf="uploadFileName" [@uploadInfo]>
              <span class="fileName">{{ uploadFileName }}</span>
              <mat-progress-bar
                  #progressBar
                  [ngClass]="uploadMessage"
                  [mode]="progress == 100 && uploading ? 'indeterminate' : 'determinate'"
                  *ngIf="progress != 0"
                  [value]="progress"
              >
              </mat-progress-bar>
          </div>
      </div>
      <button mat-raised-button color="primary" type="button" (click)="file.click()" [disabled]="uploading">
          {{ 'button_upload_file' | phrase }}
      </button>
      <input
          class="fileUploadInput"
          style="display: none"
          type="file"
          (change)="onFileChange($event)"
          accept=".doc,.docx,.pdf"
          #file
      />
  </div>

  <div class="content">
      <ng-container *ngIf="!loading">
          <mat-table [dataSource]="dataSource">
              <!-- ID Column -->
              <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef>{{ 'id' | phrase }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.fileID }}</mat-cell>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef>{{ 'name' | phrase }}</mat-header-cell>
                  <mat-cell *matCellDef="let element">{{ element.fileName }}</mat-cell>
              </ng-container>

              <!-- Type Column -->
              <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef>{{ 'type' | phrase }}</mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{ element.fileType.fileTypeName }} </mat-cell>
              </ng-container>

              <!-- Words Column -->
              <ng-container matColumnDef="words">
                  <mat-header-cell *matHeaderCellDef>{{ 'number_of_sentences' | phrase }}</mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{ element.totalLineNumber }} </mat-cell>
              </ng-container>

              <!-- Date Column -->
              <ng-container matColumnDef="date">
                  <mat-header-cell *matHeaderCellDef>{{ 'date' | phrase }}</mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{ element.insertDate | date: 'dd.MM.yyyy' }} </mat-cell>
              </ng-container>

              <!-- Delete Column -->
              <ng-container matColumnDef="delete">
                  <mat-header-cell *matHeaderCellDef style="max-width: 50px"></mat-header-cell>
                  <mat-cell *matCellDef="let element" style="max-width: 50px">
                      <button
                          *ngIf="isAdmin"
                          mat-icon-button
                          class="delete"
                          (click)="deleteElement(element.fileID, element.fileName)"
                      >
                          <mat-icon>delete</mat-icon>
                      </button>
                  </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row
                  *matRowDef="let row; columns: displayedColumns"
                  [@fadeOut]="row.fileID == animatedRowId ? 'animState' : 'normalState'"
              ></mat-row>
          </mat-table>
      </ng-container>
      <ng-container *ngIf="loading">
          <div class="loading">
              <mat-spinner [diameter]="40"></mat-spinner>
          </div>
      </ng-container>
  </div>
  <mat-paginator #paginator [length]="count" [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 50, 100]"></mat-paginator>
</div>
