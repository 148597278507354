import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { Defaults } from '@core/globals/defaults';
import { StrukturaForCreationDTO } from '@core/interfaces/dto/creation/struktura-for-creation-dto.interface';
import { StrukturaForUpdateDTO } from '@core/interfaces/dto/update/struktura-for-update-dto.interface';
import { Struktura } from '@core/interfaces/models/struktura.interface';
import { StrukturaParams } from '@core/interfaces/params/struktura-params.interface';
import { map, Observable, tap } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class StrukturaService extends Store<Struktura, StrukturaParams, StrukturaForCreationDTO, StrukturaForUpdateDTO> {
  constructor(apiService: ApiService) {
    super(apiService, 'struktura');
  }

  override load(params?: StrukturaParams): Observable<Struktura[]> {
    return super.get(params).pipe(
      map((items) => items.sort((a, b) => a.radhitja - b.radhitja)),
      tap((items) => {
        this._store$.next(items);
        this._dataLoaded$.next(true);
      })
    );
  }

  override getFromStore(): Observable<Struktura[]> {
    return super.getFromStore().pipe(
      map((struktura) => {
        return struktura.length > 0 ? struktura : Defaults.Struktura;
      })
    );
  }

  updateMultiple(updateDto: StrukturaForUpdateDTO) {
    return this.apiService.put<Struktura[]>(`${this._endpointUrl}`, updateDto).pipe(
      map((items) => items.sort((a, b) => a.radhitja - b.radhitja)),
      tap((items) => {
        this._store$.next(items);
      })
    )
  }

  // getFromStoreOrDefault() {
  //   return this.getFromStore().pipe(
  //     map((struktura) => {
  //       return struktura.length > 0 ? struktura : Defaults.Struktura;
  //     })
  //   )
  // }
}
