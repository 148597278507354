import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@core/auth/auth.service';
import { PerdoruesiStatusi } from '@core/enums/perdoruesi-statusi.enum';
import { Perdoruesi } from '@core/interfaces/signalr/perdoruesi.interface';
import { OperatoriFaqjaService } from '@core/services/operatori-faqja.service';
import { LeksikografiaHubService } from '@core/services/signalr/leksikografia-hub.service';
import { distinctUntilChanged, filter, map, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { PerdoruesiInfoComponent } from './perdoruesi-info/perdoruesi-info.component';

@Component({
  selector: 'app-perdoruesit',
  templateUrl: './perdoruesit.component.html',
  styleUrls: ['./perdoruesit.component.scss'],
  animations: [
    trigger('perdoruesiAnim', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', 'margin-left': '0px' }),
        animate('0.2s ease-in-out', style({ transform: 'translateX(0)', 'margin-left': '0px' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', width: '*' }),
        animate('0.2s ease-in-out', style({ transform: 'translateX(100%)', width: '0' })),
      ]),
    ]),
  ],
})
export class PerdoruesitComponent implements OnInit, OnDestroy {
  perdoruesit: Perdoruesi[] = [];
  self?: Perdoruesi;

  PerdoruesiStatusi = PerdoruesiStatusi;
  perdoruesiStatusi: PerdoruesiStatusi = PerdoruesiStatusi.Online;

  userActivity: any | undefined;

  connected: boolean = false;

  private _userInactive$: Subject<void> = new Subject();
  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private authService: AuthService,
    private operatoriFaqjaService: OperatoriFaqjaService,
    private leksikografiaHubService: LeksikografiaHubService,
    private matDialog: MatDialog
  ) {}

  setTimeout() {
    this.userActivity = setTimeout(() => {
      this._userInactive$.next()
    }, 600000);
  }

  refreshUserState() {
    if (this.perdoruesiStatusi == PerdoruesiStatusi.Away) {
      this.perdoruesiStatusi = PerdoruesiStatusi.Online;
      this.leksikografiaHubService.statusiUpdate(PerdoruesiStatusi.Online);
    }
    clearTimeout(this.userActivity!);
    this.setTimeout();
  }

  ngOnInit(): void {
    this.operatoriFaqjaService.listenOnFieldChanges('bazaId').pipe(
      distinctUntilChanged(),
      takeUntil(this._onDestroy$)
    ).subscribe(() => {
      this.refreshUserState();
    });

    this.setTimeout();
    this._userInactive$
      .pipe(
        withLatestFrom(this.leksikografiaHubService.getConnectionEstablished()),
        filter(([a, b]) => b === true),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => {
        this.leksikografiaHubService.statusiUpdate(PerdoruesiStatusi.Away);
        this.perdoruesiStatusi = PerdoruesiStatusi.Away;
      });

    this.leksikografiaHubService
      .getPerdoruesit()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((perdoruesit) => {
        this.self = perdoruesit.find((p) => p.operatoriId == this.authService.userId);
        this.perdoruesit = perdoruesit.filter((p) => p.operatoriId != this.authService.userId);
      });

    this.leksikografiaHubService
      .getConnectionEstablished()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((connected) => {
        this.connected = connected;
      });
  }

  openPerdoruesiInfo(operatoriId: string) {
    if (!this.authService.hasAdminPermission()) return;
    this.matDialog.open(PerdoruesiInfoComponent, {
      width: '500px',
      data: {
        operatoriId: operatoriId,
      },
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    window.removeEventListener('mousedown', this.refreshUserState);
  }
}
