import { Injectable } from '@angular/core';
import { BaseApi } from '@core/classes/base-api.class';
import { ProverbaForCreationDTO } from '@core/interfaces/dto/creation/proverba-for-creation-dto.interface';
import { ProverbaForUpdateDTO } from '@core/interfaces/dto/update/proverba-for-update-dto.interface';
import { Proverba } from '@core/interfaces/models/proverba.interface';
import { ProverbaParams } from '@core/interfaces/params/proverba-params.interface';
import { BehaviorSubject, filter, map } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ProverbaService extends BaseApi<Proverba, ProverbaParams, ProverbaForCreationDTO, ProverbaForUpdateDTO> {

  private _proverbaList$: BehaviorSubject<{ list: Proverba[], propagate: boolean } | undefined> = new BehaviorSubject<{ list: Proverba[], propagate: boolean } | undefined>(undefined);
  private _loadedFjaloriId$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  constructor(apiService: ApiService) {
    super(apiService, 'proverba');
  }

  getProverbaList(propagateOnly: boolean = true) {
    return this._proverbaList$.asObservable().pipe(
      filter((value) => (propagateOnly ? value?.propagate! : true)),
      map((value) => {
        return value ? value.list : undefined;
      })
    );
  }

  resetProverbaList() {
    this._proverbaList$.next(undefined);
  }

  setProverbaList(list: Proverba[], propagate: boolean = true) {
    this._proverbaList$.next({ list: list, propagate: propagate });
  }

  resetLoadedFjaloriId() {
    this._loadedFjaloriId$.next(undefined);
  }

  getLoadedFjaloriId() {
    return this._loadedFjaloriId$.asObservable();
  }

  setLoadedFjaloriId(fjaloriId: number) {
    this._loadedFjaloriId$.next(fjaloriId);
  }

}
