import { Injectable } from '@angular/core';
import { StrukturaStore } from '@core/classes/struktura-store.class';
import { FjalaGabimEtiketaStruktura } from '@core/interfaces/models/fjala-gabim-etiketa-struktura.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class FjalaGabimEtiketaStrukturaService extends StrukturaStore<FjalaGabimEtiketaStruktura> {

  constructor(apiService: ApiService) {
    super(apiService, 'fjalaGabimEtiketaStruktura');
  }

  updateMy(items: FjalaGabimEtiketaStruktura[]) {
    return this.apiService.put<FjalaGabimEtiketaStruktura[]>(`${this._endpointUrl}my`, { fjalaGabimEtiketaStrukturaForCreationDTO: items });
  }

}
