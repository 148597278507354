import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PermissionName } from '@core/enums/permissions/permission-name.enum';
import { PermissionType } from '@core/enums/permissions/permission-type.enum';
import { OperatoriFaqjaService } from '@core/services/operatori-faqja.service';
import { QasjaService } from '@core/services/qasja.service';
import { FjalaGabimEditoriService } from '@core/services/fjala-gabim/fjala-gabim-editori.service';
import { FjalaGabimNavbarService } from '@core/services/fjala-gabim/fjala-gabim-navbar.service';
import { Subject, takeUntil, debounceTime, switchMap, combineLatest, distinctUntilChanged, of, pairwise } from 'rxjs';
import { ShenimetPopupComponent } from '../shenimet-popup/shenimet-popup.component';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-fjala-gabim-nav-buttons',
  templateUrl: './fjala-gabim-nav-buttons.component.html',
  styleUrls: ['./fjala-gabim-nav-buttons.component.scss'],
})
export class FjalaGabimNavButtonsComponent implements OnInit, OnDestroy {
  formDirty: boolean = false;
  formValid: boolean = false;

  
  prevFjalaGabimId: number | null = null;
  fjalaGabimId: number | null = null;
  nextFjalaGabimId: number | null = null;
  
  jumpFjalaGabimId: number | null = null;
  
  canUndo: boolean = false;
  canRedo: boolean = false;

  saving: boolean = false;
  
  canDelete: boolean = false;

  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private fjalaGabimEditorService: FjalaGabimEditoriService,
    private fjalaGabimNavbarService: FjalaGabimNavbarService,
    private operatoriFaqjaService: OperatoriFaqjaService,
    private matDialog: MatDialog,
    private qasjaService: QasjaService
  ) {}

  ngOnInit(): void {
    this.fjalaGabimEditorService.editoriForm.valueChanges.pipe(debounceTime(0), takeUntil(this._onDestroy$)).subscribe(() => {
      this.formDirty = this.fjalaGabimEditorService.editoriForm.dirty;
      this.formValid = this.fjalaGabimEditorService.editoriForm.valid;
    });

    combineLatest([
      this.fjalaGabimEditorService.editoriForm.controls.id.valueChanges.pipe(distinctUntilChanged()),
      this.fjalaGabimEditorService.editoriForm.controls.fjaloriId.valueChanges.pipe(distinctUntilChanged()),
      this.fjalaGabimEditorService.editoriForm.controls.burimiId.valueChanges.pipe(distinctUntilChanged()),
      this.fjalaGabimEditorService.getSaving()
    ]).pipe(
      debounceTime(0),
      switchMap(([fjalaGabimId, fjaloriId, burimiId, saving]) => {
        if (fjalaGabimId != null && !saving) {
          return this.qasjaService.hasQasjet([
            { emri: PermissionName.FjalaGabimFjalori, tipi: PermissionType.Delete, vlera: fjaloriId?.toString() },
            { emri: PermissionName.FjalaGabimBurimi, tipi: PermissionType.View, vlera: burimiId?.toString() },
          ])
        }
        return of(false);
      }),
      takeUntil(this._onDestroy$)
    ).subscribe((canDelete) => {
      this.canDelete = canDelete;
    })

    this.operatoriFaqjaService
      .listenOnFieldChanges('activeFjalaGabimId')
      .pipe(pairwise(), takeUntil(this._onDestroy$))
      .subscribe(([prev, next]) => {
        if (prev != null && next != null) {
          this.jumpFjalaGabimId = prev;
        } else {
          this.jumpFjalaGabimId = null;
        }
      });

    this.fjalaGabimEditorService
      .canUndo()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((canUndo) => {
        this.canUndo = canUndo;
      });

    this.fjalaGabimEditorService
      .canRedo()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((canRedo) => {
        this.canRedo = canRedo;
      });

    this.fjalaGabimNavbarService
      .getNextFjalaGabim()
      .pipe(debounceTime(0), takeUntil(this._onDestroy$))
      .subscribe((id) => {
        this.nextFjalaGabimId = id;
      });

    this.fjalaGabimNavbarService
      .getPrevFjalaGabim()
      .pipe(debounceTime(0), takeUntil(this._onDestroy$))
      .subscribe((id) => {
        this.prevFjalaGabimId = id;
      });

    this.operatoriFaqjaService
      .listenOnFieldChanges('activeFjalaGabimId')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((fjalaGabimId) => {
        this.fjalaGabimId = fjalaGabimId;
      });

    this.fjalaGabimEditorService
      .getSaving()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((saving) => {
        this.saving = saving;
      });
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
      if(this.nextFjalaGabimId != null)
        this.onSaveAndNextClick();
      else
        this.onSaveClick();
      event.preventDefault();
    }
  }
  
  jump() {
    if (this.jumpFjalaGabimId && !this.saving) {
      if(this.formDirty) {
        this.fjalaGabimEditorService.getCanChange().subscribe((canChange) => {
          if(canChange) {
            this.operatoriFaqjaService.updateItems({ activeFjalaGabimId: this.jumpFjalaGabimId });
          }
        });
      } else {
        this.operatoriFaqjaService.updateItems({ activeFjalaGabimId: this.jumpFjalaGabimId });
      }
    }
  }

  undo() {
    if (this.canUndo) this.fjalaGabimEditorService.undo();
  }

  redo() {
    if (this.canRedo) this.fjalaGabimEditorService.redo();
  }

  onEditorClearClick() {
    if(!this.saving) this.fjalaGabimNavbarService.triggerOnEditorClearClick();
  }

  onDeleteClick() {
    if(this.canDelete && !this.saving) this.fjalaGabimNavbarService.triggerDeleteClick();
  }

  onPrevClick() {
    if (this.prevFjalaGabimId && !this.saving) this.fjalaGabimNavbarService.triggerPrevClick();
  }

  onSaveAndPrevClick() {
    if (this.formDirty && this.formValid && this.prevFjalaGabimId && !this.saving) this.fjalaGabimNavbarService.triggerSaveAndPrevClick();
  }

  onSaveClick() {
    if (this.formDirty && this.formValid && !this.saving) this.fjalaGabimNavbarService.triggerSaveClick();
  }

  onSaveAndNextClick() {
    if (this.formDirty && this.formValid && this.nextFjalaGabimId && !this.saving) this.fjalaGabimNavbarService.triggerSaveAndNextClick();
  }

  onNextClick() {
    if (this.nextFjalaGabimId && !this.saving) this.fjalaGabimNavbarService.triggerNextClick();
  }

  onCommentsClick() {
    if(this.fjalaGabimId) this.fjalaGabimNavbarService.triggerCommentsClick();
  }

  openShenimet() {
    this.matDialog.open(ShenimetPopupComponent, {
      disableClose: true,
      panelClass: 'big-resizable-dialog',
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
