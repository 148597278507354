import '@core/extensions/string.extensions';
import '@core/extensions/form-group.extension';

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@core/auth/auth.service';
import { FrazatService } from '@core/services/frazat.service';
import { filter, Subject, switchMap, takeUntil, distinctUntilChanged } from 'rxjs';
import { OperatoriFaqjaService } from '@core/services/operatori-faqja.service';
import { FjalaPerzgjedhurStrukturaService } from '@core/services/fjala-perzgjedhur-struktura.service';
import { FjaloriStrukturaService } from '@core/services/fjalori-struktura.service';
import { EtiketaStrukturaService } from '@core/services/etiketa-struktura.service';
import { navigationBar } from '@core/constants/animations';
import { ShembulliFjalaPerzgjedhurStrukturaService } from '@core/services/shembulli/shembulli-fjala-perzgjedhur-struktura.service';
import { ShembulliEtiketaStrukturaService } from '@core/services/shembulli/shembulli-etiketa-struktura.service';
import { ProverbaFjalaPerzgjedhurStrukturaService } from '@core/services/proverba/proverba-fjala-perzgjedhur-struktura.service';
import { ProverbaEtiketaStrukturaService } from '@core/services/proverba/proverba-etiketa-struktura.service';
import { FrazeologjiaFjalaPerzgjedhurStrukturaService } from '@core/services/frazeologjia/frazeologjia-fjala-perzgjedhur-struktura.service';
import { FrazeologjiaEtiketaStrukturaService } from '@core/services/frazeologjia/frazeologjia-etiketa-struktura.service';
import { FjalaHuajFjalaPerzgjedhurStrukturaService } from '@core/services/fjala-huaj/fjala-huaj-fjala-perzgjedhur-struktura.service';
import { FjalaHuajEtiketaStrukturaService } from '@core/services/fjala-huaj/fjala-huaj-etiketa-struktura.service';
import { FjalaGabimEtiketaStrukturaService } from '@core/services/fjala-gabim/fjala-gabim-etiketa-struktura.service';
import { FjalaGabimFjalaPerzgjedhurStrukturaService } from '@core/services/fjala-gabim/fjala-gabim-fjala-perzgjedhur-struktura.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [navigationBar],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean = false;
  navDictionarySelected: boolean = false;

  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    private frazatService: FrazatService,
    private operatoriFaqjaService: OperatoriFaqjaService,
    private fjaloriStrukturaService: FjaloriStrukturaService,
    private fjalaPerzgjedhurStrukturaService: FjalaPerzgjedhurStrukturaService,
    private etiketaStrukturaService: EtiketaStrukturaService,
    private shembulliFjalaPerzgjedhurStrukturaService: ShembulliFjalaPerzgjedhurStrukturaService,
    private shembulliEtiketaStrukturaService: ShembulliEtiketaStrukturaService,
    private proverbaFjalaPerzgjedhurStrukturaService: ProverbaFjalaPerzgjedhurStrukturaService,
    private proverbaEtiketaStrukturaService: ProverbaEtiketaStrukturaService,
    private frazeologjiaFjalaPerzgjedhurStrukturaService: FrazeologjiaFjalaPerzgjedhurStrukturaService,
    private frazeologjiaEtiketaStrukturaService: FrazeologjiaEtiketaStrukturaService,
    private fjalaHuajFjalaPerzgjedhurStrukturaService: FjalaHuajFjalaPerzgjedhurStrukturaService,
    private fjalaHuajEtiketaStrukturaService: FjalaHuajEtiketaStrukturaService,
    private fjalaGabimFjalaPerzgjedhurStrukturaService: FjalaGabimFjalaPerzgjedhurStrukturaService,
    private fjalaGabimEtiketaStrukturaService: FjalaGabimEtiketaStrukturaService,
  ) {
    this.registerIcons();
  }

  ngOnInit(): void {
    this.operatoriFaqjaService
      .listenOnFieldChanges('navigationDictionaryId')
      .pipe(distinctUntilChanged(), takeUntil(this._onDestroy$))
      .subscribe((dictionaryId) => {
        this.navDictionarySelected = dictionaryId ? true : false;
      });

    this.authService.isLoggedInAsLinguist$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        switchMap(() => {
          return this.frazatService.dataLoaded$.pipe(filter((loaded) => loaded));
        })
      )
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });

    this.operatoriFaqjaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
    this.fjaloriStrukturaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
    this.fjalaPerzgjedhurStrukturaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
    this.etiketaStrukturaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
    this.shembulliFjalaPerzgjedhurStrukturaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
    this.shembulliEtiketaStrukturaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
    this.proverbaFjalaPerzgjedhurStrukturaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
    this.proverbaEtiketaStrukturaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
    this.frazeologjiaFjalaPerzgjedhurStrukturaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
    this.frazeologjiaEtiketaStrukturaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
    this.fjalaHuajFjalaPerzgjedhurStrukturaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
    this.fjalaHuajEtiketaStrukturaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
    this.fjalaGabimFjalaPerzgjedhurStrukturaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
    this.fjalaGabimEtiketaStrukturaService.updateOnChanges().pipe(takeUntil(this._onDestroy$)).subscribe();
  }

  private registerIcons() {
    this.matIconRegistry.addSvgIcon(`as_logo`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/logo.svg'));

    this.matIconRegistry.addSvgIcon(`as_dots`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/3dots.svg'));
    this.matIconRegistry.addSvgIcon(`as_a_plus`, this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/addword.svg'));
    this.matIconRegistry.addSvgIcon(`as_a_plus_time`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/quickword.svg`));
    this.matIconRegistry.addSvgIcon(`as_structure`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/structure.svg`)); //
    this.matIconRegistry.addSvgIcon(`as_attachment`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/attachment.svg`));
    this.matIconRegistry.addSvgIcon(`as_audio`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/audio.svg`));
    this.matIconRegistry.addSvgIcon(`as_back`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/back.svg`));
    this.matIconRegistry.addSvgIcon(`as_collapse`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/collapse.svg`));
    this.matIconRegistry.addSvgIcon(`as_comments`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/comments.svg`));
    this.matIconRegistry.addSvgIcon(`as_copy`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/copy.svg`));
    this.matIconRegistry.addSvgIcon(`as_crown`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/kingidic.svg`));
    this.matIconRegistry.addSvgIcon(`as_delete`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/delete.svg`));
    this.matIconRegistry.addSvgIcon(`as_blog`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/blog.svg`)); //
    this.matIconRegistry.addSvgIcon(`as_filter`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/filter.svg`));
    this.matIconRegistry.addSvgIcon(`as_history`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/history.svg`));
    this.matIconRegistry.addSvgIcon(`as_home`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/home.svg`));
    this.matIconRegistry.addSvgIcon(`as_jump`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/jump.svg`));
    this.matIconRegistry.addSvgIcon(`as_save_and_next`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/nextandsave.svg`)); //
    this.matIconRegistry.addSvgIcon(`as_cancel_and_next`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/nextnosave.svg`)); //
    this.matIconRegistry.addSvgIcon(`as_save`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/save.svg`));
    this.matIconRegistry.addSvgIcon(`as_notes`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/notes.svg`));
    this.matIconRegistry.addSvgIcon(`as_paste`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/paste.svg`));
    this.matIconRegistry.addSvgIcon(`as_photo`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/photo.svg`));
    this.matIconRegistry.addSvgIcon(`as_plus`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/plus.svg`));
    this.matIconRegistry.addSvgIcon(`as_save_and_back`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/previusandsave.svg`)); //
    this.matIconRegistry.addSvgIcon(`as_cancel_and_back`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/previusnosave.svg`)); //
    this.matIconRegistry.addSvgIcon(`as_settings`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/settings.svg`));
    this.matIconRegistry.addSvgIcon(`as_shortcut`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/shortcut.svg`));
    this.matIconRegistry.addSvgIcon(`as_dictionary`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/dictionaries.svg`));
    this.matIconRegistry.addSvgIcon(`as_video`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/video.svg`));
    this.matIconRegistry.addSvgIcon(`as_switchlang`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/switchlang.svg`));
    this.matIconRegistry.addSvgIcon(`as_secondary`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/secondary.svg`));
    this.matIconRegistry.addSvgIcon(`as_direkt`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/direkt.svg`));
    this.matIconRegistry.addSvgIcon(`as_indirekt`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/indirekt.svg`));
    this.matIconRegistry.addSvgIcon(`as_delete_relation`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/delete_relation.svg`));
    this.matIconRegistry.addSvgIcon(`as_similar`, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/similar.svg`));
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    if (this.authService.hasValidToken()) {
      this.operatoriFaqjaService.updateOnChanges(0, 0).toPromise();
      this.fjaloriStrukturaService.updateOnChanges(0, 0).toPromise();
      this.fjalaPerzgjedhurStrukturaService.updateOnChanges(0, 0).toPromise();
      this.etiketaStrukturaService.updateOnChanges(0, 0).toPromise();
      this.shembulliFjalaPerzgjedhurStrukturaService.updateOnChanges(0, 0).toPromise();
      this.shembulliEtiketaStrukturaService.updateOnChanges(0, 0).toPromise();
      this.proverbaFjalaPerzgjedhurStrukturaService.updateOnChanges(0, 0).toPromise();
      this.proverbaEtiketaStrukturaService.updateOnChanges(0, 0).toPromise();
      this.frazeologjiaFjalaPerzgjedhurStrukturaService.updateOnChanges(0, 0).toPromise();
      this.frazeologjiaEtiketaStrukturaService.updateOnChanges(0, 0).toPromise();
      this.fjalaHuajFjalaPerzgjedhurStrukturaService.updateOnChanges(0, 0).toPromise();
      this.fjalaHuajEtiketaStrukturaService.updateOnChanges(0, 0).toPromise();
      this.fjalaGabimFjalaPerzgjedhurStrukturaService.updateOnChanges(0, 0).toPromise();
      this.fjalaGabimEtiketaStrukturaService.updateOnChanges(0, 0).toPromise();
    }
  }
}
