import { BaseForCreationDTO } from '@core/interfaces/dto/creation/base-for-creation-dto.interface';
import { BaseForUpdateDTO } from '@core/interfaces/dto/update/base-for-update-dto.interface';
import { BaseParams } from '@core/interfaces/params/base-params.interface';
import { BehaviorSubject, map, Observable, tap, switchMap } from 'rxjs';
import { BaseModel } from '../interfaces/models/base-model.interface';
import { ApiService } from '../services/api.service';
import { BaseApi } from './base-api.class';

export abstract class Store<T1 extends BaseModel, T2 extends BaseParams, T3 extends BaseForCreationDTO, T4 extends BaseForUpdateDTO> extends BaseApi<T1, T2, T3, T4> {
  protected _store$: BehaviorSubject<T1[]> = new BehaviorSubject<T1[]>([]);
  protected _dataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(apiService: ApiService, baseUrl: string) {
    super(apiService, baseUrl);
  }

  load(params?: T2) {
    return super.get(params).pipe(
      tap((items) => {
        this._store$.next(items);
        this._dataLoaded$.next(true);
      })
    );
  }

  getFromStore(): Observable<T1[]> {
    return this._dataLoaded$.pipe(
      switchMap((loaded) => {
        if(loaded)
          return this._store$.asObservable()
        throw `Can't call get before loading store. (${this._endpointUrl})`;
      })
    );
  }

  getByIdFromStore(id: number): Observable<T1 | undefined> {
    return this._store$.pipe(
      map((data) => {
        return data.find(d => d.id === id)
      })
    );
  }

  override insert(item: T3) {
    return super.insert(item).pipe(
      tap((f) => {
        this.insertIntoStore(f);
      })
    );
  }

  override update(item: T4) {
    return super.update(item).pipe(
      tap((f) => {
        this.updateInStore(f);
      })
    )
  }

  override delete(id: number) {
    return super.delete(id).pipe(
      tap(() => {
        this.deleteFromStore(id);
      })
    );
  }

  get dataLoaded$(): Observable<boolean> {
    return this._dataLoaded$.asObservable();
  }

  protected insertIntoStore(item: T1) {
    const store = [...this._store$.value, item];
    this._store$.next(store);
  }

  protected updateInStore(item: T1) {
    const store = [...this._store$.value];
    let itemIndex = store.findIndex((p) => p.id == item.id);
    store[itemIndex] = item;
    this._store$.next(store);
  }

  protected deleteFromStore(id: number) {
    const store = [...this._store$.value];
    let itemIndex = store.findIndex((p) => p.id == id);
    store.splice(itemIndex, 1);
    this._store$.next(store);
  }
}
