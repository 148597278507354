import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PerdoruesiStatusi } from '@core/enums/perdoruesi-statusi.enum';
import { Perdoruesi } from '@core/interfaces/signalr/perdoruesi.interface';
import { LeksikografiaHubService } from '@core/services/signalr/leksikografia-hub.service';
import { distinctUntilChanged, map, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-perdoruesi-info',
  templateUrl: './perdoruesi-info.component.html',
  styleUrls: ['./perdoruesi-info.component.scss'],
})
export class PerdoruesiInfoComponent implements OnInit, OnDestroy {
  operatoriId: string;

  perdoruesi: Perdoruesi | undefined;

  PerdoruesiStatusi = PerdoruesiStatusi;

  openedConnectionId?: string;

  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<PerdoruesiInfoComponent>,
    private leksikografiaHubService: LeksikografiaHubService
  ) {
    this.operatoriId = data.operatoriId;
  }

  ngOnInit(): void {
    this.leksikografiaHubService
      .getPerdoruesit()
      .pipe(
        map((perdoruesit) => perdoruesit.find((p) => p.operatoriId == this.operatoriId)),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((perdoruesi) => {
        if (perdoruesi) this.perdoruesi = perdoruesi;
        else if (!this.perdoruesi) {
          this.dialogRef.close();
        } else {
          this.perdoruesi.connections = [];
        }
      });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
