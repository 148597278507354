import { Injectable } from '@angular/core';
import { StrukturaStore } from '@core/classes/struktura-store.class';
import { FjalaGabimFjalaPerzgjedhurStruktura } from '@core/interfaces/models/fjala-gabim-fjala-perzgjedhur-struktura.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class FjalaGabimFjalaPerzgjedhurStrukturaService extends StrukturaStore<FjalaGabimFjalaPerzgjedhurStruktura> {

  constructor(apiService: ApiService) {
    super(apiService, 'fjalaGabimFjalaPerzgjedhurStruktura');
  }

  updateMy(items: FjalaGabimFjalaPerzgjedhurStruktura[]) {
    return this.apiService.put<FjalaGabimFjalaPerzgjedhurStruktura[]>(`${this._endpointUrl}my`, { fjalaGabimFjalaPerzgjedhurStrukturaForCreationDTO: items });
  }

}