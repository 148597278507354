<div class="disconnected" *ngIf="!connected; else perdoruesitList">
  <mat-icon>wifi_off</mat-icon>
</div>
<ng-template #perdoruesitList>
  <div class="perdoruesit">
    <div class="perdoruesi" *ngFor="let perdoruesi of perdoruesit" [@perdoruesiAnim]>
      <button mat-icon-button type="button" [matTooltip]="perdoruesi.operatoriName" (click)="openPerdoruesiInfo(perdoruesi.operatoriId)">
        <div
          class="wrap"
          [matBadgeHidden]="perdoruesi.connections.length < 2"
          [matBadge]="perdoruesi.connections.length"
          matBadgeSize="small"
          [class.online]="perdoruesi | isOnline"
        >
          {{ perdoruesi.initials }}
        </div>
      </button>
    </div>
    <div class="perdoruesi self" *ngIf="self">
      <button mat-icon-button type="button" [matTooltip]="self.operatoriName" (click)="openPerdoruesiInfo(self.operatoriId)">
        <div
          class="wrap"
          [matBadgeHidden]="self.connections.length < 2"
          [matBadge]="self.connections.length"
          matBadgeSize="small"
          [class.online]="self | isOnline"
        >
          {{ self.initials }}
        </div>
      </button>
    </div>
  </div>
</ng-template>
