<h2 mat-dialog-title>{{ 'notes_title' | phrase }}</h2>
<button
  mat-icon-button
  class="dialog-drag-handle"
  cdkDragHandle
  [disableRipple]="true"
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  cdkDragBoundary="body"
  (cdkDragEnded)="dragEnd($event)"
  [cdkDragFreeDragPosition]="dialogInfo"
  (dblclick)="resetSize()"
>
  <svg _ngcontent-ahf-c139="" width="24px" fill="currentColor" viewBox="0 0 24 24">
    <path _ngcontent-ahf-c139="" d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
    <path _ngcontent-ahf-c139="" d="M0 0h24v24H0z" fill="none"></path>
  </svg>
</button>
<button class="close-dialog-button" mat-icon-button mat-dialog-title (click)="close()">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-content>
  <as-ckeditor [value]="notesValue" (onValueChanges)="notesChanged($event)" class="notesEditor"></as-ckeditor>
</div>
