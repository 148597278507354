import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const navigationBar = trigger('navigationBar', [
  transition(':enter', [style({ transform: 'translateY(-100%)' }), animate('0.3s ease-in-out', style({ transform: 'translateY(0)' }))]),
  transition(':leave', [style({ transform: 'translateY(0)' }), animate('0.3s ease-in-out', style({ transform: 'translateY(-100%)' }))]),
]);

export const dragAndDropItem = trigger('dragAndDropItem', [
  transition(':enter', [
    style({ transform: 'scale(0.7)', opacity: 0, height: '0' }),
    animate('0.2s ease-out', style({ transform: 'scale(1)', opacity: 1, height: '*' })),
  ]),
  transition(':leave', [
    style({ transform: 'scale(1)', opacity: 1, height: '*' }),
    animate('0.2s ease-out', style({ transform: 'scale(0.7)', opacity: 0, height: '0' })),
  ]),
]);

export const chipItem = trigger('chipItem', [
  transition(':enter', [
    style({ transform: 'scale(0.7)', opacity: 0, width: '0' }),
    animate('0.2s ease-in', style({ transform: 'scale(1)', opacity: 1, width: '*' })),
  ]),
  transition(':leave', [
    style({ transform: 'scale(1)', opacity: 1, width: '*' }),
    animate('0.2s ease-out', style({ transform: 'scale(0.7)', opacity: 0, width: '0' })),
  ]),
]);

export const footerWindow = trigger('footerWindow', [
  transition(':enter', [style({ transform: 'translateY(0)' }), animate('0.2s ease-in-out', style({ transform: 'translateY(-100%)' }))]),
  transition(':leave', [style({ transform: 'translateY(-100%)' }), animate('0.2s ease-in-out', style({ transform: 'translateY(0)' }))]),
]);

export const wordsFoundIcon = trigger('wordsFoundIcon', [
  transition(':enter', [style({ transform: 'scale(0)', opacity: 0 }), animate('.2s ease-out', style({ transform: 'scale(1)', opacity: 1 }))]),
  transition(':leave', [style({ transform: 'scale(1)', opacity: 1 }), animate('.2s ease-in', style({ transform: 'scale(0)', opacity: 0 }))]),
]);

export const sidebarItem = trigger('sidebarItem', [
  transition(':enter', [style({ height: '0px' }), animate('.2s ease', style({ height: '36px' }))]),
  transition(':leave', [style({ height: '36px' }), animate('.2s ease', style({ height: '0px' }))]),
]);

export const searchAndReplaceAnim = trigger('searchAndReplaceAnim', [
  transition(':enter', [
    style({ transform: 'translateY(-20px)', opacity: 0 }),
    animate('0.15s ease-in-out', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0)', opacity: 1 }),
    animate('0.15s ease-in-out', style({ transform: 'translateY(-20px)', opacity: 0 })),
  ]),
]);

export const notFoundWindow = trigger('notFoundWindow', [
  transition(':enter', [style({ transform: 'translateY(-100%)' }), animate('0.2s ease-in-out', style({ transform: 'translateY(0%)' }))]),
  transition(':leave', [style({ transform: 'translateY(0%)' }), animate('0.2s ease-in-out', style({ transform: 'translateY(-100%)' }))]),
]);
