import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { ShembulliParacaktimiKerkimitForCreationDTO } from '@core/interfaces/dto/creation/shembulli-paracaktimi-kerkimit-for-creation-dto.interface';
import { ShembulliParacaktimiKerkimitForUpdateDTO } from '@core/interfaces/dto/update/shembulli-paracaktimi-kerkimit-for-update-dto.interface';
import { ShembulliParacaktimiKerkimit } from '@core/interfaces/models/shembulli-paracaktimi-kerkimit.interface';
import { ShembulliParacaktimiKerkimitParams } from '@core/interfaces/params/shembulli-paracaktimi-kerkimit-params.interface';
import { BehaviorSubject, map, tap } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class ShembulliParacaktimiKerkimitService extends Store<
  ShembulliParacaktimiKerkimit,
  ShembulliParacaktimiKerkimitParams,
  ShembulliParacaktimiKerkimitForCreationDTO,
  ShembulliParacaktimiKerkimitForUpdateDTO
> {
  
  constructor(apiService: ApiService) {
    super(apiService, 'ShembulliParacaktimiKerkimit');
  }

  override load(params?: ShembulliParacaktimiKerkimitParams) {
    return this.getMy(params).pipe(
      map((items) => items.sort((a, b) => a.radhitja - b.radhitja)),
      tap((items) => {
        this._store$.next(items);
        this._dataLoaded$.next(true);
      })
    );
  }

  getMy(params?: ShembulliParacaktimiKerkimitParams) {
    return this.apiService.get<ShembulliParacaktimiKerkimit[]>(`${this._endpointUrl}my`, params);
  }

  override update(item: ShembulliParacaktimiKerkimitForUpdateDTO) {
    return this.apiService.put<ShembulliParacaktimiKerkimit>(`${this._endpointUrl}${item.id}`, item).pipe(
      tap(() => {
        this.updateInStore(item);
      })
    );
  }

  updateMultiple(items: ShembulliParacaktimiKerkimitForUpdateDTO[]) {
    return this.apiService.put<ShembulliParacaktimiKerkimit[]>(`${this._endpointUrl}`, items).pipe(
      tap(() => {
        const store = [...this._store$.value];
        items.forEach((item) => {
          const i = store.findIndex((x) => x.id == item.id);
          store.splice(i, 1, item);
        });
        this._store$.next(store);
      })
    );
  }
}
