export enum WordElement {
    Description = 'word_structure_description',
    Materials = 'word_structure_materials',
    Examples = 'word_structure_examples',
    Synonyms = 'word_structure_synonyms',
    Antonyms = 'word_structure_antonyms',
    PartOfSpeech = 'word_structure_partofspeech',
    FieldOfUsage = 'word_structure_fieldofusage',
    Proverbs = 'word_structure_proverbs',
    Phrases = 'word_structure_phrases',
    Mistakes = 'word_structure_mistakes',
    MixUps = 'word_structure_mixups',
    ForeignWords = 'word_structure_foreignwords',
    Etymology = 'word_structure_etymology',
}