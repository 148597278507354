import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FjalaNavbarService {
  constructor() {}

  //#region Shto fjalen shpejt

  private _onShtoFjalenShpejtClick$: Subject<void> = new Subject();

  onShtoFjalenShpejtClick() {
    return this._onShtoFjalenShpejtClick$.asObservable();
  }

  triggerShtoFjalenShpejtClick() {
    this._onShtoFjalenShpejtClick$.next();
  }

  //#endregion

  //#region Editor clear

  private _onEditorClearClick$: Subject<void> = new Subject();

  triggerOnEditorClearClick() {
    this._onEditorClearClick$.next();
  }

  onEditorClearClick() {
    return this._onEditorClearClick$.asObservable();
  }

  //#endregion

  //#region Delete

  private _onDeleteClick$: Subject<void> = new Subject<void>();

  triggerDeleteClick() {
    this._onDeleteClick$.next();
  }

  onDeleteClick() {
    return this._onDeleteClick$.asObservable();
  }

  //#endregion

  //#region Previous
  private _prevFjala$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private _onPrevClick$: Subject<void> = new Subject<void>();

  getPrevFjala() {
    return this._prevFjala$.asObservable();
  }

  setPrevFjala(id: number | null) {
    this._prevFjala$.next(id);
  }

  triggerPrevClick() {
    this._onPrevClick$.next();
  }

  onPrevClick() {
    return this._onPrevClick$.asObservable();
  }
  //#endregion

  //#region Next
  private _nextFjala$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private _onNextClick$: Subject<void> = new Subject<void>();

  getNextFjala() {
    return this._nextFjala$.asObservable();
  }

  setNextFjala(id: number | null) {
    this._nextFjala$.next(id);
  }

  triggerNextClick() {
    this._onNextClick$.next();
  }

  onNextClick() {
    return this._onNextClick$.asObservable();
  }
  //#endregion

  //#region Enter
  
  private _onEnterClick$: Subject<void> = new Subject<void>();
  
  onEnterClick() {
    return this._onEnterClick$.asObservable();
  }

  triggerEnterClick() {
    this._onEnterClick$.next();
  }

  //#endregion

  //#region Save and previous

  private _onSaveAndPrevClick$: Subject<void> = new Subject<void>();
  
  onSaveAndPrevClick() {
    return this._onSaveAndPrevClick$.asObservable();
  }

  triggerSaveAndPrevClick() {
    this._onSaveAndPrevClick$.next();
  }

  //#endregion

  //#region Save

  private _onSaveClick$: Subject<void> = new Subject<void>();
  
  onSaveClick() {
    return this._onSaveClick$.asObservable();
  }

  triggerSaveClick() {
    this._onSaveClick$.next();
  }

  //#endregion

  //#region Save and next

  private _onSaveAndNextClick$: Subject<void> = new Subject<void>();
  
  onSaveAndNextClick() {
    return this._onSaveAndNextClick$.asObservable();
  }

  triggerSaveAndNextClick() {
    this._onSaveAndNextClick$.next();
  }

  //#endregion

  //#region Comments

  private _onCommentsClick$: Subject<void> = new Subject<void>();

  onCommentsClick() {
    return this._onCommentsClick$.asObservable();
  }

  triggerCommentsClick() {
    this._onCommentsClick$.next();
  }

  //#endregion

}
