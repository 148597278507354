import { Injectable } from '@angular/core';
import { StrukturaStore } from '@core/classes/struktura-store.class';
import { ShembulliEtiketaStruktura } from '@core/interfaces/models/shembulli-etiketa-struktura.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ShembulliEtiketaStrukturaService extends StrukturaStore<ShembulliEtiketaStruktura> {

  constructor(apiService: ApiService) {
    super(apiService, 'shembulliEtiketaStruktura');
  }

  updateMy(items: ShembulliEtiketaStruktura[]) {
    return this.apiService.put<ShembulliEtiketaStruktura[]>(`${this._endpointUrl}my`, { shembulliEtiketaStrukturaForCreationDTO: items });
  }

}
