import { Injectable } from '@angular/core';
import { BaseApi } from '@core/classes/base-api.class';
import { FrazeologjiaForCreationDTO } from '@core/interfaces/dto/creation/frazeologjia-for-creation-dto.interface';
import { FrazeologjiaForUpdateDTO } from '@core/interfaces/dto/update/frazeologjia-for-update-dto.interface';
import { Frazeologjia } from '@core/interfaces/models/frazeologjia.interface';
import { FrazeologjiaParams } from '@core/interfaces/params/frazeologjia-params.interface';
import { BehaviorSubject, filter, map } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class FrazeologjiaService extends BaseApi<Frazeologjia, FrazeologjiaParams, FrazeologjiaForCreationDTO, FrazeologjiaForUpdateDTO> {

  private _frazeologjiaList$: BehaviorSubject<{ list: Frazeologjia[], propagate: boolean } | undefined> = new BehaviorSubject<{ list: Frazeologjia[], propagate: boolean } | undefined>(undefined);
  private _loadedFjaloriId$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  constructor(apiService: ApiService) {
    super(apiService, 'frazeologjia');
  }

  getFrazeologjiaList(propagateOnly: boolean = true) {
    return this._frazeologjiaList$.asObservable().pipe(
      filter((value) => (propagateOnly ? value?.propagate! : true)),
      map((value) => {
        return value ? value.list : undefined;
      })
    );
  }

  resetFrazeologjiaList() {
    this._frazeologjiaList$.next(undefined);
  }

  setFrazeologjiaList(list: Frazeologjia[], propagate: boolean = true) {
    this._frazeologjiaList$.next({ list: list, propagate: propagate });
  }

  resetLoadedFjaloriId() {
    this._loadedFjaloriId$.next(undefined);
  }

  getLoadedFjaloriId() {
    return this._loadedFjaloriId$.asObservable();
  }

  setLoadedFjaloriId(fjaloriId: number) {
    this._loadedFjaloriId$.next(fjaloriId);
  }

}
