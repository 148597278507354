import { Injectable } from '@angular/core';
import { Validators, FormArray, FormGroup, FormControl, AbstractControl, UntypedFormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EditoriForUpdateInsertDTO } from '@core/interfaces/dto/editori-for-update-insert-dto.interface';
import { Editori } from '@core/interfaces/models/editori.interface';
import { ConfirmWordChangeWithoutSaveComponent } from '@shared/dialogs/confirm-word-change-without-save/confirm-word-change-without-save.component';
import { ConfirmWordChangeComponent } from '@shared/dialogs/confirm-word-change/confirm-word-change.component';
import { BehaviorSubject, map, Observable, of, Subject, switchMap, tap, filter, combineLatest, throttleTime } from 'rxjs';
import { BazaService } from './baza.service';

export function bazaSekondareValidator(control: AbstractControl): { [key: string]: any } | null {
  if (!(control instanceof FormGroup)) {
    throw 'bazaSekondareValidator works only on form groups.';
  }

  if (control.get('fjala')?.value == null || control.get('fjala')?.value.trim() == '') {
    if (control.dirty) return { fjalaRequired: true };
  }

  return null;
}

@Injectable({
  providedIn: 'root',
})
export class EditoriService {
  public editoriForm = new FormGroup({
    editoriBazaKryesore: new FormGroup({
      baza: new FormGroup({
        id: new FormControl<number | null>(null),
        fjaloriId: new FormControl<number | null>(null, [Validators.required]),
        fjala: new FormControl<string>('', [Validators.required]),
        homonimi: new FormControl<number | null>(0),
        pjesaLigjeratesId: new FormControl<number | null>(null),
        pershkrimi: new FormControl<string>(''),
        nyjaId: new FormControl<number | null>(null),
        materialet: new FormControl<string | null>(null),
        origjina: new FormControl<string | null>(null),
        bazaFjalaPerzgjedhur: new UntypedFormArray([]),
        referenca: new UntypedFormArray([]),
        bazaFushaPerdorimit: new UntypedFormArray([]),
        bazaShembulli: new UntypedFormArray([]),
        bazaProverba: new UntypedFormArray([]),
        bazaFjalaHuaj: new UntypedFormArray([]),
        bazaFrazeologjia: new UntypedFormArray([]),
        sinonimi: new UntypedFormArray([]),
        sinonimiMaterial: new UntypedFormArray([]),
        antonimi: new UntypedFormArray([]),
        antonimiMaterial: new UntypedFormArray([]),
        bazaFjalaGabim: new UntypedFormArray([]),
        bazaFjalaPerzier: new UntypedFormArray([]),
        bazaEtiketa: new UntypedFormArray([]),
        emriBaza: new FormGroup({
          id: new FormControl<number | null>(null),
          gjiniaId: new FormControl<number | null>(null),
          numriId: new FormControl<number | null>(null),
          llojiId: new FormControl<number | null>(null),
        }),
        mbiemriBaza: new FormGroup({
          id: new FormControl<number | null>(null),
          gjiniaId: new FormControl<number | null>(null),
          llojiId: new FormControl<number | null>(null),
          vetemNjeraGjini: new FormControl<boolean | null>(null)
        }),
        foljaBaza: new FormGroup({
          id: new FormControl<number | null>(null),
          kalimtaresiaId: new FormControl<number | null>(null),
        }),
        ePapercaktuarBaza: new FormGroup({
          id: new FormControl<number | null>(null),
          llojiId: new FormControl<number | null>(null),
        }),
        burimiId: new FormControl<string | null>(null),
        burimi: new FormControl<string | null>(null),
        shtoFjalenNeMorfologji: new FormControl<boolean>(true),
        krijoFjaleTeReNeFjales: new FormControl<boolean>(false),
        updateKrejtGrupin: new FormControl<boolean>(false),
        shkeputNgaGrupi: new FormControl<boolean>(false),
        fjalesiId: new FormControl<number | null>(null),
        ekzistonNeMorfologji: new FormControl<boolean>(false),
        anuloDrejtshkrimin: new FormControl<boolean>(false),
      }),
      llojiRelacionit: new FormControl<string | null>(null),
    }),
    editoriBazaSekondare: new FormGroup({
      baza: new FormGroup(
        {
          id: new FormControl<number | null>(null),
          fjaloriId: new FormControl<number | null>(null),
          fjala: new FormControl<string>(''),
          homonimi: new FormControl<number | null>(0),
          pjesaLigjeratesId: new FormControl<number | null>(null),
          pershkrimi: new FormControl<string>(''),
          nyjaId: new FormControl<number | null>(null),
          materialet: new FormControl<string | null>(null),
          origjina: new FormControl<string | null>(null),
          bazaFjalaPerzgjedhur: new UntypedFormArray([]),
          referenca: new UntypedFormArray([]),
          bazaFushaPerdorimit: new UntypedFormArray([]),
          bazaShembulli: new UntypedFormArray([]),
          bazaProverba: new UntypedFormArray([]),
          bazaFjalaHuaj: new UntypedFormArray([]),
          bazaFrazeologjia: new UntypedFormArray([]),
          sinonimi: new UntypedFormArray([]),
          sinonimiMaterial: new UntypedFormArray([]),
          antonimi: new UntypedFormArray([]),
          antonimiMaterial: new UntypedFormArray([]),
          bazaFjalaGabim: new UntypedFormArray([]),
          bazaFjalaPerzier: new UntypedFormArray([]),
          bazaEtiketa: new UntypedFormArray([]),
          emriBaza: new FormGroup({
            id: new FormControl<number | null>(null),
            gjiniaId: new FormControl<number | null>(null),
            numriId: new FormControl<number | null>(null),
            llojiId: new FormControl<number | null>(null),
          }),
          mbiemriBaza: new FormGroup({
            id: new FormControl<number | null>(null),
            gjiniaId: new FormControl<number | null>(null),
            llojiId: new FormControl<number | null>(null),
            vetemNjeraGjini: new FormControl<boolean | null>(null)
          }),
          foljaBaza: new FormGroup({
            id: new FormControl<number | null>(null),
            kalimtaresiaId: new FormControl<number | null>(null),
          }),
          ePapercaktuarBaza: new FormGroup({
            id: new FormControl<number | null>(null),
            llojiId: new FormControl<number | null>(null),
          }),
          burimiId: new FormControl<string | null>(null),
          burimi: new FormControl<string | null>(null),
          shtoFjalenNeMorfologji: new FormControl<boolean>(true),
          krijoFjaleTeReNeFjales: new FormControl<boolean>(false),
          updateKrejtGrupin: new FormControl<boolean>(false),
          shkeputNgaGrupi: new FormControl<boolean>(false),
          fjalesiId: new FormControl<number | null>(null),
          ekzistonNeMorfologji: new FormControl<boolean>(false),
          anuloDrejtshkrimin: new FormControl<boolean>(false),
        },
        { validators: bazaSekondareValidator }
      ),
      llojiRelacionit: new FormControl<string | null>(null),
    }),
    editoriBazaTerciare: new FormGroup({
      baza: new FormGroup(
        {
          id: new FormControl<number | null>(null),
          fjaloriId: new FormControl<number | null>(null),
          fjala: new FormControl<string>(''),
          homonimi: new FormControl<number | null>(0),
          pjesaLigjeratesId: new FormControl<number | null>(null),
          pershkrimi: new FormControl<string>(''),
          nyjaId: new FormControl<number | null>(null),
          materialet: new FormControl<string | null>(null),
          origjina: new FormControl<string | null>(null),
          bazaFjalaPerzgjedhur: new UntypedFormArray([]),
          referenca: new UntypedFormArray([]),
          bazaFushaPerdorimit: new UntypedFormArray([]),
          bazaShembulli: new UntypedFormArray([]),
          bazaProverba: new UntypedFormArray([]),
          bazaFjalaHuaj: new UntypedFormArray([]),
          bazaFrazeologjia: new UntypedFormArray([]),
          sinonimi: new UntypedFormArray([]),
          sinonimiMaterial: new UntypedFormArray([]),
          antonimi: new UntypedFormArray([]),
          antonimiMaterial: new UntypedFormArray([]),
          bazaFjalaGabim: new UntypedFormArray([]),
          bazaFjalaPerzier: new UntypedFormArray([]),
          bazaEtiketa: new UntypedFormArray([]),
          emriBaza: new FormGroup({
            id: new FormControl<number | null>(null),
            gjiniaId: new FormControl<number | null>(null),
            numriId: new FormControl<number | null>(null),
            llojiId: new FormControl<number | null>(null),
          }),
          mbiemriBaza: new FormGroup({
            id: new FormControl<number | null>(null),
            gjiniaId: new FormControl<number | null>(null),
            llojiId: new FormControl<number | null>(null),
            vetemNjeraGjini: new FormControl<boolean | null>(null)
          }),
          foljaBaza: new FormGroup({
            id: new FormControl<number | null>(null),
            kalimtaresiaId: new FormControl<number | null>(null),
          }),
          ePapercaktuarBaza: new FormGroup({
            id: new FormControl<number | null>(null),
            llojiId: new FormControl<number | null>(null),
          }),
          burimiId: new FormControl<string | null>(null),
          burimi: new FormControl<string | null>(null),
          shtoFjalenNeMorfologji: new FormControl<boolean>(true),
          krijoFjaleTeReNeFjales: new FormControl<boolean>(false),
          updateKrejtGrupin: new FormControl<boolean>(false),
          shkeputNgaGrupi: new FormControl<boolean>(false),
          fjalesiId: new FormControl<number | null>(null),
          ekzistonNeMorfologji: new FormControl<boolean>(false),
          anuloDrejtshkrimin: new FormControl<boolean>(false),
        },
        { validators: bazaSekondareValidator }
      ),
      llojiRelacionit: new FormControl<string | null>(null),
    }),
    kopjoFjalen: new UntypedFormArray([]),
  });

  private _fjalaExcluded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _saving$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _onEditorReset$: Subject<void> = new Subject();

  private _saveHistory: boolean = true;

  private _initialHistoryValue: any;
  private _formHistoryUndo: Array<any> = new Array<any>();
  private _formHistoryRedo: Array<any> = new Array<any>();
  private _canUndo$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _canRedo$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private matDialog: MatDialog, private bazaService: BazaService) {
    this.editoriForm.valueChanges
      .pipe(
        filter(() => this._saveHistory),
        tap(() => {
          this._formHistoryRedo = new Array();
          this._canRedo$.next(false);
        }),
        throttleTime(150)
      )
      .subscribe((value) => {
        if (this._formHistoryUndo.length == 0) {
          this._formHistoryUndo.splice(0, 0, this._initialHistoryValue);
        } else {
          this._formHistoryUndo.splice(0, 0, this.editoriForm.getRawValue());
        }

        this._canUndo$.next(true);
        this._canRedo$.next(false);

        if (this._formHistoryUndo.length > 50) this._formHistoryUndo.pop();
      });
  }

  setFormValue(editori: Editori) {
    this._saveHistory = false;

    if (editori.editoriBazaKryesore?.baza.fjalesiId) editori.editoriBazaKryesore.baza.fjalesiId = undefined;
    if (editori.editoriBazaSekondare?.baza.fjalesiId) editori.editoriBazaSekondare.baza.fjalesiId = undefined;
    if (editori.editoriBazaTerciare?.baza.fjalesiId) editori.editoriBazaTerciare.baza.fjalesiId = undefined;

    this.resetControlValue(this.editoriForm);
    this.setControlValue(editori, this.editoriForm);
    this._initialHistoryValue = editori;

    this.editoriForm.controls.editoriBazaKryesore.controls.baza.controls.shtoFjalenNeMorfologji.setValue(
      editori.editoriBazaKryesore?.baza.ekzistonNeMorfologji ?? false
    );
    this.editoriForm.controls.editoriBazaSekondare.controls.baza.controls.shtoFjalenNeMorfologji.setValue(
      editori.editoriBazaSekondare?.baza.ekzistonNeMorfologji ?? false
    );
    this.editoriForm.controls.editoriBazaTerciare.controls.baza.controls.shtoFjalenNeMorfologji.setValue(
      editori.editoriBazaTerciare?.baza.ekzistonNeMorfologji ?? false
    );

    this._formHistoryUndo = new Array();
    this._formHistoryRedo = new Array();
    this._canUndo$.next(false);
    this._canRedo$.next(false);

    this._onEditorReset$.next();
    this.editoriForm.markAsPristine();
    this.editoriForm.markAsUntouched();

    this._saveHistory = true;
  }

  setControlValue(object: any, control?: AbstractControl) {
    if (control instanceof FormGroup) {
      for (const field in control.controls) {
        // control.get(field)!.reset(undefined, { emitEvent: false });
        if (object && object[field]) {
          this.setControlValue(object[field], control.get(field)!);
        }
        //  else {
        //   this.resetControlValue(control);
        // }
      }
    } else if (control instanceof FormArray) {
      (object as [])?.forEach((obj) => {
        const keys = Object.keys(obj);
        let fg: FormGroup = new FormGroup({});
        keys.forEach((key) => {
          fg.addControl(key, new FormControl({ value: obj[key], disabled: control.disabled }));
        });
        control.push(fg);
      });
    } else if (control instanceof FormControl) {
      control.setValue(object);
    }
  }

  resetForm(control?: AbstractControl) {
    this._saveHistory = false;

    this.resetControlValue(control ?? this.editoriForm);

    this.editoriForm.controls.editoriBazaKryesore.controls.baza.controls.shtoFjalenNeMorfologji.setValue(true);
    this.editoriForm.controls.editoriBazaSekondare.controls.baza.controls.shtoFjalenNeMorfologji.setValue(true);
    this.editoriForm.controls.editoriBazaTerciare.controls.baza.controls.shtoFjalenNeMorfologji.setValue(true);

    this.editoriForm.controls.editoriBazaKryesore.controls.baza.controls.fjaloriId.setValue(this.fjaloriKryesorId);
    this.editoriForm.controls.editoriBazaSekondare.controls.baza.controls.fjaloriId.setValue(this.fjaloriSekondarId);
    this.editoriForm.controls.editoriBazaTerciare.controls.baza.controls.fjaloriId.setValue(this.fjaloriTerciarId);
    this.editoriForm.markAsPristine();
    this.editoriForm.markAsUntouched();

    this._initialHistoryValue = this.editoriForm.getRawValue();

    this._onEditorReset$.next();
    this._formHistoryUndo = new Array();
    this._formHistoryRedo = new Array();
    this._canUndo$.next(false);
    this._canRedo$.next(false);

    this._saveHistory = true;
  }

  resetControlValue(control?: AbstractControl) {
    if (control instanceof FormGroup) {
      for (const field in control.controls) {
        if (field != 'fjaloriId') this.resetControlValue(control.get(field)!);
      }
    } else if (control instanceof FormControl) {
      control.setValue(null); //reset();
    } else if (control instanceof FormArray) {
      control.clear();
    }
  }

  updateOrderCtrl(formArray: FormArray, orderFieldName: string) {
    for (let i = 0; i < formArray.length; i++) {
      formArray
        .at(i)
        .get(orderFieldName)
        ?.setValue(i + 1);
    }
  }

  restrictedReplaceFieldNames: string[] = [
    'id',
    'fjaloriId',
    'homonimi',
    'pjesaLigjeratesId',
    'nyjaId',
    'burimi',
    'burimiId',
    'gjiniaId',
    'numriId',
    'llojiId',
    'kalimtaresiaId',
  ];

  recursiveReplace(from: string, to: string, caseSensitive: boolean, control: AbstractControl) {
    if (control instanceof FormGroup) {
      for (const field in control.controls) {
        if (!this.restrictedReplaceFieldNames.includes(field)) this.recursiveReplace(from, to, caseSensitive, control.get(field)!);
      }
    } else if (control instanceof FormControl) {
      let value = control.value;

      if (value != null && typeof value === 'string') {
        const re = new RegExp(from, `g${caseSensitive ? '' : 'i'}`);
        const match = value.match(re);

        if (match) {
          control.setValue(value.replace(re, to));
          control.markAsDirty();
        }
      }
    } else if (control instanceof FormArray) {
      for (let i = 0; i < control.controls.length; i++) {
        this.recursiveReplace(from, to, caseSensitive, control.at(i));
      }
    }
  }

  fjaloriKryesorId: number | null = null;
  registerFjaloriKryesorId(id: number | null) {
    if (this.fjaloriKryesorId != id) {
      this.fjaloriKryesorId = id;
      this._saveHistory = false;
      this.editoriForm.controls.editoriBazaKryesore.controls.baza.controls.fjaloriId.setValue(this.fjaloriKryesorId);
      this._saveHistory = true;
    }
  }

  fjaloriSekondarId: number | null = null;
  registerFjaloriSekondarId(id: number | null) {
    if (this.fjaloriSekondarId != id) {
      this.fjaloriSekondarId = id;
      this._saveHistory = false;
      if (id == null) {
        this.resetControlValue(this.editoriForm.controls.editoriBazaSekondare);
      } else {
        this.editoriForm.controls.editoriBazaSekondare.controls.baza.controls.fjaloriId.setValue(this.fjaloriSekondarId);
      }
      this._saveHistory = true;
    }
  }

  fjaloriTerciarId: number | null = null;
  registerFjaloriTerciarId(id: number | null) {
    if (this.fjaloriTerciarId != id) {
      this.fjaloriTerciarId = id;
      this._saveHistory = false;
      if (id == null) {
        this.resetControlValue(this.editoriForm.controls.editoriBazaTerciare);
      } else {
        this.editoriForm.controls.editoriBazaTerciare.controls.baza.controls.fjaloriId.setValue(this.fjaloriTerciarId);
      }
      this._saveHistory = true;
    }
  }

  getCanChangeWord(): Observable<boolean> {
    if (this.editoriForm.dirty) {
      if (this.editoriForm.valid) {
        const dialog = this.matDialog.open(ConfirmWordChangeComponent, {
          width: '300px',
          disableClose: true,
        });

        return dialog.afterClosed().pipe(
          switchMap((saveChanges) => {
            if (saveChanges === undefined || saveChanges === null) {
              return of(false);
            }
            if (saveChanges) {
              this.editoriForm.disable();
              this.setSaving(true);

              return this.save().pipe(
                map(() => {
                  this.editoriForm.enable();
                  this.setSaving(false);
                  return true;
                })
              );
            }
            return of(true);
          })
        );
      } else {
        const dialog = this.matDialog.open(ConfirmWordChangeWithoutSaveComponent, {
          width: '400px',
          disableClose: true,
        });

        return dialog.afterClosed().pipe(map((response) => response ?? false));
      }
    }
    return of(true);
  }

  save() {
    return this.bazaService.ruajEditorin(this.getDTO());
  }

  setFjalaExcluded(excluded: boolean) {
    this._fjalaExcluded$.next(excluded);
  }

  getFjalaExcluded() {
    return this._fjalaExcluded$.asObservable();
  }

  getLoading() {
    return this._loading$.asObservable();
  }

  setLoading(loading: boolean) {
    this._saveHistory = !loading;
    this._loading$.next(loading);
  }

  getSaving() {
    return this._saving$.asObservable();
  }

  setSaving(saving: boolean) {
    this._saving$.next(saving);
  }

  getOnEditorReset() {
    return this._onEditorReset$.asObservable();
  }

  undo() {
    if (this._formHistoryUndo.length > 0) {
      let last = this._formHistoryUndo.splice(0, 1)[0];

      const current = this.editoriForm.getRawValue();
      this._formHistoryRedo.splice(0, 0, current);

      this._saveHistory = false;
      this.resetControlValue(this.editoriForm);
      this.setControlValue(last, this.editoriForm);
      this._saveHistory = true;

      if (this._formHistoryUndo.length > 0) {
        this._canUndo$.next(true);
      } else {
        this._canUndo$.next(false);
      }
      this._canRedo$.next(true);
    }
  }

  canUndo() {
    return combineLatest([this._canUndo$, this._loading$, this._saving$]).pipe(map(([undo, loading, saving]) => undo && !loading && !saving));
  }

  redo() {
    if (this._formHistoryRedo.length > 0) {
      const last = this._formHistoryRedo.splice(0, 1)[0];
      const current = this.editoriForm.getRawValue();
      this._formHistoryUndo.splice(0, 0, current);

      this._saveHistory = false;
      this.resetControlValue(this.editoriForm);
      this.setControlValue(last, this.editoriForm);
      this._saveHistory = true;

      if (this._formHistoryRedo.length > 0) {
        this._canRedo$.next(true);
      } else {
        this._canRedo$.next(false);
      }
      this._canUndo$.next(true);
    }
  }

  canRedo() {
    return combineLatest([this._canRedo$, this._loading$, this._saving$]).pipe(map(([redo, loading, saving]) => redo && !loading && !saving));
  }

  private getDTO() {
    const value = this.editoriForm.getRawValue();

    let editoriForUpdateInsertDTO: EditoriForUpdateInsertDTO = {
      bazaPrimareFjaloriId: value.editoriBazaKryesore.baza.fjaloriId!,
      bazaSekondareFjaloriId: value.editoriBazaSekondare.baza.fjaloriId ?? undefined,
      bazaTerciareFjaloriId: value.editoriBazaTerciare.baza.fjaloriId ?? undefined,
      kopjoFjalenForCreationDTO: value.kopjoFjalen ?? [],
    };

    if (this.editoriForm.controls.editoriBazaKryesore.dirty) {
      if (value.editoriBazaKryesore?.baza?.fjala != null && value.editoriBazaKryesore?.baza?.fjala != '') {
        if (value.editoriBazaKryesore.baza.id != null) {
          editoriForUpdateInsertDTO.bazaPrimareForUpdateDTO = this.getBazaDTO(value.editoriBazaKryesore.baza);
        } else {
          editoriForUpdateInsertDTO.bazaPrimareForCreationDTO = this.getBazaDTO(value.editoriBazaKryesore.baza);
        }
      }
    }
    if (this.editoriForm.controls.editoriBazaSekondare.dirty) {
      if (value.editoriBazaSekondare?.baza?.fjala != null && value.editoriBazaSekondare?.baza?.fjala != '') {
        if (value.editoriBazaSekondare.baza.id != null) {
          editoriForUpdateInsertDTO.bazaSekondareForUpdateDTO = this.getBazaDTO(value.editoriBazaSekondare.baza);
        } else {
          editoriForUpdateInsertDTO.bazaSekondareForCreationDTO = this.getBazaDTO(value.editoriBazaSekondare.baza);
        }
      }
    }
    if (this.editoriForm.controls.editoriBazaTerciare.dirty) {
      if (value.editoriBazaTerciare?.baza?.fjala != null && value.editoriBazaTerciare?.baza?.fjala != '') {
        if (value.editoriBazaTerciare.baza.id != null) {
          editoriForUpdateInsertDTO.bazaTerciareForUpdateDTO = this.getBazaDTO(value.editoriBazaTerciare.baza);
        } else {
          editoriForUpdateInsertDTO.bazaTerciareForCreationDTO = this.getBazaDTO(value.editoriBazaTerciare.baza);
        }
      }
    }

    return editoriForUpdateInsertDTO;
  }

  private getBazaDTO(baza: any) {
    return {
      id: baza.id,
      fjala: baza.fjala!,
      pjesaLigjeratesId: baza.pjesaLigjeratesId ?? undefined,
      homonimi: baza.homonimi ?? undefined,
      nyjaId: baza.nyjaId ?? undefined,
      pershkrimi: baza.pershkrimi ?? '',
      materialet: baza.materialet ?? '',
      origjina: baza.origjina ?? '',
      burimiId: baza.burimiId ?? undefined,
      burimi: baza.burimi ?? undefined,
      fjaloriId: baza.fjaloriId!,
      bazaFushaPerdorimitForCreationDTO: baza.bazaFushaPerdorimit ?? null,
      bazaEtiketaForCreationDTO: baza.bazaEtiketa ?? null,
      bazaFjalaPerzgjedhurForCreationDTO: baza.bazaFjalaPerzgjedhur ?? null,
      referencaForCreationDTO: baza.referenca ?? null,
      bazaShembulliForCreationDTO: baza.bazaShembulli ?? null,
      bazaProverbaForCreationDTO: baza.bazaProverba ?? null,
      bazaFjalaHuajForCreationDTO: baza.bazaFjalaHuaj ?? null,
      bazaFrazeologjiaForCreationDTO: baza.bazaFrazeologjia ?? null,
      sinonimiForCreationDTO: baza.sinonimi ?? null,
      sinonimiMaterialForCreationDTO: baza.sinonimiMaterial ?? null,
      antonimiForCreationDTO: baza.antonimi ?? null,
      antonimiMaterialForCreationDTO: baza.antonimiMaterial ?? null,
      bazaFjalaGabimForCreationDTO: baza.bazaFjalaGabim ?? null,
      bazaFjalaPerzierForCreationDTO: baza.bazaFjalaPerzier ?? null,
      emriBazaForUpdateDTO:
        baza.id && (baza.emriBaza?.id || baza.emriBaza?.gjiniaId || baza.emriBaza?.numriId || baza.emriBaza?.llojiId)
          ? {
              id: baza.emriBaza?.id ?? undefined,
              gjiniaId: baza.emriBaza?.gjiniaId ?? undefined,
              numriId: baza.emriBaza?.numriId ?? undefined,
              llojiId: baza.emriBaza?.llojiId ?? undefined,
            }
          : undefined,
      emriBazaForCreationDTO:
        !baza.id && (baza.emriBaza?.gjiniaId || baza.emriBaza?.numriId || baza.emriBaza?.llojiId)
          ? {
              gjiniaId: baza.emriBaza?.gjiniaId ?? undefined,
              numriId: baza.emriBaza?.numriId ?? undefined,
              llojiId: baza.emriBaza?.llojiId ?? undefined,
            }
          : undefined,
      mbiemriBazaForUpdateDTO:
        baza.id && (baza.mbiemriBaza?.id || baza.mbiemriBaza?.gjiniaId || baza.mbiemriBaza?.llojiId || baza.mbiemriBaza?.vetemNjeraGjini !== undefined)
          ? {
              id: baza.mbiemriBaza?.id ?? undefined,
              gjiniaId: baza.mbiemriBaza?.gjiniaId ?? undefined,
              llojiId: baza.mbiemriBaza?.llojiId ?? undefined,
              vetemNjeraGjini: baza.mbiemriBaza?.vetemNjeraGjini ?? undefined,
            }
          : undefined,
      mbiemriBazaForCreationDTO:
        !baza.id && (baza.mbiemriBaza?.gjiniaId || baza.mbiemriBaza?.llojiId || baza.mbiemriBaza?.vetemNjeraGjini !== undefined)
          ? {
              gjiniaId: baza.mbiemriBaza?.gjiniaId ?? undefined,
              llojiId: baza.mbiemriBaza?.llojiId ?? undefined,
              vetemNjeraGjini: baza.mbiemriBaza?.vetemNjeraGjini ?? undefined,
            }
          : undefined,
      foljaBazaForUpdateDTO:
        baza.id && (baza.foljaBaza?.id || baza.foljaBaza?.kalimtaresiaId)
          ? {
              id: baza.foljaBaza?.id ?? undefined,
              kalimtaresiaId: baza.foljaBaza?.kalimtaresiaId ?? undefined,
            }
          : undefined,
      foljaBazaForCreationDTO:
        !baza.id && baza.foljaBaza?.kalimtaresiaId
          ? {
              kalimtaresiaId: baza.foljaBaza?.kalimtaresiaId ?? undefined,
            }
          : undefined,
      ePapercaktuarBazaForUpdateDTO:
        baza.id && (baza.ePapercaktuarBaza?.id || baza.ePapercaktuarBaza?.llojiId)
          ? {
              id: baza.ePapercaktuarBaza?.id ?? undefined,
              llojiId: baza.ePapercaktuarBaza?.llojiId ?? undefined,
            }
          : undefined,
      ePapercaktuarBazaForCreationDTO:
        !baza.id && baza.ePapercaktuarBaza?.llojiId
          ? {
              llojiId: baza.ePapercaktuarBaza?.llojiId ?? undefined,
            }
          : undefined,
      shtoFjalenNeMorfologji: baza.shtoFjalenNeMorfologji ?? false,
      krijoFjaleTeReNeFjales: baza.krijoFjaleTeReNeFjales ?? false,
      fjalesiId: baza.fjalesiId,
      anuloDrejtshkrimin: baza.anuloDrejtshkrimin ?? false,
      updateKrejtGrupin: baza.updateKrejtGrupin ?? false,
      shkeputNgaGrupi: baza.shkeputNgaGrupi ?? false,
    };
  }
}
