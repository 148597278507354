import { Injectable } from '@angular/core';
import { Store } from '@core/classes/store.class';
import { FrazaForCreationDTO } from '@core/interfaces/dto/creation/fraza-for-creation-dto.interface';
import { FrazaForUpdateDTO } from '@core/interfaces/dto/update/fraza-for-update-dto.interface';
import { Fraza } from '@core/interfaces/models/fraza.interface';
import { BaseParams } from '@core/interfaces/params/base-params.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FrazatService extends Store<Fraza, BaseParams, FrazaForCreationDTO, FrazaForUpdateDTO> {
  baseUrl: string = 'fraza/';

  constructor(apiService: ApiService) {
    super(apiService, 'fraza/');
  }

  /**
   * Gets value of phrase by name
   * @param emri name of the phrase
   */
  fn(emri: string) {
    return this._store$.value.find((x) => x.emri === emri)?.vlera;
  }
}
