import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Directive({
  selector: '[hasRoles]',
})
export class HasRolesDirective implements OnInit {
  private isAdmin: boolean = false;
  private lexRoles: string[] = [];
  private rolesToCheck: string[] = [];

  private created = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private oauthService: OAuthService
  ) {
    if (this.oauthService.hasValidAccessToken()) {
      const token = this.oauthService.getIdentityClaims() as any;
      if (token) {
        if (token.role && token.role.includes('SuperAdmin')) {
          this.isAdmin = true;
        }
        if (token.lex_role) {
          if (typeof token.lex_role == 'string') {
            this.lexRoles.push(token.lex_role);
          } else {
            this.lexRoles = token.lex_role;
          }
        }
      }
    }
  }

  ngOnInit(): void {}

  @Input()
  set hasRoles(roles: string[]) {
    this.rolesToCheck = roles;
    this.updateView();
  }

  private updateView() {
    if (this.checkRoles()) {
      if (!this.created) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.created = true;
      }
    } else {
      this.viewContainer.clear();
      this.created = false;
    }
  }

  private checkRoles() {
    let hasRole = false;

    if (this.isAdmin) {
      return true;
    }
    if (this.rolesToCheck && this.lexRoles) {
      hasRole = this.lexRoles.some((x) => {
        return this.rolesToCheck.some((y) => x == y);
      });
    }
    return hasRole;
  }
}
