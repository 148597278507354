import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PermissionName } from '@core/enums/permissions/permission-name.enum';
import { PermissionType } from '@core/enums/permissions/permission-type.enum';
import { OperatoriFaqjaService } from '@core/services/operatori-faqja.service';
import { QasjaService } from '@core/services/qasja.service';
import { ShembulliEditoriService } from '@core/services/shembulli/shembulli-editori.service';
import { ShembulliNavbarService } from '@core/services/shembulli/shembulli-navbar.service';
import { Subject, takeUntil, debounceTime, switchMap, combineLatest, distinctUntilChanged, of, pairwise } from 'rxjs';
import { ShenimetPopupComponent } from '../shenimet-popup/shenimet-popup.component';

@Component({
  selector: 'app-shembujt-nav-buttons',
  templateUrl: './shembujt-nav-buttons.component.html',
  styleUrls: ['./shembujt-nav-buttons.component.scss'],
})
export class ShembujtNavButtonsComponent implements OnInit, OnDestroy {
  formDirty: boolean = false;
  formValid: boolean = false;

  
  prevShembulliId: number | null = null;
  shembulliId: number | null = null;
  nextShembulliId: number | null = null;
  
  jumpShembulliId: number | null = null;
  
  canUndo: boolean = false;
  canRedo: boolean = false;

  saving: boolean = false;
  
  canDelete: boolean = false;

  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private shembulliEditorService: ShembulliEditoriService,
    private shembulliNavbarService: ShembulliNavbarService,
    private operatoriFaqjaService: OperatoriFaqjaService,
    private matDialog: MatDialog,
    private qasjaService: QasjaService
  ) {}

  ngOnInit(): void {
    this.shembulliEditorService.editoriForm.valueChanges.pipe(debounceTime(0), takeUntil(this._onDestroy$)).subscribe(() => {
      this.formDirty = this.shembulliEditorService.editoriForm.dirty;
      this.formValid = this.shembulliEditorService.editoriForm.valid;
    });

    combineLatest([
      this.shembulliEditorService.editoriForm.controls.id.valueChanges.pipe(distinctUntilChanged()),
      this.shembulliEditorService.editoriForm.controls.fjaloriId.valueChanges.pipe(distinctUntilChanged()),
      this.shembulliEditorService.editoriForm.controls.burimiId.valueChanges.pipe(distinctUntilChanged()),
      this.shembulliEditorService.getSaving()
    ]).pipe(
      debounceTime(0),
      switchMap(([shembulliId, fjaloriId, burimiId, saving]) => {
        if (shembulliId != null && !saving) {
          return this.qasjaService.hasQasjet([
            { emri: PermissionName.ShembulliFjalori, tipi: PermissionType.Delete, vlera: fjaloriId?.toString() },
            { emri: PermissionName.ShembulliBurimi, tipi: PermissionType.View, vlera: burimiId?.toString() },
          ])
        }
        return of(false);
      }),
      takeUntil(this._onDestroy$)
    ).subscribe((canDelete) => {
      this.canDelete = canDelete;
    })

    this.operatoriFaqjaService
      .listenOnFieldChanges('activeShembulliId')
      .pipe(pairwise(), takeUntil(this._onDestroy$))
      .subscribe(([prev, next]) => {
        if (prev != null && next != null) {
          this.jumpShembulliId = prev;
        } else {
          this.jumpShembulliId = null;
        }
      });

    this.shembulliEditorService
      .canUndo()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((canUndo) => {
        this.canUndo = canUndo;
      });

    this.shembulliEditorService
      .canRedo()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((canRedo) => {
        this.canRedo = canRedo;
      });

    this.shembulliNavbarService
      .getNextShembulli()
      .pipe(debounceTime(0), takeUntil(this._onDestroy$))
      .subscribe((id) => {
        this.nextShembulliId = id;
      });

    this.shembulliNavbarService
      .getPrevShembulli()
      .pipe(debounceTime(0), takeUntil(this._onDestroy$))
      .subscribe((id) => {
        this.prevShembulliId = id;
      });

    this.operatoriFaqjaService
      .listenOnFieldChanges('activeShembulliId')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((shembulliId) => {
        this.shembulliId = shembulliId;
      });

    this.shembulliEditorService
      .getSaving()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((saving) => {
        this.saving = saving;
      });
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
      if(this.nextShembulliId != null)
        this.onSaveAndNextClick();
      else
        this.onSaveClick();
      event.preventDefault();
    }
  }

  jump() {
    if (this.jumpShembulliId && !this.saving) {
      if(this.formDirty) {
        this.shembulliEditorService.getCanChange().subscribe((canChange) => {
          if(canChange) {
            this.operatoriFaqjaService.updateItems({ activeShembulliId: this.jumpShembulliId });
          }
        });
      } else {
        this.operatoriFaqjaService.updateItems({ activeShembulliId: this.jumpShembulliId });
      }
    }
  }

  undo() {
    if (this.canUndo) this.shembulliEditorService.undo();
  }

  redo() {
    if (this.canRedo) this.shembulliEditorService.redo();
  }

  onEditorClearClick() {
    if(!this.saving) this.shembulliNavbarService.triggerOnEditorClearClick();
  }

  onDeleteClick() {
    if(this.canDelete && !this.saving) this.shembulliNavbarService.triggerDeleteClick();
  }

  onPrevClick() {
    if (this.prevShembulliId && !this.saving) this.shembulliNavbarService.triggerPrevClick();
  }

  onSaveAndPrevClick() {
    if (this.formDirty && this.formValid && this.prevShembulliId && !this.saving) this.shembulliNavbarService.triggerSaveAndPrevClick();
  }

  onSaveClick() {
    if (this.formDirty && this.formValid && !this.saving) this.shembulliNavbarService.triggerSaveClick();
  }

  onSaveAndNextClick() {
    if (this.formDirty && this.formValid && this.nextShembulliId && !this.saving) this.shembulliNavbarService.triggerSaveAndNextClick();
  }

  onNextClick() {
    if (this.nextShembulliId && !this.saving) this.shembulliNavbarService.triggerNextClick();
  }

  onCommentsClick() {
    if(this.shembulliId) this.shembulliNavbarService.triggerCommentsClick();
  }

  openShenimet() {
    this.matDialog.open(ShenimetPopupComponent, {
      disableClose: true,
      panelClass: 'big-resizable-dialog',
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
